import classnames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import {
  MemberRisk,
  MemberStatus,
  States,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CommunityResource,
  DBEnum,
  Employee,
  EmployeeGroup,
  MemberView,
  PaginationType,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';

import {
  BackgroundColors,
  Button,
  IconButton,
  Modal,
  PanelInfo,
  Select,
  Spinner,
  Tooltip,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { SearchCommunityResource } from 'dash/src/components/SearchCommunityResource';
import {
  BulkUpdatePEChange,
  bulkUpdate,
  getMembersView,
} from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';

import {
  CHANGE_TYPES,
  ChangeType,
  Op,
  ProgramExtensionOp,
} from './BulkEditTypes';
import { BulkReviewTable } from './BulkReviewTable';

const PAGE_SIZE = 10;

const BULKK_EDIT_PE_ACTIONS = [
  { value: Op.ADD, label: translate('global.add') },
  { value: Op.EDIT, label: translate('global.edit') },
  { value: Op.REMOVE, label: translate('global.remove') },
];

const useStyles = makeStyles({
  disabledOption: {
    opacity: 0.38,
    width: '100%',
  },
  errorList: {
    listStyleType: 'initial',
    paddingBottom: '1rem',
    paddingLeft: '2rem',
  },
  modalBody: {
    minHeight: '50.1rem',
  },
  tableContainer: {
    background: BackgroundColors.gray,
    padding: '0.5rem 1rem 1rem',
    marginTop: '1rem',
    borderRadius: '0.5rem',
  },
});

interface Props {
  activeCareCoordinators?: Selectable[];
  activeEngagementOwners?: Selectable[];
  activeHealthCoaches?: Selectable[];
  activeNPs?: SelectableInfo<Employee>[];
  activePodManagers?: SelectableInfo<Employee>[];
  activeRNs?: SelectableInfo<Employee>[];
  activeCarePlanGroups: DBEnum[];
  allProgramExtension: ProgramExtension[];
  allProgramExtensionStatuses: ProgramExtensionStatus[];
  allWorklistGroups: EmployeeGroup[];
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  selection: number[];
}

const UNASSIGNED_SELECTABLE = {
  label: translate('global.unassigned'),
  value: 0,
};

export const BulkEditModal = ({
  activeCareCoordinators,
  activeEngagementOwners,
  activeHealthCoaches,
  activeNPs,
  activePodManagers,
  activeRNs,
  activeCarePlanGroups,
  allProgramExtension,
  allProgramExtensionStatuses,
  allWorklistGroups,
  onClose,
  onSubmit,
  open,
  selection,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [reviewing, setReviewing] = useState(false);
  const [submited, setSubmited] = useState(false);

  const [loadingStatus, setLoadingStatus] = useState(false);
  const [activeCount, setActiveCount] = useState(-1);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [pagination, setPagination] = useState<PaginationType>();

  const [selectedPodManager, setSelectedPodManager] = useState<number>();
  const [selectedRN, setSelectedRN] = useState<number>();
  const [selectedNP, setSelectedNP] = useState<number>();
  const [selectedCareCoordinator, setSelectedCareCoordinator] = useState<
    number[]
  >([]);
  const [selectedEngagementOwner, setSelectedEngagementOwner] = useState<
    number[]
  >([]);
  const [selectedHealthCoach, setSelectedHealthCoach] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<MemberStatus>();
  const [selectedCarePlanGroup, setSelectedCarePlanGroup] = useState<DBEnum>();
  const [selectedCommunityResource, setSelectedCommunityResource] = useState<
    CommunityResource
  >();
  const [selectedWorklistGroup, setSelectedWorklistGroup] = useState<
    EmployeeGroup
  >();
  const [
    selectedRiskLevel,
    setSelectedRiskLevel,
  ] = useState<MemberRisk | null>();
  const [programExtensionChanges, setProgramExtensionChanges] = useState<
    ProgramExtensionOp[]
  >([{} as ProgramExtensionOp]);

  const [changes, setChanges] = useState<Map<string, ChangeType>>();
  const [previewMembers, setPreviewMembers] = useState<MemberView[]>([]);

  const [error, setError] = useState<boolean>(false);
  const [npErrorStates, setNPErrorStates] = useState<States[]>();
  const [rnErrorStates, setRNErrorStates] = useState<States[]>();

  const checkDeceasedStatus = async () => {
    setLoadingStatus(true);
    setActiveCount(-1);
    const { pagination } = await getMembersView({
      id: selection,
      wasEverOnboarded: true,
      count: true,
    });
    setActiveCount(pagination.total);
    setLoadingStatus(false);
  };

  const resetModal = () => {
    if (open) {
      setPreviewMembers([]);
      setProgramExtensionChanges([{} as ProgramExtensionOp]);
      setReviewing(false);
      setError(false);
      setSelectedCareCoordinator([]);
      setSelectedCarePlanGroup(undefined);
      setSelectedCommunityResource(undefined);
      setSelectedEngagementOwner([]);
      setSelectedHealthCoach([]);
      setSelectedNP(undefined);
      setSelectedRiskLevel(undefined);
      setSelectedRN(undefined);
      setSelectedStatus(undefined);
      setSelectedWorklistGroup(undefined);
      setSubmited(false);
      checkDeceasedStatus();
    }
  };

  // reset everything if the selection changes
  useEffect(resetModal, [selection, open]);

  // review table load
  useEffect(() => {
    if (!reviewing) return;
    if ((page + 1) * pageSize < previewMembers?.length + 1) return;
    fetchPreview();
  }, [reviewing, page, pageSize]);

  const isValid = () => {
    if (
      programExtensionChanges.find(
        ({ operation, status }) =>
          operation && operation !== Op.REMOVE && !status,
      )
    ) {
      return false;
    }

    return (
      !!selectedPodManager ||
      !!selectedNP ||
      !!selectedRN ||
      !!selectedCareCoordinator?.length ||
      !!selectedEngagementOwner?.length ||
      !!selectedHealthCoach?.length ||
      !!selectedStatus ||
      !!selectedCommunityResource ||
      !!selectedCarePlanGroup ||
      selectedRiskLevel !== undefined ||
      !!selectedWorklistGroup ||
      (programExtensionChanges.length > 0 &&
        !programExtensionChanges.find(({ operation, id }) => !operation || !id))
    );
  };

  const fetchPreview = async () => {
    setLoading(true);
    const offset = page * pageSize;
    const ids = [...selection].slice(offset, offset + pageSize);
    const { items } = await getMembersView({
      id: ids,
      offset: 0,
      limit: pageSize,
    });
    if (offset !== 0) {
      setPreviewMembers([...previewMembers, ...items]);
    } else {
      setPreviewMembers(items);
    }

    setPagination({
      limit: pageSize,
      offset,
      total: selection?.length || 0,
    } as PaginationType);
    setLoading(false);
  };

  const review = async () => {
    setSubmited(true);

    if (!isValid()) {
      return;
    }

    const changes = new Map<string, ChangeType>();
    if (selectedRiskLevel !== undefined) {
      changes.set(CHANGE_TYPES.RISK_LEVEL, selectedRiskLevel);
    }
    if (selectedWorklistGroup) {
      changes.set(CHANGE_TYPES.WORKLIST_GROUP, selectedWorklistGroup);
    }
    if (selectedCommunityResource) {
      changes.set(CHANGE_TYPES.COMMUNITY_RESOURCE, selectedCommunityResource);
    }
    if (selectedCarePlanGroup !== undefined) {
      changes.set(CHANGE_TYPES.CARE_PLAN_GROUP, selectedCarePlanGroup);
    }
    if (selectedNP) {
      changes.set(
        CHANGE_TYPES.NP_OWNER,
        activeNPs?.find(({ value }) => value === selectedNP),
      );
    }
    if (selectedPodManager) {
      changes.set(
        CHANGE_TYPES.POD_MANAGER,
        activePodManagers?.find(({ value }) => value === selectedPodManager),
      );
    }
    if (selectedRN) {
      changes.set(
        CHANGE_TYPES.RN_OWNER,
        activeRNs?.find(({ value }) => value === selectedRN),
      );
    }
    if (selectedCareCoordinator?.length) {
      changes.set(
        CHANGE_TYPES.CARE_COORDINATOR,
        activeCareCoordinators?.filter(({ value }) =>
          selectedCareCoordinator.includes(value as number),
        ),
      );
    }
    if (selectedEngagementOwner?.length) {
      changes.set(
        CHANGE_TYPES.ENGAGEMENT_OWNER,
        activeEngagementOwners?.filter(({ value }) =>
          selectedEngagementOwner.includes(value as number),
        ),
      );
    }
    if (selectedHealthCoach?.length) {
      changes.set(
        CHANGE_TYPES.HEALTH_COACH,
        activeHealthCoaches?.filter(({ value }) =>
          selectedHealthCoach.includes(value as number),
        ),
      );
    }
    if (selectedStatus) {
      changes.set(CHANGE_TYPES.STATUS, selectedStatus);
    }
    if (programExtensionChanges?.filter(({ operation }) => operation)?.length) {
      changes.set(CHANGE_TYPES.PROGRAM_EXTENSION, programExtensionChanges);
    }
    setChanges(changes);
    setReviewing(true);
  };

  const handleSubmit = async () => {
    if (!isValid()) {
      return;
    }
    let error: boolean;
    let npErrorStates: States[];
    let rnErrorStates: States[];

    try {
      ({ error, npErrorStates, rnErrorStates } = await bulkUpdate(selection, {
        careCoordinatorIds: selectedCareCoordinator?.length
          ? selectedCareCoordinator
          : undefined,
        carePlanGroupId:
          selectedCarePlanGroup?.id === 0 ? null : selectedCarePlanGroup?.id,
        communityResourceId: selectedCommunityResource?.id,
        engagementOwnerIds: selectedEngagementOwner?.length
          ? selectedEngagementOwner.filter(Boolean)
          : undefined,
        healthCoachIds: selectedHealthCoach?.length
          ? selectedHealthCoach.filter(Boolean)
          : undefined,
        rnOwnerId: selectedRN ? Number(selectedRN) : undefined,
        npOwnerId: selectedNP ? Number(selectedNP) : undefined,
        podManagerId: selectedPodManager
          ? Number(selectedPodManager)
          : undefined,
        riskLevel: selectedRiskLevel,
        status: selectedStatus,
        programExtensions: programExtensionChanges
          .filter(({ operation, id }) => operation && id)
          .map(
            ({ operation, id, status }) =>
              ({
                opt: operation,
                programExtensionId: id,
                programExtensionStatus: status,
              } as BulkUpdatePEChange),
          ),
        worklistGroupId: selectedWorklistGroup,
      }));
    } catch (e) {
      showGlobalError(e as string);
      return;
    }

    setError(error);
    if (error) {
      setNPErrorStates(npErrorStates);
      setRNErrorStates(rnErrorStates);
      return false;
    }

    onSubmit();
    onClose();
    return true;
  };

  return (
    <Modal
      maxWidth="lg"
      title={translate('memberBulkEdit.bulkEdit')}
      onCancel={reviewing ? () => setReviewing(false) : onClose}
      onClose={onClose}
      onSubmit={async () => {
        reviewing ? await handleSubmit() : await review();
      }}
      onSubmitSecondary={
        reviewing
          ? async () => {
              setReviewing(false);
              setError(false);
            }
          : undefined
      }
      open={open}
      preventCloseOnSubmit
      submitSecondaryText={reviewing ? translate('global.back') : undefined}
      submitText={
        reviewing
          ? translate('global.confirm')
          : translate('memberBulkEdit.review')
      }
      submitDisabled={reviewing ? error : !isValid()}
      submitSecondaryDisabled={reviewing && error ? false : undefined}
    >
      <div className={classnames(styles.modalBody, 'grid-wrapper fit')}>
        {reviewing && error ? (
          <PanelInfo
            className="grid-span-12"
            title={
              <div>
                <p style={{ color: 'inherit' }}>
                  {translate('memberBulkEdit.errorStateAssignment')}
                </p>

                <ul className={styles.errorList}>
                  {changes?.has(CHANGE_TYPES.NP_OWNER) &&
                    !!npErrorStates?.length &&
                    npErrorStates.map((state) => (
                      <li key={`error-state-np-${state.value}`}>
                        {translate(
                          'memberBulkEdit.errorStateAssignmentNotLicensed',
                          {
                            name: (changes?.get(
                              CHANGE_TYPES.NP_OWNER,
                            ) as Selectable)?.label,
                            state: state.toString(),
                          },
                        )}
                      </li>
                    ))}
                  {changes?.has(CHANGE_TYPES.RN_OWNER) &&
                    (changes?.get(CHANGE_TYPES.RN_OWNER) as Selectable)
                      ?.value !==
                      (changes?.get(CHANGE_TYPES.NP_OWNER) as Selectable)
                        ?.value &&
                    !!rnErrorStates?.length &&
                    rnErrorStates.map((state) => (
                      <li key={`error-state-rn-${state.value}`}>
                        {translate(
                          'memberBulkEdit.errorStateAssignmentNotLicensed',
                          {
                            name: (changes?.get(
                              CHANGE_TYPES.RN_OWNER,
                            ) as Selectable)?.label,
                            state: state.toString(),
                          },
                        )}
                      </li>
                    ))}
                </ul>
                <p style={{ color: 'inherit' }}>
                  {translate('memberBulkEdit.errorStateAssignmentSubtitle')}
                </p>
              </div>
            }
            type="error"
          />
        ) : (
          <PanelInfo
            className="grid-span-12"
            title={translate('memberBulkEdit.warning', {
              count: selection.length,
            })}
            type="warning"
          />
        )}
        {!reviewing && (
          <>
            <Select
              className="grid-span-4"
              label={translate('members.podManager')}
              placeholder={translate('global.unchanged')}
              items={activePodManagers || []}
              value={activePodManagers?.find(
                ({ value }) => value === selectedPodManager,
              )}
              onChange={(pm: Selectable) =>
                setSelectedPodManager(pm?.value as number)
              }
            />
            <Select
              className="grid-span-4"
              label={translate('members.rnOwner')}
              placeholder={translate('global.unchanged')}
              items={activeRNs || []}
              value={activeRNs?.find(({ value }) => value === selectedRN)}
              onChange={(rn: Selectable) => setSelectedRN(rn?.value as number)}
            />
            <Select
              className="grid-span-4"
              label={translate('members.npOwner')}
              placeholder={translate('global.unchanged')}
              items={activeNPs || []}
              value={activeNPs?.find(({ value }) => value === selectedNP)}
              onChange={(np: Selectable) => setSelectedNP(np?.value as number)}
            />
            <Select
              className="grid-span-4"
              label={translate('members.careCoordinators')}
              placeholder={translate('global.unchanged')}
              items={activeCareCoordinators || []}
              multiple
              value={activeCareCoordinators?.filter(({ value }) =>
                selectedCareCoordinator?.includes(value as number),
              )}
              onChange={(cc: Selectable[]) =>
                setSelectedCareCoordinator(
                  cc.map(({ value }) => value as number),
                )
              }
            />
            <Select
              className="grid-span-4"
              label={translate('members.engagementOwners')}
              placeholder={translate('global.unchanged')}
              items={activeEngagementOwners || []}
              multiple
              value={activeEngagementOwners?.filter(({ value }) =>
                selectedEngagementOwner?.includes(value as number),
              )}
              onChange={(cc: Selectable[]) =>
                setSelectedEngagementOwner(
                  cc.map(({ value }) => value as number),
                )
              }
            />
            <Select
              className="grid-span-4"
              label={translate('members.healthCoaches')}
              placeholder={translate('global.unchanged')}
              items={activeHealthCoaches || []}
              multiple
              value={activeHealthCoaches?.filter(({ value }) =>
                selectedHealthCoach?.includes(value as number),
              )}
              onChange={(cc: Selectable[]) =>
                setSelectedHealthCoach(cc.map(({ value }) => value as number))
              }
            />
            <Select
              className="grid-span-4"
              label={translate('members.worklistGroup')}
              placeholder={translate('global.unchanged')}
              getItemLabel={({ name }: EmployeeGroup) => name}
              items={allWorklistGroups}
              value={allWorklistGroups.find(
                ({ id }) => id === selectedWorklistGroup?.id,
              )}
              onChange={setSelectedWorklistGroup}
            />
            <Select
              className="grid-span-4"
              disabled={!Session.actingUser.canEditBulkEditStatus}
              getItemDisabled={({ value }) =>
                (value === MemberStatus.DECEASED_NON_ONBOARDED.value &&
                  activeCount !== 0) ||
                (value === MemberStatus.DECEASED_ONBOARDED.value &&
                  selection?.length !== activeCount)
              }
              label={translate('members.status')}
              loading={loadingStatus}
              placeholder={translate('global.unchanged')}
              items={
                Session.actingUser.canEditBulkEditStatusAll
                  ? MemberStatus.toSelectable()
                  : Enum.toSelectable([MemberStatus.ACCEPTED])
              }
              value={MemberStatus.toSelectable().find(
                ({ value }) => value === selectedStatus?.value,
              )}
              onChange={(status: Selectable) =>
                setSelectedStatus(MemberStatus.byKey[status.value])
              }
              renderOption={({ value, label }: Selectable) => {
                const text = label;
                if (
                  MemberStatus.INVALID.value === value &&
                  !Session.actingUser.canSetMemberStatusInvalid
                ) {
                  return (
                    <Tooltip
                      text={translate(
                        'memberBulkEdit.warningNoSetInvalidStatus',
                      )}
                      enterDelay={50}
                      followCursor
                    >
                      <span
                        className={styles.disabledOption}
                        onClick={(evt) => evt.stopPropagation()}
                      >
                        {' '}
                        {text}
                      </span>
                    </Tooltip>
                  );
                }
                return text;
              }}
            />
            <SearchCommunityResource
              className="grid-span-4"
              label={translate('members.communityResource')}
              onChange={(value) =>
                setSelectedCommunityResource(value as CommunityResource)
              }
              placeholder={translate('global.unchanged')}
              value={selectedCommunityResource}
            />
            <Select
              className="grid-span-4"
              label={translate('members.carePlanGroup')}
              placeholder={translate('global.unchanged')}
              getItemLabel={({ description }: DBEnum) => description}
              items={activeCarePlanGroups}
              value={activeCarePlanGroups.find(
                ({ id }) => id === selectedCarePlanGroup?.id,
              )}
              onChange={setSelectedCarePlanGroup}
            />
            <Select
              className="grid-span-4"
              label={translate('members.riskLevel')}
              placeholder={translate('global.unchanged')}
              items={[
                UNASSIGNED_SELECTABLE,
                ...Enum.toSelectable(MemberRisk.asArray),
              ]}
              value={
                selectedRiskLevel
                  ? Enum.toSelectable([selectedRiskLevel])[0]
                  : undefined
              }
              onChange={(riskLevel?: Selectable) => {
                if (riskLevel) {
                  setSelectedRiskLevel(
                    riskLevel?.value ? MemberRisk.byKey[riskLevel.value] : null,
                  );
                } else {
                  setSelectedRiskLevel(undefined);
                }
              }}
            />

            <label
              className="grid-span-12"
              style={{ height: 0, fontSize: '0.875em' }}
            >
              {translate('members.programExtensions')}
            </label>
            {programExtensionChanges.map(
              ({ operation, id: peId, status }, idx) => (
                <Fragment key={idx}>
                  <Select
                    className="grid-span-3"
                    error={
                      submited &&
                      programExtensionChanges.length > 1 &&
                      !operation &&
                      translate('global.missingRequiredFieldMin')
                    }
                    items={BULKK_EDIT_PE_ACTIONS}
                    onChange={(newOp: Selectable) => {
                      const editChange = programExtensionChanges[idx];
                      programExtensionChanges[idx] = {
                        ...editChange,
                        operation: newOp ? (newOp.value as Op) : undefined,
                      };
                      setProgramExtensionChanges([...programExtensionChanges]);
                    }}
                    placeholder={translate('memberBulkEdit.action')}
                    value={BULKK_EDIT_PE_ACTIONS.find(
                      ({ value }) => value === operation,
                    )}
                  />
                  <Select
                    className="grid-span-5"
                    error={
                      submited &&
                      operation &&
                      !peId &&
                      translate('global.missingRequiredFieldMin')
                    }
                    getItemLabel={({ name }: ProgramExtension) => name}
                    items={
                      allProgramExtension.filter(
                        ({ id }) =>
                          !programExtensionChanges.find(
                            ({ id: changeID }) => changeID === id,
                          ),
                      ) || []
                    }
                    onChange={(newPE: ProgramExtension) => {
                      const editChange = programExtensionChanges[idx];
                      programExtensionChanges[idx] = {
                        ...editChange,
                        id: newPE?.id,
                        status: allProgramExtension
                          .find(({ id }) => id === newPE?.id)
                          ?.statuses?.find(
                            ({ id }) =>
                              id === programExtensionChanges[idx]?.status,
                          )?.id,
                      };
                      setProgramExtensionChanges([...programExtensionChanges]);
                    }}
                    placeholder={translate('memberBulkEdit.programExtension')}
                    value={allProgramExtension.find(({ id }) => id === peId)}
                  />
                  <Select
                    className="grid-span-3"
                    disabled={
                      !operation ||
                      operation === Op.REMOVE ||
                      !programExtensionChanges[idx]?.id
                    }
                    error={
                      submited &&
                      operation &&
                      operation !== Op.REMOVE &&
                      !status &&
                      translate('global.missingRequiredFieldMin')
                    }
                    items={ProgramExtensionStatus.toSelectable(
                      allProgramExtension.find(({ id }) => id === peId)
                        ?.statuses || [],
                    ).sort(Enum.sort)}
                    onChange={(newStatus: Selectable) => {
                      const editChange = programExtensionChanges[idx];
                      programExtensionChanges[idx] = {
                        ...editChange,
                        status: newStatus?.value
                          ? (newStatus.value as string)
                          : undefined,
                      };
                      setProgramExtensionChanges([...programExtensionChanges]);
                    }}
                    placeholder={translate(
                      'memberBulkEdit.programExtensionStatus',
                    )}
                    value={ProgramExtensionStatus.toSelectable(
                      allProgramExtension.find(({ id }) => id === peId)
                        ?.statuses || [],
                    ).find(({ value }) => value === status)}
                  />

                  <IconButton
                    className="grid-span-1"
                    data-cy="bulk-edit-pe-delete-row"
                    disabled={programExtensionChanges.length < 2}
                    size="small"
                    onClick={() => {
                      programExtensionChanges.splice(idx, 1);
                      setProgramExtensionChanges([...programExtensionChanges]);
                    }}
                  >
                    <DeleteOutlineIcon fontSize="large" />
                  </IconButton>
                </Fragment>
              ),
            )}
            <div className="grid-span-4" />
            <Button
              className="grid-span-4"
              color="tertiary"
              icon={<AddIcon />}
              type="contained"
              onClick={() =>
                setProgramExtensionChanges([
                  ...programExtensionChanges,
                  {} as ProgramExtensionOp,
                ])
              }
            >
              {translate('memberBulkEdit.addProgramExtension')}
            </Button>
          </>
        )}
        {reviewing && loading && (
          <div className="flex center grid-span-12">
            <Spinner width={64} />
          </div>
        )}
        {reviewing && !loading && (
          <div className={classnames('grid-span-12', styles.tableContainer)}>
            <BulkReviewTable
              allProgramExtension={allProgramExtension}
              allProgramExtensionStatuses={allProgramExtensionStatuses}
              changes={changes}
              members={previewMembers}
              onChangePage={setPage}
              onChangePageSize={(ps) => {
                setPage(0);
                setPageSize(ps);
                setPreviewMembers([]);
              }}
              pagination={pagination}
              showState={
                changes?.has(CHANGE_TYPES.NP_OWNER) ||
                changes?.has(CHANGE_TYPES.RN_OWNER)
              }
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BulkEditModal;
