import React from 'react';

import { States } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  FilterSelectable,
  ProgramExtension,
} from '@vestahealthcare/common/models';

import {
  FilterItem,
  Select,
  SelectProgramExtension,
  SelectProgramExtensionData,
} from 'styleguide-v2';

import { GetCCMMemberParams } from 'dash/src/services/CCMServices';
import { getFilterItem } from 'dash/src/utils/filterUtils';

import { dataUtils } from '../../../utils/dataUtils';

type KeyGetCCMParams = keyof GetCCMMemberParams;

type Props = {
  data: {
    languages: Selectable[];
    programExtensions: ProgramExtension[];
    worklistGroups: Selectable[];
  };
  filters?: {
    [x in KeyGetCCMParams]?: FilterItem;
  };
  loading?: boolean;
  onChange: (
    filters: {
      [x in KeyGetCCMParams]?: FilterItem;
    },
  ) => void;
};

export const CCMFiltersBar = ({ data, filters, loading, onChange }: Props) => (
  <>
    <Select
      allowAddNewValue
      data-cy="ccm-filter-bar-member-id"
      items={[]}
      inputType="number"
      multiple
      noOptionsText={translate('members.typeToSearch')}
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          memberIds: getFilterItem(
            translate('ccm.common.memberId'),
            values.map(({ label }) => ({
              value: label,
              label,
            })),
          ),
        })
      }
      placeholder={translate('ccm.common.memberId')}
      size="xs"
      value={filters?.memberIds?.value || []}
    />
    <Select
      data-cy="ccm-filter-bar-state"
      items={Enum.toSelectable(States.asArray)}
      limitTags={1}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          state: getFilterItem(translate('ccm.common.state'), values),
        })
      }
      placeholder={translate('ccm.common.state')}
      placeholderV2
      size="xs"
      value={filters?.state?.value || []}
    />
    <Select
      data-cy="ccm-filter-bar-language"
      items={data.languages.filter(({ disabled }) => !disabled)}
      grouped={({ preferred }) =>
        preferred ? translate('global.preferred') : translate('global.other')
      }
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          language: getFilterItem(translate('ccm.common.language'), values),
        })
      }
      placeholder={translate('ccm.common.language')}
      placeholderV2
      size="xs"
      value={filters?.language?.value || []}
    />
    <Select
      data-cy="ccm-filter-care-plan-group"
      items={data.worklistGroups}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          worklistGroupId: getFilterItem(
            translate('ccm.common.employeeGroup'),
            values,
          ),
        })
      }
      placeholder={translate('ccm.common.employeeGroup')}
      placeholderV2
      size="xs"
      value={filters?.worklistGroupId?.value || []}
    />
    <SelectProgramExtension
      items={data.programExtensions}
      placeholder={translate('podReporting.filters.programExtensions')}
      placeholderV2
      limitTags={1}
      onChange={(value) =>
        onChange({
          ...filters,
          programExtensionFilter: getFilterItem(
            translate('podReporting.filters.programExtensions'),
            value,
            dataUtils.programExtensions.getFilterItem,
          ),
        })
      }
      size="xs"
      value={(filters?.programExtensionFilter?.value as FilterSelectable<
        SelectProgramExtensionData
      >[])?.map(({ value }) => value)}
    />
  </>
);
