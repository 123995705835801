import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';

import { translate } from '@vestahealthcare/common/i18n';
import { EmployeeGroup } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { IconButton, Table, UpdatedBy } from 'styleguide-v2';

interface Props {
  groups: EmployeeGroup[];
  onEdit: (group: EmployeeGroup) => void;
}

const GROUP_COLUMNS = ({ onEdit }: Props) => [
  { headerName: translate('groups.name'), field: 'name' },
  {
    headerName: translate('groups.status'),
    component: ({ active }: EmployeeGroup) => (
      <span className="flex">
        {active ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <CancelIcon color="error" />
        )}
        &nbsp;
        {active ? translate('groups.active') : translate('groups.inactive')}
      </span>
    ),
    width: 150,
  },
  {
    headerName: translate('groups.createdBy'),
    component: ({ createdAt, createdBy }: EmployeeGroup) =>
      createdAt ? (
        <UpdatedBy date={createdAt} user={createdBy.fullName} />
      ) : (
        EMPTY
      ),
    width: 250,
  },
  {
    headerName: translate('groups.updatedBy'),
    component: ({ updatedAt, updatedBy }: EmployeeGroup) =>
      updatedAt ? (
        <UpdatedBy date={updatedAt} user={updatedBy.fullName} />
      ) : (
        EMPTY
      ),
    width: 220,
  },
  {
    headerName: '',
    component: (group: EmployeeGroup) => (
      <IconButton size="small" onClick={() => onEdit(group)}>
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

export const GroupsTable = (props: Props) => (
  <Table
    config={{
      columns: GROUP_COLUMNS(props),
      compact: true,
      data: props.groups,
      paginationOptions: [-1],
    }}
  />
);

export default GroupsTable;
