import classNames from 'classnames';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  DeviceOS,
  MemberConsentType,
  MemberContactMethodType,
  MemberContactTimeType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamPersonExtended } from '@vestahealthcare/common/models';
import ConsentRequest from '@vestahealthcare/common/models/ConsentRequest';
import {
  ConsentAnswer,
  MemberConsentAnswer,
} from '@vestahealthcare/common/models/MemberConsentAnswer';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
  NO_TIME_DATE_FORMAT,
  TIME_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import {
  Card,
  CardContent,
  CardHeader,
  Colors,
  Fonts,
  IconButton,
  Invite,
  Tooltip,
} from 'styleguide-v2';

import {
  sendAppInvite,
  sendTextConsent,
} from 'dash/src/services/CareTeamServices';

const phoneUtil = PhoneNumberUtil.getInstance();

type Props = {
  consents?: MemberConsentAnswer[];
  consentRequest?: ConsentRequest;
  onEditAddress: () => void;
  onEditContact: () => void;
  onEditEngagement: () => void;
  onEditTextConsent: () => void;
  person?: CareTeamPersonExtended;
  refetchCTP?: (person: CareTeamPersonExtended) => void;
  showContactVerified?: boolean;
};

type CardProps = {
  className?: string;
  consents?: MemberConsentAnswer[];
  consentRequest?: ConsentRequest;
  onEdit: () => void;
  onEditConsent?: () => void;
  person?: CareTeamPersonExtended;
  refetchCTP?: (person: CareTeamPersonExtended) => void;
  showContactVerified?: boolean;
};

const engagementStyles = makeStyles({
  lastLogin: {
    fontSize: '0.875em',
    margin: 0,
  },
});

const EngagementCard = ({
  className,
  consents,
  person,
  onEdit,
  refetchCTP,
}: CardProps) => {
  const styles = engagementStyles();
  const showLastLoginInfo = person?.appUser?.lastLoginAt;

  const [androidSent, setAndroidSent] = useState<boolean>(false);
  const [iosSent, setIOSSent] = useState<boolean>(false);
  const [textSent, setTextSent] = useState<boolean>(false);

  const showAppLinks =
    person &&
    !showLastLoginInfo &&
    person?.appUser?.authenticationPhone &&
    !person?.appUser?.lastLoginAt;
  const showTextLink =
    person &&
    !consents?.find(
      ({ type, answer }) =>
        type === MemberConsentType.WRITTEN && answer === ConsentAnswer.YES,
    ) &&
    person?.contactInfo?.contactMethods?.find(
      ({ contactMethod }) => contactMethod === MemberContactMethodType.TEXT,
    );

  useEffect(() => {
    setAndroidSent(person?.appUser?.inviteOS === DeviceOS.ANDROID);
    setIOSSent(person?.appUser?.inviteOS === DeviceOS.IOS);
    setTextSent(!!consents?.length);
  }, [person, consents]);

  const handleSendTextConsent = async (person: CareTeamPersonExtended) => {
    await sendTextConsent(person.id);
    if (refetchCTP) {
      refetchCTP(person);
    }
  };

  return (
    <Card className={className} sx={{ height: 'max-content' }}>
      <CardHeader
        action={
          <IconButton
            color="default"
            data-cy="member-profile-network-edit"
            size="small"
            onClick={() => onEdit()}
          >
            <EditIcon color="primary" />
          </IconButton>
        }
        title={translate('careTeam.steps.engagement')}
      />
      <CardContent className="grid-wrapper fit">
        <span className="grid-span-4 bold">
          {translate('careTeam.common.engagementPreference')}:
        </span>
        <span
          className={`grid-span-${
            showAppLinks || showLastLoginInfo || showTextLink ? 4 : 8
          }`}
        >
          {person?.contactInfo?.contactMethods?.map(
            ({ contactMethod, contactMethodOther }) => (
              <p
                className="no-margin flex"
                key={`care-team-contact-method-${contactMethod.value}`}
              >
                {contactMethod === MemberContactMethodType.OTHER
                  ? contactMethodOther
                  : contactMethod.toString()}
              </p>
            ),
          ) || EMPTY}
        </span>
        {showLastLoginInfo && (
          <div className="grid-span-4">
            <p className={classNames('bold', styles.lastLogin)}>
              {`${translate('careTeam.common.lastLogin')} `}
            </p>
            <p className={styles.lastLogin}>
              {'on '}
              {person?.appUser?.lastLoginAt?.format(NO_TIME_DATE_FORMAT)}
            </p>
            <p className={styles.lastLogin}>
              {person?.appUser?.lastLoginDevice &&
                `v${
                  person?.appUser?.lastLoginDevice.appVersion
                } - ${DeviceOS.byKey[
                  person?.appUser?.lastLoginDevice.os
                ].toString()} `}
              {person?.appUser?.lastLoginDevice?.osVersion &&
                `${person?.appUser?.lastLoginDevice.osVersion} `}
            </p>
          </div>
        )}

        {(showAppLinks || showTextLink) && (
          <div className="grid-span-4 flex gap wrap">
            {showAppLinks && (
              <>
                <Invite
                  type="ios"
                  sent={iosSent}
                  onClick={async () => {
                    if (person) {
                      sendAppInvite(person.id, 'ios');
                    }
                    setIOSSent(true);
                  }}
                />
                <Invite
                  type="android"
                  sent={androidSent}
                  onClick={async () => {
                    if (person) {
                      sendAppInvite(person.id, 'android');
                    }
                    setAndroidSent(true);
                  }}
                />
              </>
            )}
            {showTextLink && (
              <Invite
                type="sms"
                sent={textSent}
                onClick={async () => {
                  if (person) {
                    handleSendTextConsent(person);
                  }
                  setTextSent(true);
                }}
              />
            )}
          </div>
        )}

        <span className="grid-span-4 bold">
          {translate('careTeam.common.frequency')}:
        </span>
        <span className="grid-span-8">
          {person?.contactInfo?.contactFrequency?.toString() || EMPTY}
        </span>
        <span className="grid-span-4 bold">
          {translate('careTeam.common.engagementOwner')}:
        </span>
        <span className="grid-span-8">
          {person?.engagementOwner?.fullName || EMPTY}
        </span>
        <span className="grid-span-4 bold">
          {translate('careTeam.common.preferredDays')}:
        </span>
        <span className="grid-span-8">
          {person?.contactInfo?.contactTimes?.length
            ? person
                ?.getDaysOfService()
                ?.map(({ dates, startTime, endTime, type }, index) => (
                  <p
                    className="no-margin"
                    key={`care-team-person-${person.id}-days-${index}`}
                  >
                    {!!dates?.length && (
                      <span>
                        {DayOfWeek.stringify(dates)}
                        {(type || startTime || endTime) && ': '}
                      </span>
                    )}
                    {type && type !== MemberContactTimeType.CUSTOM && (
                      <span>{`${type?.toString()} `}</span>
                    )}
                    {(startTime || endTime) && (
                      <span>
                        {startTime
                          ? moment(startTime.getTime()).format(TIME_FORMAT)
                          : '*'}
                        {' - '}
                        {endTime
                          ? moment(endTime.getTime()).format(TIME_FORMAT)
                          : '*'}
                      </span>
                    )}
                  </p>
                ))
            : EMPTY}
        </span>
      </CardContent>
    </Card>
  );
};

const contactStyles = makeStyles({
  answer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    '& > div': {
      flex: '1 100%',
    },
  },
  answerType: {
    fontSize: `calc(${Fonts.fontSize} * .75)`,
    fontWeight: 500,
    flex: '1 7.5rem',
  },
  consentStatus: {
    fontSize: `calc(${Fonts.fontSize} * .75)`,
    fontWeight: 500,
  },
  consentDate: {
    fontSize: `calc(${Fonts.fontSize} * .75)`,
  },
  editIcon: {
    '&&': {
      padding: '0.25rem',
      alignSelf: 'baseline',
    },
  },
});

const ContactCard = ({
  className,
  consents,
  consentRequest,
  person,
  onEdit,
  onEditConsent,
  showContactVerified,
}: CardProps) => {
  const styles = contactStyles();

  const getAnswerIcon = (answer?: MemberConsentAnswer) => {
    if (answer?.answer) {
      return (
        <div className={styles.answer}>
          {answer?.isReceived && <CheckCircleIcon color="primary" />}
          {answer?.isDenied && <CancelIcon color="error" />}
          &nbsp;
          <div>
            <div className={styles.consentStatus}>
              {answer?.isReceived
                ? translate('memberConsents.received')
                : translate('memberConsents.denied')}
              {` (${answer.type?.toString()})`}
            </div>
            {answer?.dateReceived && (
              <div className={styles.consentDate}>
                {answer?.dateReceived?.format(DATE_FORMAT_SHORT)}
              </div>
            )}
          </div>
        </div>
      );
    }
    if (answer?.dateSent) {
      return (
        <div className={styles.answer}>
          <ArrowCircleRightIcon color="warning" />
          &nbsp;
          <div>
            <div className={styles.consentStatus}>
              {translate('memberConsents.sent')}
            </div>
            <div className={styles.consentDate}>
              {answer?.dateSent?.format(DATE_FORMAT_SHORT)}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.answer}>
        <CancelIcon />
        &nbsp;
        <span className={styles.consentDate}>{translate('global.none')}</span>
      </div>
    );
  };

  return (
    <Card className={className} sx={{ height: 'max-content' }}>
      <CardHeader
        action={
          <IconButton
            color="default"
            data-cy="member-profile-network-edit"
            size="small"
            onClick={() => onEdit()}
          >
            <EditIcon color="primary" />
          </IconButton>
        }
        title={translate('careTeam.steps.contact')}
      />
      <CardContent className="grid-wrapper fit">
        <span className="grid-span-4 bold">
          {translate('careTeam.common.email')}:
        </span>
        <span className="grid-span-8">{person?.email || EMPTY}</span>
        <span className="grid-span-4 bold">
          {translate('careTeam.common.languages')}:
        </span>
        <span className="grid-span-8">
          {person?.languages?.length
            ? person.languages
                .map(({ language }) => language.toString())
                .join(', ')
            : EMPTY}
        </span>
        <span className="grid-span-4 bold">
          {translate('careTeam.common.phones')}:
        </span>
        <span className="grid-span-8">
          {person?.phones?.length
            ? person.phones.map(
                ({ id, phone: { number, type }, verifiedAt, verifiedBy }) => (
                  <p
                    className="no-margin flex gap"
                    key={`care-team-person-phone-${id}`}
                  >
                    {`${phoneUtil.format(
                      phoneUtil.parse(number, 'US'),
                      PhoneNumberFormat.NATIONAL,
                    )} (${type?.toString()})`}
                    {showContactVerified && (
                      <Tooltip
                        text={
                          verifiedAt ? (
                            <>
                              <p className="white">
                                {translate('careTeam.common.tooltipVerified')}
                              </p>
                              <p className="white italic no-margin">
                                {translate(
                                  'careTeam.common.tooltipVerifiedBy',
                                  {
                                    date: verifiedAt?.format(DATE_FORMAT_SHORT),
                                    user: verifiedBy?.fullName || EMPTY,
                                  },
                                )}
                              </p>
                            </>
                          ) : (
                            translate('careTeam.common.tooltipNotVerified')
                          )
                        }
                      >
                        {verifiedAt ? (
                          <CheckCircleIcon htmlColor={Colors.iconGreen} />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Tooltip>
                    )}
                  </p>
                ),
              )
            : EMPTY}
        </span>
        {!!person?.phones?.length && onEditConsent && (
          <>
            <span className="grid-span-4 bold">
              {translate('careTeam.common.textConsent')}:
            </span>
            <span className="grid-span-8 flex spaced">
              <div style={{ flex: 2 }}>
                {consents?.length
                  ? consents
                      ?.filter(
                        ({ consentRequestId }) =>
                          consentRequestId === consentRequest?.id,
                      )
                      ?.sort(({ type }) =>
                        type === MemberConsentType.VERBAL ? -1 : 1,
                      )
                      ?.map((answer) => (
                        <p
                          className={styles.answer}
                          key={`answer-${answer.id}`}
                        >
                          {getAnswerIcon(answer)}
                        </p>
                      ))
                  : EMPTY}
              </div>
              <IconButton
                className={styles.editIcon}
                color="default"
                data-cy="member-profile-network-edit"
                size="small"
                onClick={() => onEditConsent()}
              >
                <EditIcon color="primary" />
              </IconButton>
            </span>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const AddressCard = ({ className, person, onEdit }: CardProps) => (
  <Card className={className} sx={{ height: 'max-content' }}>
    <CardHeader
      action={
        <IconButton
          color="default"
          data-cy="member-profile-network-edit"
          size="small"
          onClick={() => onEdit()}
        >
          <EditIcon color="primary" />
        </IconButton>
      }
      title={translate('careTeam.common.address')}
    />
    <CardContent className="grid-wrapper fit">
      <span className="grid-span-4 bold">
        {translate('careTeam.common.address')}:
      </span>
      <div className="grid-span-8">
        {person?.address ? (
          <>
            <p className="no-margin">{person.address.address1 || EMPTY}</p>
            {person.address.address2 && (
              <p className="no-margin">{person.address.address2}</p>
            )}
            <p className="no-margin">
              {[
                person.address.city,
                person.address.state?.value,
                person.address.zipCode,
              ]
                .filter(Boolean)
                .join(', ') || ''}
            </p>
          </>
        ) : (
          EMPTY
        )}
        {person?.address && (
          <>
            <br />
            <span className="flex gap">
              {person.verifiedAddress ? (
                <CheckCircleIcon color="primary" />
              ) : (
                <CancelIcon color="error" />
              )}
              {person.verifiedAddress
                ? translate('global.verified')
                : translate('global.notVerified')}
            </span>
          </>
        )}
      </div>
    </CardContent>
  </Card>
);

export const ProfileCareTeamPersonStep = ({
  consents,
  consentRequest,
  onEditAddress,
  onEditContact,
  onEditEngagement,
  onEditTextConsent,
  person,
  refetchCTP,
  showContactVerified,
}: Props) => (
  <>
    <div className="grid-span-5 grid-wrapper fit">
      <ContactCard
        className="grid-span-12"
        consents={consents}
        consentRequest={consentRequest}
        person={person}
        onEdit={onEditContact}
        onEditConsent={onEditTextConsent}
        showContactVerified={showContactVerified}
      />
      <AddressCard
        className="grid-span-12"
        person={person}
        onEdit={onEditAddress}
      />
    </div>

    <EngagementCard
      consents={consents}
      className="grid-span-7"
      person={person}
      onEdit={onEditEngagement}
      refetchCTP={refetchCTP}
    />
  </>
);

export default ProfileCareTeamPersonStep;
