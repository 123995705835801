import moment, { Moment } from 'moment';

import { MemberStatus } from '../enums';
import { BaseEnum } from './BaseEnum';
import { Employee } from './Employee';
import { EmployeeGroup } from './EmployeeGroup';
import { EventStatus } from './EventStatus';
import { EventType } from './EventType';
import { make } from './Model';
import { Patient } from './Patient';

export class EventModel {
  assignee?: Employee;

  assigneeGroup?: EmployeeGroup;

  closedAt = 0;

  closedBy: Employee;

  createdAt = 0;

  createdBy: Employee;

  datetime: Moment;

  followUpDatetime?: Moment;

  followUpDatetimeHasTime?: boolean;

  hasTime = false;

  id = 0;

  incidentId = 0;

  invalidatedReason?: BaseEnum;

  invalidNote = '';

  lastNoteAt = 0;

  lastNoteBy?: Employee;

  numberOfIssues = 0;

  resolution?: BaseEnum;

  resolutionOther?: string;

  skilledRecordId = 0;

  status: EventStatus;

  tocRecordId = 0;

  type: EventType;

  updatedAt = 0;

  updatedBy: Employee;

  urgencyLevel?: number;

  urgent = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.assignee = make(obj.assignee, Employee);
    this.assigneeGroup = make(obj.assigneeGroup, EmployeeGroup);
    this.closedBy = make(obj.closedBy, Employee);
    this.createdBy = make(obj.createdBy, Employee);
    this.datetime = moment.unix(obj.datetime);
    this.followUpDatetime = obj.followUpDatetime
      ? moment.unix(obj.followUpDatetime)
      : undefined;
    this.invalidatedReason = make(obj.invalidatedReason, BaseEnum);
    this.lastNoteBy = make(obj.lastNoteBy, Employee);
    this.resolution = make(obj.resolution, BaseEnum);
    this.status = make(obj.status, EventStatus);
    this.type = make(obj.typeId, EventType);
    this.updatedBy = make(obj.updatedBy, Employee);
  }

  get isClosed() {
    return this.status.isClosedStatus;
  }

  get isInvalid() {
    return this.status.id === EventStatus.INVALID;
  }

  get isDocumentationOnly() {
    return this.status.id === EventStatus.DOCUMENTATION_ONLY;
  }

  get isReadyToClose() {
    return !this.isClosed && this.status.id !== EventStatus.NOT_STARTED;
  }

  canForceCloseEvent(actingUser?: Employee, member?: Patient) {
    return (
      !this.isClosed &&
      ((this?.assignee?.id === actingUser?.id &&
        actingUser?.canEventForceClose) ||
        (this.assigneeGroup?.id === EmployeeGroup.ENROLLMENT &&
          actingUser?.isInEnrollment)) &&
      member?.status !== MemberStatus.ACTIVE
    );
  }
}

export default EventModel;
