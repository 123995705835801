import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import Cancelcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamMember,
  CareTeamMemberType,
  Patient,
  UserPhoneVerified,
} from '@vestahealthcare/common/models';

import { Chip, Colors, Modal, PhoneLink, Tooltip } from 'styleguide-v2';

import { CareTeamMemberPermissions } from 'dash/src/pages/CareTeam/components/CareTeamMemberPermissions';
import { useSelector } from 'dash/src/redux/store';
import { fetchMemberCareTeamMembers } from 'dash/src/services/CareTeamServices';

interface Props {
  member: Patient;
  onClose: () => void;
  open?: boolean;
  getCareTeam?: boolean;
}

const useStyles = makeStyles({
  header: {
    marginTop: '2.5rem',
  },
  row: {
    padding: '0.5rem',
  },
  rowEven: {
    backgroundColor: `${Colors.gray}33`,
    borderRadius: '0.5rem',
  },
});

const getVerifiedIcon = (
  type: CareTeamMemberType,
  phone: UserPhoneVerified,
) => {
  if (type?.id === CareTeamMemberType.PROVIDER) {
    return (
      <Tooltip
        text={translate(
          `careTeam.common.${
            phone?.verifiedAt ? 'tooltipVerified' : 'tooltipNotVerified'
          }`,
        )}
      >
        {phone?.verifiedAt ? (
          <CheckIcon htmlColor={Colors.iconGreen} />
        ) : (
          <Cancelcon color="error" />
        )}
      </Tooltip>
    );
  }
};

export const ContactInfoModal = (props: Props) => {
  const { member, open, onClose } = props;
  const styles = useStyles();

  const [careTeamMembersFetch, setCareTeamMembersFetch] = useState<
    CareTeamMember[]
  >([]);

  const careTeamMembersState = useSelector(
    (state) => state.memberInfoSlice.careTeamMembers,
  );

  const getCTMembers = async () => {
    const { items: members } = await fetchMemberCareTeamMembers(member.id);
    setCareTeamMembersFetch(members);
  };

  useEffect(() => {
    if (props.getCareTeam) {
      getCTMembers();
    }
  }, [member]);

  const careTeamMembers = props.getCareTeam
    ? careTeamMembersFetch
    : careTeamMembersState;

  return (
    <Modal
      title={translate('memberProfile.header.careTeam')}
      maxWidth="lg"
      onClose={onClose}
      open={!!open}
    >
      <h4>{translate('memberProfile.header.member')}</h4>
      <div className={classNames('grid-wrapper', styles.row)}>
        <span className="grid-span-2">{member.fullName}</span>
        <div className="grid-span-7" />
        <div className="grid-span-1 flex wrap gap">
          {member?.languages?.map((item) => (
            <Chip
              color="info"
              key={`member-language-${item.value}`}
              type="outlined"
              size="small"
            >
              {item.toString()}
            </Chip>
          ))}
        </div>
        <div className="grid-span-2">
          {member.primaryPhone && (
            <PhoneLink
              compact
              phone={member.primaryPhone.phone.number}
              type={member.primaryPhone.phone.type?.toString()}
              primary
            />
          )}
        </div>
      </div>
      {member.phones
        ?.filter(({ primary }) => !primary)
        ?.map(({ phone }, index) => (
          <div
            key={phone.number}
            className={classNames(
              'grid-wrapper',
              index % 2 === 0 && styles.rowEven,
              styles.row,
            )}
          >
            <div className="grid-span-10" />
            <div className="grid-span-2">
              <PhoneLink
                compact
                phone={phone.number}
                type={phone.type?.toString()}
              />
            </div>
          </div>
        ))}

      {!!careTeamMembers?.filter(({ active }) => active)?.length && (
        <h4 className={styles.header}>
          {translate('memberProfile.header.careTeam')}
        </h4>
      )}

      {careTeamMembers
        ?.filter(({ active }) => active)
        ?.map((member, index) => {
          const {
            careTeamPerson: { id, fullName, phones, languages },
            type,
            typeWithSubType,
          } = member;
          return (
            <Fragment key={`contact-caregiver-${id}`}>
              <div
                className={classNames(
                  'grid-wrapper',
                  index % 2 === 1 && styles.rowEven,
                  styles.row,
                )}
              >
                <span className="grid-span-2">{fullName}</span>
                <div className="grid-span-3 flex gap wrap">
                  {!!typeWithSubType.type && (
                    <Chip color="primary" type="outlined" size="small">
                      {typeWithSubType.type?.description}
                    </Chip>
                  )}
                  {!!typeWithSubType.subtype && (
                    <Chip color="secondary" type="outlined" size="small">
                      {typeWithSubType.subtype}
                    </Chip>
                  )}
                </div>
                <div className="grid-span-4 flex gap wrap">
                  <CareTeamMemberPermissions
                    member={member as CareTeamMember}
                  />
                </div>
                <div className="grid-span-1 flex gap wrap">
                  {languages?.map((item) => (
                    <Chip
                      color="info"
                      key={`member-language-${item.language.value}`}
                      type={item.preferred ? 'contained' : 'outlined'}
                      size="small"
                    >
                      {item.language.toString()}
                    </Chip>
                  ))}
                </div>
                <div className="grid-span-2">
                  {phones && phones[0]?.phone?.number && (
                    <PhoneLink
                      compact
                      icon={getVerifiedIcon(type, phones[0])}
                      phone={phones[0].phone.number}
                      primary={phones[0].primary}
                      type={phones[0].phone.type?.toString()}
                    />
                  )}
                </div>
              </div>
              {phones.length > 1 &&
                phones?.slice(1)?.map((otherPhone) => (
                  <div
                    key={`contact-caregiver-${id}-phone-${otherPhone.phone.number}`}
                    className={classNames(
                      'grid-wrapper',
                      index % 2 === 1 && styles.rowEven,
                      styles.row,
                    )}
                  >
                    <div className="grid-span-10" />
                    <div className="grid-span-2">
                      {otherPhone && (
                        <PhoneLink
                          compact
                          icon={getVerifiedIcon(type, otherPhone)}
                          phone={otherPhone.phone.number}
                          primary={otherPhone.primary}
                          type={otherPhone.phone.type?.toString()}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </Fragment>
          );
        })}
    </Modal>
  );
};

export default ContactInfoModal;
