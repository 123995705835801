import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import { Enum, States } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  Facility,
  InsurancePlan,
  InsurancePlanEcw,
} from '@vestahealthcare/common/models';

import {
  BackgroundColors,
  Button,
  CollapsableSidebar,
  Colors,
  IconButton,
  Select,
  Switch,
  SwitchGroup,
  TextInput,
} from 'styleguide-v2';

import CacheServices from 'dash/src/services/CacheServices';
import { fetchFacilities } from 'dash/src/services/FacilityServices';
import { InsurancePlanUpdateParams } from 'dash/src/services/InsurancePlansServices';
import Session from 'dash/src/services/SessionServices';

const MAX_INT_VALUE = 2 ** 31 - 1;
interface Props {
  insurancePlan?: InsurancePlan;
  onClose: () => void;
  onSubmit: (id: number, params: InsurancePlanUpdateParams) => Promise<boolean>;
}

const useStyles = makeStyles({
  inNetworkLink: {
    fontSize: '0.875em',
    marginLeft: '0.5rem',
  },
  inNetworkLinkException: {
    color: Colors.textGreen,
    textDecoration: 'underline',

    '&:hover': {
      color: Colors.iconGreen,
    },
  },
  inNetworkLinkDefault: {
    color: Colors.iconGray,
  },

  inNetworkExceptionContainer: {
    border: `1px solid ${BackgroundColors.lightGray}`,
    borderRadius: '5px',
    gap: '10px 0',
    padding: '1rem',
    width: '100%',
  },
  firstIcon: {
    '&&': {
      margin: '2.25rem auto auto',
    },
  },
  icon: {
    margin: '0.5rem auto auto',
  },
});

const InsurancePlansEdit = ({ insurancePlan, onClose, onSubmit }: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [id, setDashId] = useState(0);
  const [ecwIds, setECWIds] = useState<InsurancePlanEcw[]>([]);
  const [name, setName] = useState('');
  const [inNetwork, setInNetwork] = useState(false);
  const [isMedicare, setIsMedicare] = useState(false);
  const [isMedicaid, setIsMedicaid] = useState(false);
  const [isGeneric, setIsGeneric] = useState(false);
  const [abilityPayerId, setAbilityPayerId] = useState<number>();
  const [states, setStates] = useState<States[]>([]);

  const [inNetworkExceptions, setInNetworkExceptions] = useState<boolean>(
    false,
  );
  const [exceptions, setExceptions] = useState<(Facility | undefined)[]>([
    undefined,
  ]);
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [insuranceTypes, setInsuranceTypes] = useState<BaseEnum[]>([]);

  const canEditECW = Session.actingUser.canEditInsuranceECW;

  const getInitialData = async () => {
    setLoadingData(true);
    const [{ items }, it] = await Promise.all([
      fetchFacilities({
        limit: -1,
      }),
      CacheServices.getInsuranceTypes(),
    ]);

    setFacilities(items);
    setInsuranceTypes(it);
    setLoadingData(false);
  };

  useEffect(() => {
    setSubmitted(false);
    if (insurancePlan) {
      const {
        id,
        abilityPayerId,
        ecwIds,
        facilityExceptions,
        name,
        isGeneric,
        inNetwork,
        isMedicaid,
        isMedicare,
        states,
      } = insurancePlan;
      setExceptions(
        facilityExceptions?.length ? facilityExceptions : [undefined],
      );
      setInNetworkExceptions(!!facilityExceptions?.length);
      setDashId(id);
      setECWIds(ecwIds?.length ? [...ecwIds] : [new InsurancePlanEcw({})]);
      setName(name);
      setInNetwork(inNetwork);
      setIsMedicaid(isMedicaid);
      setIsMedicare(isMedicare);
      setIsGeneric(!!isGeneric);
      setAbilityPayerId(abilityPayerId);
      setStates(states || []);
    }
  }, [insurancePlan]);

  const doSubmit = async () => {
    setSubmitted(true);
    setLoading(true);

    const result = await onSubmit(insurancePlan?.id || 0, {
      abilityPayerId,
      ecwIds: ecwIds.filter(({ ecwId }) => !!ecwId),
      facilityExceptions: inNetworkExceptions
        ? exceptions
            ?.filter((f): f is Facility => Boolean(f))
            ?.map(({ id }) => id)
        : [],
      inNetwork,
      isGeneric,
      isMedicaid,
      isMedicare,
      name,
      states,
    });
    if (result) {
      onClose();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!facilities?.length) {
      getInitialData();
    }
  }, []);

  const isEdit = () => !!insurancePlan?.id;
  const didStatesChange = () =>
    states.sort().join(',') !== insurancePlan?.states.sort().join(',');

  const validateInNetworkStates = () => !inNetwork || states.length > 0;

  const validateEcwIds = () =>
    ecwIds?.length < 2 ||
    !ecwIds.find(
      ({ ecwId, insuranceType, planNumber }) =>
        !ecwId || (!insuranceType && !planNumber),
    );

  const hasChanges = () => {
    const commonValidation =
      !!name &&
      (!inNetwork || (inNetwork && states.length > 0)) &&
      (!inNetworkExceptions || exceptions.findIndex((item) => !item) === -1) &&
      (!isGeneric || states.length === 1) &&
      (!abilityPayerId || abilityPayerId <= MAX_INT_VALUE) &&
      validateInNetworkStates() &&
      validateEcwIds();

    if (isEdit()) {
      return (
        commonValidation &&
        (JSON.stringify(ecwIds) !== JSON.stringify(insurancePlan?.ecwIds) ||
          isMedicaid !== insurancePlan?.isMedicaid ||
          isGeneric !== insurancePlan?.isGeneric ||
          isMedicare !== insurancePlan?.isMedicare ||
          inNetwork !== insurancePlan?.inNetwork ||
          abilityPayerId !== insurancePlan?.abilityPayerId ||
          didStatesChange() ||
          !!insurancePlan?.facilityExceptions?.length !== inNetworkExceptions ||
          JSON.stringify(
            insurancePlan?.facilityExceptions?.map(({ id }) => id),
          ) !== JSON.stringify(exceptions?.map((item) => item?.id)))
      );
    }
    return commonValidation;
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!insurancePlan}
      title={
        <h2>
          {translate(`insurancePlans.${isEdit() ? 'edit' : 'add'}ModalTitle`)}
        </h2>
      }
      size={550}
    >
      <CollapsableSidebar.Body>
        {isEdit() && (
          <TextInput
            disabled
            label={translate('insurancePlans.dashId')}
            value={id}
          />
        )}
        <div className="grid-wrapper fit">
          {ecwIds.map(({ ecwId, insuranceType, planNumber }, idx) => (
            <Fragment key={`insurance-plan-edit-ecw-id-${idx}`}>
              {idx !== 0 && <hr className="grid-span-12 no-margin" />}
              <TextInput
                className={`grid-span-${canEditECW ? 11 : 12}`}
                disabled={!canEditECW}
                label={
                  idx === 0 ? translate('insurancePlans.ecwIds') : undefined
                }
                type="number"
                onChange={(value) => {
                  if (ecwIds[idx]) {
                    ecwIds[idx] = {
                      ...ecwIds[idx],
                      ecwId: value || '',
                    };
                    setECWIds([...ecwIds]);
                  }
                }}
                placeholder={translate('insurancePlans.ecwId')}
                value={ecwId}
              />
              {canEditECW && (
                <IconButton
                  className={classNames(
                    idx === 0 && styles.firstIcon,
                    styles.icon,
                    'grid-span-1',
                  )}
                  disabled={ecwIds && ecwIds.length < 2}
                  size="small"
                  onClick={() => {
                    ecwIds?.splice(idx, 1);
                    setECWIds([...ecwIds]);
                  }}
                >
                  <DeleteOutlineIcon fontSize="large" />
                </IconButton>
              )}
              {ecwIds?.length > 1 && (
                <>
                  <Select
                    className="grid-span-6"
                    disabled={!!planNumber || !canEditECW}
                    items={insuranceTypes}
                    required={inNetwork}
                    placeholder={translate('insurancePlans.insuranceType')}
                    onChange={(val?: BaseEnum) => {
                      if (ecwIds[idx]) {
                        ecwIds[idx] = {
                          ...ecwIds[idx],
                          insuranceType: val?.id || null,
                        };
                        setECWIds([...ecwIds]);
                      }
                    }}
                    value={insuranceTypes?.find(
                      ({ id }) => id === insuranceType,
                    )}
                  />
                  <TextInput
                    className="grid-span-6"
                    disabled={!!insuranceType || !canEditECW}
                    placeholder={translate('insurancePlans.planNumber')}
                    onChange={(value) => {
                      if (ecwIds[idx]) {
                        ecwIds[idx] = {
                          ...ecwIds[idx],
                          planNumber: value || null,
                        };
                        setECWIds([...ecwIds]);
                      }
                    }}
                    value={planNumber || ''}
                  />
                </>
              )}
            </Fragment>
          ))}
          {canEditECW && (
            <a
              className={classNames(
                'grid-span-12 flex middle',
                styles.inNetworkLink,
                styles.inNetworkLinkException,
              )}
              onClick={(evt) => {
                evt.preventDefault();
                setECWIds([...ecwIds, new InsurancePlanEcw({})]);
              }}
              style={{ textDecoration: 'none' }}
            >
              <AddIcon fontSize="small" />{' '}
              {translate('insurancePlans.addECWID')}
            </a>
          )}
        </div>
        <TextInput
          disabled={isEdit()}
          label={translate('insurancePlans.name')}
          onChange={(value) => setName(value || '')}
          value={name}
          required
        />
        <TextInput
          error={
            !!abilityPayerId &&
            abilityPayerId > MAX_INT_VALUE &&
            translate('common.errors.maxValue', { value: MAX_INT_VALUE })
          }
          label={translate('insurancePlans.abilityPayerId')}
          onChange={(value) =>
            setAbilityPayerId(value ? parseInt(value, 10) : undefined)
          }
          type="number"
          value={abilityPayerId}
        />
        <Select
          className="grid-span-12"
          data-cy="employees-modal-state"
          error={
            isGeneric && states.length !== 1
              ? translate('insurancePlans.errorStateLength')
              : undefined
          }
          items={States.toSelectable(States.asArray)}
          multiple
          required={inNetwork}
          label={translate('employees.modal.state')}
          onChange={(val: Selectable[]) =>
            setStates(val?.map(({ value }) => States.byKey[value]) || [])
          }
          value={Enum.toSelectable(states)}
        />
        <div className="grid-span-12">
          <SwitchGroup
            items={[
              {
                label: (
                  <span>
                    {translate('insurancePlans.inNetwork')}
                    &nbsp;&nbsp;
                    <a
                      className={classNames(
                        styles.inNetworkLink,
                        inNetworkExceptions
                          ? styles.inNetworkLinkDefault
                          : styles.inNetworkLinkException,
                      )}
                      onClick={(evt) => {
                        evt.preventDefault();
                        setInNetworkExceptions(!inNetworkExceptions);
                      }}
                    >
                      {inNetworkExceptions
                        ? `(${translate('insurancePlans.default')})`
                        : translate('insurancePlans.inNetworkAddException')}
                    </a>
                  </span>
                ),
                checked: inNetwork,
              },
            ]}
            onChange={(items) => {
              setInNetwork(!!items[0].checked);
            }}
          />
          {inNetworkExceptions && (
            <div
              className={classNames(
                'grid-span-12 grid-wrapper',
                styles.inNetworkExceptionContainer,
              )}
            >
              {exceptions.map((exception, idx) => (
                <Fragment key={`exception-facility-${idx}`}>
                  <Select
                    className="grid-span-10"
                    disableClearable
                    error={submitted && !exception}
                    getItemLabel={(f: Facility) => f?.name || ''}
                    items={facilities}
                    label={
                      idx === 0
                        ? translate('insurancePlans.eligibilityExceptions')
                        : undefined
                    }
                    loading={loadingData}
                    onChange={(val?: Facility) => {
                      if (val) {
                        exceptions[idx] = val;
                        setExceptions([...exceptions]);
                      }
                    }}
                    value={exception}
                    required
                  />
                  <Switch
                    className={classNames(
                      idx === 0 && styles.firstIcon,
                      styles.icon,
                      'grid-span-1',
                    )}
                    checked={!inNetwork}
                    disabled
                    onChange={() => {}}
                  />
                  <IconButton
                    className={classNames(
                      idx === 0 && styles.firstIcon,
                      styles.icon,
                      'grid-span-1',
                    )}
                    size="small"
                    onClick={() => {
                      if (exceptions.length < 2) {
                        setExceptions([undefined]);
                        setInNetworkExceptions(false);
                      } else {
                        exceptions?.splice(idx, 1);
                        setExceptions([...exceptions]);
                      }
                    }}
                  >
                    <DeleteOutlineIcon fontSize="large" />
                  </IconButton>
                </Fragment>
              ))}
              <a
                className={classNames(
                  'grid-span-12 flex middle',
                  styles.inNetworkLink,
                  styles.inNetworkLinkException,
                )}
                onClick={(evt) => {
                  evt.preventDefault();
                  setExceptions([...exceptions, undefined]);
                }}
                style={{ textDecoration: 'none' }}
              >
                <AddIcon fontSize="small" />{' '}
                {translate('insurancePlans.eligibilityExceptionsAdd')}
              </a>
            </div>
          )}
          <SwitchGroup
            items={[
              {
                label: translate('insurancePlans.isMedicare'),
                checked: isMedicare,
              },
              {
                label: translate('insurancePlans.isMedicaid'),
                checked: isMedicaid,
              },
              {
                label: translate('insurancePlans.isGeneric'),
                checked: isGeneric,
              },
            ]}
            onChange={(items) => {
              setIsMedicare(!!items[0].checked);
              setIsMedicaid(!!items[1].checked);
              setIsGeneric(!!items[2].checked);
            }}
          />
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-group-close" onClick={onClose}>
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-group-submit"
          disabled={!hasChanges()}
          loading={loading}
          onClick={doSubmit}
        >
          {translate(`global.${isEdit() ? 'save' : 'add'}`)}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default InsurancePlansEdit;
