import React, { useEffect, useState } from 'react';

import {
  EmployeeGroupStatus,
  EmployeeGroupType,
  Enum,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { EmployeeGroup } from '@vestahealthcare/common/models';

import {
  Button,
  CollapsableSidebar,
  PanelInfo,
  Select,
  SwitchGroup,
  TextInput,
} from 'styleguide-v2';

import { UpdateEmployeeGroupParams } from 'dash/src/services/EmployeeServices';

interface Props {
  group?: EmployeeGroup;
  groups: EmployeeGroup[];
  onClose: () => void;
  onSubmit: (id: number, params: UpdateEmployeeGroupParams) => Promise<boolean>;
}

const GroupsEditModal = ({ group, groups, onClose, onSubmit }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [type, setType] = useState<EmployeeGroupType>(
    EmployeeGroupType.ASSIGNEE,
  );
  const [active, setActive] = useState<boolean>(true);

  useEffect(() => {
    if (group) {
      setName(group.name);
      setType(group.type || EmployeeGroupType.ASSIGNEE);
      setActive(group.active);
    }
  }, [group]);

  const validate = () => {
    if (group && name && type) {
      if (
        name === group.name ||
        !groups.find(
          ({ name: gName }) =>
            name.toLocaleLowerCase() === gName.toLocaleLowerCase(),
        )
      ) {
        return true;
      }
    }
  };

  const getErrorText = () => {
    if (submitted && group) {
      if (!name) {
        return translate('global.missingRequiredFieldMin');
      }
      if (
        name !== group.name &&
        groups.find(
          ({ name: gName }) =>
            name.toLocaleLowerCase() === gName.toLocaleLowerCase(),
        )
      ) {
        return translate('global.uniqueConstraintField');
      }
    }
  };

  const doSubmit = async () => {
    setSubmitted(true);
    if (validate()) {
      setLoading(true);
      try {
        await onSubmit(group?.id || 0, {
          type,
          status: active
            ? EmployeeGroupStatus.ACTIVE
            : EmployeeGroupStatus.INACTIVE,
          name,
        });
        onClose();
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  const hasChanges = () =>
    name !== group?.name || active !== group?.active || type !== group?.type;

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!group}
      title={
        <h2>
          {group?.id
            ? translate('groups.editModalTitle')
            : translate('groups.addModalTitle')}
        </h2>
      }
    >
      <CollapsableSidebar.Body>
        <TextInput
          error={getErrorText()}
          label={translate('groups.name')}
          onChange={(value) => {
            const safeValue = value
              ?.trim()
              .split(' ')
              .filter(Boolean)
              .join(' ');
            setName(safeValue || '');
          }}
          value={name}
        />
        <Select
          disableClearable
          error={submitted && !type}
          items={Enum.toSelectable(EmployeeGroupType.asArray)}
          label={translate('groups.type')}
          onChange={(item: Selectable) => {
            setType(EmployeeGroupType.byKey[item.value]);
          }}
          value={type ? Enum.toSelectable([type])[0] : undefined}
          required
        />
        <SwitchGroup
          items={[
            {
              label: translate('groups.active'),
              checked: active,
            },
          ]}
          onChange={(items) => setActive(!!items[0].checked)}
        />
        {group?.isProtected && group?.active && !active && (
          <PanelInfo type="warning">
            {translate('groups.warningProtected')}
          </PanelInfo>
        )}
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-group-close" onClick={onClose}>
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-group-submit"
          disabled={!hasChanges()}
          loading={loading}
          onClick={doSubmit}
        >
          {group?.id ? translate('global.save') : translate('global.add')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default GroupsEditModal;
