import moment, { Moment } from 'moment';

import { Employee } from './Employee';
import { UpdateInfo } from './Helpers/UpdateInfo';
import { make } from './Model';

export class EventNote implements UpdateInfo {
  id = 0;

  addendum = false;

  note = '';

  createdBy: Employee;

  updatedBy: Employee;

  createdAt: Moment;

  updatedAt: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
    this.createdAt = moment.unix(obj.createdAt);
    this.updatedAt = moment.unix(obj.updatedAt);
  }
}

export default EventNote;
