import { Enum } from './Enum';

export class MemberStatus extends Enum {
  translationKey = 'memberStatus';

  static asArray: MemberStatus[] = [];

  static byKey: { [name: string]: MemberStatus } = {};

  static readonly ACCEPTED = new MemberStatus('ACCEPTED');

  static readonly ACTIVE = new MemberStatus('ACTIVE');

  static readonly DECEASED_ONBOARDED = new MemberStatus('DECEASED_ONBOARDED');

  static readonly DECEASED_NON_ONBOARDED = new MemberStatus(
    'DECEASED_NON_ONBOARDED',
  );

  static readonly DISENROLLED_INSTITUTIONALIZED = new MemberStatus(
    'DISENROLLED_INSTITUTIONALIZED',
  );

  static readonly DISENROLLED_HOSPICE = new MemberStatus('DISENROLLED_HOSPICE');

  static readonly DISENROLLED_OON_INSURANCE = new MemberStatus(
    'DISENROLLED_OON_INSURANCE',
  );

  static readonly DISENROLLED_UNRESPONSIVE = new MemberStatus(
    'DISENROLLED_UNRESPONSIVE',
  );

  static readonly DISENROLLED_UNSUPPORTED_STATE = new MemberStatus(
    'DISENROLLED_UNSUPPORTED_STATE',
  );

  static readonly DISENROLLED_WITHDRAW = new MemberStatus(
    'DISENROLLED_WITHDRAW',
  );

  static readonly INVALID = new MemberStatus('INVALID');

  static readonly PASSIVE = new MemberStatus('PASSIVE');

  constructor(public readonly value: string) {
    super();
    MemberStatus.asArray.push(this);
    MemberStatus.byKey[value] = this;
  }

  get description() {
    return this.toString();
  }

  static toSelectable() {
    return Enum.toSelectable(MemberStatus.asArray);
  }

  static toFilterSelectable() {
    return Enum.toSelectable(MemberStatus.asArray);
  }

  static COLOR_MAP = new Map<
    MemberStatus,
    'primary' | 'secondary' | 'info' | 'warning' | 'error' | 'action'
  >([
    [MemberStatus.ACCEPTED, 'secondary'],
    [MemberStatus.ACTIVE, 'secondary'],
    [MemberStatus.DECEASED_ONBOARDED, 'action'],
    [MemberStatus.DECEASED_NON_ONBOARDED, 'action'],
    [MemberStatus.DISENROLLED_INSTITUTIONALIZED, 'action'],
    [MemberStatus.DISENROLLED_HOSPICE, 'action'],
    [MemberStatus.DISENROLLED_OON_INSURANCE, 'action'],
    [MemberStatus.DISENROLLED_UNRESPONSIVE, 'action'],
    [MemberStatus.DISENROLLED_UNSUPPORTED_STATE, 'action'],
    [MemberStatus.DISENROLLED_WITHDRAW, 'action'],
    [MemberStatus.INVALID, 'error'],
    [MemberStatus.PASSIVE, 'warning'],
  ]);
}

export default MemberStatus;
