import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';

import { MemberDashboardKeyContactType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  ClinicalMember,
  MemberDashboardKeyContact,
  MemberWarning,
  Patient,
} from '@vestahealthcare/common/models';
import MemberWarningType from '@vestahealthcare/common/models/member-warnings/MemberWarningType';

import {
  BackgroundColors,
  Card,
  Chip,
  Collapse,
  CollapseIcon,
  Colors,
  Fonts,
  PhoneLink,
} from 'styleguide-v2';

import Session from 'dash/src/services/SessionServices';

type Props = {
  className?: string;
  data?: MemberDashboardKeyContact[];
  member: Patient | ClinicalMember;
  warnings?: MemberWarning[];
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },
  },
  dataContainer: {
    display: 'flex',
    flewWrap: 'wrap',
    gap: '0.5rem',

    '& > *': {
      flex: `1 1 calc(100% - 1rem)`,
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
  subtitle: {
    color: Colors.iconGray,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: `calc(${Fonts.fontSize} * 0.625)`,
    letterSpacing: '1px',
    marginBottom: '-0.5rem',
  },
  collapsed: {
    borderTop: `1px solid ${BackgroundColors.grayLine}!important`,
  },
  items: {
    '& > div': {
      padding: '1rem 0',
      width: '90%',
    },
    '& > div:not(:last-child)': {
      borderBottom: `1px solid ${BackgroundColors.grayLine}!important`,
    },
  },
  itemsEmpty: {
    margin: '1rem 0 0',
  },
  name: {
    color: Colors.textBlack,
    fontWeight: 500,
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    marginBottom: 0,
  },
  phone: {
    '&& > a': {
      color: Colors.iconGray,
      fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    },
  },
  seeMore: {
    color: Colors.textGreen,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
});

const MAX_ITEMS = 3;

export const KeyContactsCard = ({
  className,
  data,
  member,
  warnings,
}: Props) => {
  const styles = useStyles();

  const memberId = useMemo(
    () => (member instanceof Patient ? member?.id : member.memberId),
    [member],
  );
  const openNewTab = useMemo(() => !(member instanceof Patient), [member]);
  const shouldShowLink = Session.actingUser.showMemberLink;

  const pcp = useMemo(
    () =>
      data?.filter(({ type }) => type === MemberDashboardKeyContactType.PCP),
    [data],
  );
  const pharmacy = useMemo(
    () =>
      data?.filter(
        ({ type }) => type === MemberDashboardKeyContactType.PHARMACY,
      ),
    [data],
  );

  const [openPCP, setOpenPCP] = useState<boolean>(false);
  const [openPharmacy, setOpenPharmacy] = useState<boolean>(false);

  const pharmacyWarning = warnings?.find(
    ({ type }) => type.id === MemberWarningType.MISSING_PHARMACY,
  );
  const pcpWarning = warnings?.find(
    ({ type }) => type.id === MemberWarningType.MISSING_PCP,
  );

  const renderPCP = ({ name, phones }: MemberDashboardKeyContact) => (
    <div key={`pcp-${name}`}>
      <p className={styles.name}>{name}</p>
      {phones?.length ? (
        phones?.map((phone) => (
          <p className="no-margin" key={phone}>
            <PhoneLink className={styles.phone} phone={phone} />
          </p>
        ))
      ) : (
        <p className="no-margin size-xs gray italic">
          {translate('memberProfile.dashboard.noPhone')}
        </p>
      )}
    </div>
  );

  const renderPharmacy = ({
    address,
    name,
    phones,
  }: MemberDashboardKeyContact) => (
    <div key={`pharmacy-${name}`}>
      <p className={styles.name}>{name}</p>
      {address ? (
        <>
          <p className="no-margin gray size-xs">{address.address1}</p>
          {(address.city || address.state || address.zipCode) && (
            <p className="no-margin gray size-xs">
              {`${address.city ? `${address.city}, ` : ''}${
                address.state?.value ? `${address.state?.value} ` : ''
              }${address.zipCode || ''}`}
            </p>
          )}
        </>
      ) : (
        <p className="no-margin gray size-xs italic">
          {translate('memberProfile.dashboard.noAddress')}
        </p>
      )}
      {phones?.length ? (
        phones?.map((phone) => (
          <p className="no-margin" key={phone}>
            <PhoneLink className={styles.phone} phone={phone} />
          </p>
        ))
      ) : (
        <p className="no-margin gray size-xs italic">
          {translate('memberProfile.dashboard.noPhone')}
        </p>
      )}
    </div>
  );

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={styles.subContainer}>
        <div className="flex spaced">
          <span className={styles.title}>
            {translate('memberProfile.dashboard.keyContacts')}
          </span>
          {shouldShowLink && (
            <a
              className="flex middle min-gap"
              href={`#/patients/${memberId}/network`}
              style={{ color: Colors.textGreen, fontWeight: 500 }}
              rel="noreferrer"
              target={openNewTab ? '_blank' : undefined}
            >
              {translate('memberProfile.dashboard.goToMemberNetwork')}
              <ForwardIcon fontSize="small" />
            </a>
          )}
        </div>
        <br />
        <div className={styles.dataContainer}>
          <div>
            <p className={styles.subtitle}>
              {translate('memberProfile.dashboard.pcp')}
            </p>

            {pcpWarning && (
              <Chip
                className={styles.itemsEmpty}
                color="error"
                type="contained-2"
              >
                <span className="bold">
                  {pcpWarning.type.description?.toLocaleUpperCase()}
                </span>
              </Chip>
            )}

            {!!pcp?.length && (
              <div className={styles.items}>
                {[...pcp].slice(0, MAX_ITEMS).map(renderPCP)}
              </div>
            )}

            {!!pcp?.length && (
              <Collapse open={openPCP}>
                <div className={classNames(styles.items, styles.collapsed)}>
                  {[...pcp].slice(MAX_ITEMS).map(renderPCP)}
                </div>
              </Collapse>
            )}
            {!!pcp?.length && pcp?.length > MAX_ITEMS && (
              <a
                className={classNames('flex middle min-gap', styles.seeMore)}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenPCP(!openPCP);
                }}
              >
                <CollapseIcon open={openPCP} htmlColor={Colors.textGreen} />
                {translate(
                  `memberProfile.dashboard.${openPCP ? 'hide' : 'see'}More`,
                  {
                    count: pcp.length - MAX_ITEMS,
                  },
                )}
              </a>
            )}
          </div>
          <div>
            <p className={styles.subtitle}>
              {translate('memberProfile.dashboard.pharmacy')}
            </p>

            {pharmacyWarning && (
              <Chip
                className={styles.itemsEmpty}
                color="error"
                type="contained-2"
              >
                <span className="bold">
                  {pharmacyWarning.type.description?.toLocaleUpperCase()}
                </span>
              </Chip>
            )}

            {!!pharmacy?.length && (
              <div className={styles.items}>
                {[...pharmacy].slice(0, MAX_ITEMS).map(renderPharmacy)}
              </div>
            )}

            {!!pharmacy?.length && (
              <Collapse open={openPharmacy}>
                <div className={classNames(styles.items, styles.collapsed)}>
                  {[...pharmacy].slice(MAX_ITEMS).map(renderPharmacy)}
                </div>
              </Collapse>
            )}
            {!!pharmacy?.length && pharmacy?.length > MAX_ITEMS && (
              <a
                className={classNames('flex middle min-gap', styles.seeMore)}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenPharmacy(!openPharmacy);
                }}
              >
                <CollapseIcon
                  open={openPharmacy}
                  htmlColor={Colors.textGreen}
                />
                {translate(
                  `memberProfile.dashboard.${
                    openPharmacy ? 'hide' : 'see'
                  }More`,
                  {
                    count: pharmacy.length - MAX_ITEMS,
                  },
                )}
              </a>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
