import { EmployeeRole } from '../enums';
import { make, makeArray, makeEnums } from './Model';
import { PodRuleTrigger } from './PodRuleTrigger';
import { ProgramExtension } from './ProgramExtension';

export class PodRule {
  id = 0;

  active = true;

  additional = false;

  default = false;

  description = '';

  flexible = false;

  maxAttempts = 0;

  name = '';

  overriddenBy?: PodRule[];

  programExtension: ProgramExtension;

  roles: EmployeeRole[];

  rolloverAfterPeriod = false;

  spacing = 0;

  triggers?: PodRuleTrigger[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.overriddenBy = makeArray(obj.overriddenBy, PodRule);
    this.programExtension = make(obj.programExtension, ProgramExtension);
    this.roles = makeEnums(obj.roles, EmployeeRole);
    this.triggers = makeArray(obj.triggers, PodRuleTrigger);
  }
}

export default PodRule;
