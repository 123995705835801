import { Brand } from '../../enums';

export interface PatientBrandHelper {
  brand?: Brand;
  isBrandCareAtHome: () => boolean;
}

export const isPatientBrandHelper = function testType(
  arg: any,
): arg is PatientBrandHelper {
  return arg && arg.brand && arg.isBrandCareAtHome;
};

export const hasBrandCareAtHome = ({ brand }: PatientBrandHelper) =>
  brand === Brand.CARE_AT_HOME;
