import { Language } from '../enums';
import {
  VestaCoreLiteHelper,
  isVPC,
  isVPCLite,
  isVestaCoreLite,
} from './Helpers/VestaCoreLiteHelper';
import { MemberProgramExtension } from './MemberProgramExtension';
import { makeArray } from './Model';
import { PatientBase } from './PatientBase';

export class EventMember extends PatientBase implements VestaCoreLiteHelper {
  language?: Language;

  programExtensions: MemberProgramExtension[] = [];

  state?: string;

  constructor(obj: any) {
    super(obj);
    this.language = obj.language ? Language.byKey[obj.language] : undefined;
    this.programExtensions = makeArray(
      obj.programExtensions || obj.memberProgramExtensions,
      MemberProgramExtension,
    );
    this.state = obj.state;
  }

  isVPC(): false | MemberProgramExtension {
    return isVPC(this as VestaCoreLiteHelper);
  }

  isVPCLite(): false | MemberProgramExtension {
    return isVPCLite(this as VestaCoreLiteHelper);
  }

  isVestaCoreLite(): false | MemberProgramExtension {
    return isVestaCoreLite(this as VestaCoreLiteHelper);
  }
}

export default EventMember;
