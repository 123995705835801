import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  CareTeamMember,
  CareTeamMemberType,
  CareTeamPersonExtended,
} from '@vestahealthcare/common/models';
import ConsentRequest from '@vestahealthcare/common/models/ConsentRequest';
import MemberConsentAnswer from '@vestahealthcare/common/models/MemberConsentAnswer';

import {
  Button,
  Colors,
  Fonts,
  Icon,
  IconButton,
  Panel,
  Select,
  TextInput,
} from 'styleguide-v2';

import { ConsentEditModal } from 'dash/src/components/ConsentEditModal';
import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CareTeamMemberDeleteModal } from 'dash/src/pages/CareTeam/modals/CareTeamMemberDelete';
import { CareTeamMemberEditModal } from 'dash/src/pages/CareTeam/modals/CareTeamMemberEdit';
import { CacheServices } from 'dash/src/services';
import {
  CreateCareTeamMemberParams,
  UpdateCareTeamPersonParams,
  activateCareTeamMember,
  changeRoleCareTeamMember,
  createCareTeamMember,
  fetchCareTeamPerson,
  invalidateCareTeamMember,
  updateCareTeamMember,
  updateCareTeamPerson,
} from 'dash/src/services/CareTeamServices';
import {
  createCTPConsentAnswer,
  deleteCTPConsentAnswer,
  fetchCTPConsentAnswers,
  updateCTPConsentAnswer,
} from 'dash/src/services/ConsentServices';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { LinkCareTeamPersonStep } from './Steps/LinkCareTeamPersonStep';
import { ProfileCareTeamPersonStep } from './Steps/ProfileCareTeamPersonStep';
import { EditAddressModal } from './modals/EditAddressModal';
import { EditContactModal } from './modals/EditContactModal';
import { EditEngagementModal } from './modals/EditEngagementModal';

const useStyles = makeStyles({
  back: {
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
  upperTitle: {
    alignSelf: 'flex-start',
    color: Colors.textGray,
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    marginTop: '0.25rem',
    fontWeight: 400,
  },
  title: {
    height: '2.9rem',
  },
  titleContainer: {
    alignItems: 'flex-start',
    display: 'grid',
    marginTop: '-1.5rem',

    '& > a': {
      height: '2rem',
    },
  },
  subtitleContainer: {
    marginRight: '3.3rem',
  },
  status: {
    width: '25rem',
  },
  white: {
    '&&': {
      background: Colors.white,
    },
  },
});

type Params = {
  id: string;
};

type Props = {
  showBackButton?: boolean;
};

export const CareTeamDetail = ({ showBackButton }: Props) => {
  const styles = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();

  const currentParams = useParams<Params>();

  const [statuses, setStatuses] = useState<BaseEnum[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [edittingTitle, setEdittingTitle] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [status, setStatus] = useState<BaseEnum>();

  const [editCareTeamPerson, setEditCareTeamPerson] = useState<
    CareTeamPersonExtended
  >();
  const [editCareTeamMember, setEditCareTeamMember] = useState<
    CareTeamMember
  >();
  const [consents, setConsents] = useState<MemberConsentAnswer[]>([]);
  const [consentRequest, setConsentRequest] = useState<ConsentRequest>();

  const [openEditMemberModal, setOpenEditMemberModal] = useState<boolean>(
    false,
  );
  const [openDeleteMemberModal, setOpenDeleteMemberModal] = useState<boolean>(
    false,
  );
  const [openEditContactModal, setOpenEditContactModal] = useState<boolean>(
    false,
  );
  const [openEditEngagementModal, setOpenEditEngagementModal] = useState<
    boolean
  >(false);
  const [openEditAddressModal, setOpenEditAddressModal] = useState<boolean>(
    false,
  );
  const [openConsentModal, setOpenConsentModal] = useState<boolean>(false);

  const showContactVerified = useMemo(
    () =>
      !!editCareTeamPerson?.careTeamMembers?.find(
        ({ type }) => CareTeamMemberType.PROVIDER === type?.id,
      ),
    [editCareTeamPerson],
  );

  const getInitialData = async () => {
    const statuses = await CacheServices.getCareTeamPersonStatuses();
    setStatuses(statuses);
    const consentRequests = await CacheServices.getCaregiverConsentRequests();
    setConsentRequest(consentRequests.find(({ name }) => name === 'TEXT'));
  };

  const fetchConsents = async () => {
    const consents = await fetchCTPConsentAnswers(+currentParams.id);
    setConsents(consents);
  };

  const getCareTeamPersonData = async () => {
    if (!currentParams.id) return;

    setLoading(true);
    try {
      const ctp = await fetchCareTeamPerson(+currentParams.id);
      setEditCareTeamPerson(ctp);
      await fetchConsents();
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    setFirstName(
      editCareTeamPerson?.firstName || queryParams.get('firstName') || '',
    );
    setLastName(
      editCareTeamPerson?.lastName || queryParams.get('lastName') || '',
    );
    setStatus(editCareTeamPerson?.status);
  }, [editCareTeamPerson]);

  useEffect(() => {
    getCareTeamPersonData();
  }, [currentParams.id]);

  useEffect(() => {
    getInitialData();
  }, []);

  const updatePerson = async (
    id: number,
    params: UpdateCareTeamPersonParams,
  ) => {
    let result = true;
    try {
      await updateCareTeamPerson(id, {
        firstName,
        lastName,
        statusId: status?.id,
        ...params,
      });
      await getCareTeamPersonData();
      setOpenEditContactModal(false);
    } catch (e) {
      result = false;
    }
    return result;
  };

  const getPageTitle = () => {
    const subtitle = (
      <div className={classNames('flex gap spaced', styles.subtitleContainer)}>
        {showBackButton && (
          <Button
            href="blank"
            className={styles.back}
            data-cy="sms-back-button"
            onClick={() => history.goBack()}
          >
            <Icon className="fa fa-arrow-left" color="green" size="sm" />
            &nbsp;{translate('careTeam.backToDashboard')}
          </Button>
        )}
        <span className={styles.upperTitle}>
          {translate('careTeam.detail.titleId', {
            id: currentParams.id,
          })}
        </span>
      </div>
    );
    if (edittingTitle)
      return (
        <div className={styles.titleContainer}>
          {subtitle}
          <div className={classNames('flex gap', styles.title)}>
            <TextInput
              className="grid-span-3"
              data-cy="care-team-detail-first-name"
              onChange={setFirstName}
              placeholder={translate('careTeam.common.firstName')}
              size="small"
              value={firstName}
            />
            <TextInput
              className="grid-span-3"
              data-cy="care-team-detail-last-name"
              onChange={setLastName}
              placeholder={translate('careTeam.common.lastName')}
              size="small"
              value={lastName}
            />
            <IconButton
              onClick={() => {
                if (!firstName) setFirstName(editCareTeamPerson?.firstName);
                if (!lastName) setLastName(editCareTeamPerson?.lastName);
                if (editCareTeamPerson) {
                  updateCareTeamPerson(editCareTeamPerson.id, {
                    firstName: firstName || undefined,
                    lastName: lastName || undefined,
                  });
                }
                setEdittingTitle(!edittingTitle);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </div>
        </div>
      );

    return (
      <div className={styles.titleContainer}>
        {subtitle}
        <div className={classNames('flex', styles.title)}>
          <span>
            {firstName} {lastName}
          </span>

          <IconButton
            onClick={() => setEdittingTitle(!edittingTitle)}
            size="small"
          >
            <EditIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  return (
    <Panel id="care-team-detail-section">
      <Panel.Heading title={getPageTitle()}>
        <Panel.Actions>
          <Select
            className={styles.status}
            disableClearable
            getItemLabel={({ description }) => description}
            items={statuses}
            label={translate('careTeam.common.status')}
            loading={loading || loadingStatus}
            onChange={async (statusId: BaseEnum) => {
              setLoadingStatus(true);
              if (editCareTeamPerson) {
                await updateCareTeamPerson(editCareTeamPerson.id, {
                  statusId: statusId.id,
                });
              }
              setLoadingStatus(false);
              setStatus(status);
            }}
            value={status}
          />
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <div className="grid-wrapper fit">
          <ProfileCareTeamPersonStep
            consents={consents}
            consentRequest={consentRequest}
            onEditAddress={() => setOpenEditAddressModal(true)}
            onEditContact={() => setOpenEditContactModal(true)}
            onEditEngagement={() => setOpenEditEngagementModal(true)}
            onEditTextConsent={() => setOpenConsentModal(true)}
            person={editCareTeamPerson}
            refetchCTP={async () => await fetchConsents()}
            showContactVerified={showContactVerified}
          />
          <br />

          <LinkCareTeamPersonStep
            className="grid-span-12"
            members={
              (editCareTeamPerson as CareTeamPersonExtended)?.careTeamMembers
            }
            onActivate={async (member) => {
              try {
                await activateCareTeamMember(member.id);
                await getCareTeamPersonData();
                setOpenDeleteMemberModal(false);
              } catch (e) {
                showGlobalError(e as string);
              }
            }}
            onAdd={() => {
              setEditCareTeamMember(undefined);
              setOpenEditMemberModal(true);
            }}
            onDelete={(ctm) => {
              setEditCareTeamMember(ctm);
              setOpenDeleteMemberModal(true);
            }}
            onEdit={(ctm) => {
              setEditCareTeamMember(ctm);
              setOpenEditMemberModal(true);
            }}
          />
        </div>
        <br />
        <br />
        <CareTeamMemberEditModal
          editMode="member"
          member={editCareTeamMember}
          onClose={() => setOpenEditMemberModal(false)}
          onSubmit={async (id, params, changeRole) => {
            try {
              if (
                changeRole &&
                editCareTeamMember?.id &&
                editCareTeamPerson?.id
              ) {
                await changeRoleCareTeamMember(
                  editCareTeamMember.id,
                  (params as CreateCareTeamMemberParams).type,
                  {
                    careTeamPersonId: editCareTeamPerson.id,
                    memberId: id,
                    ...params,
                  } as CreateCareTeamMemberParams,
                );
              } else if (editCareTeamMember?.id) {
                await updateCareTeamMember(
                  editCareTeamMember.id,
                  editCareTeamMember.type,
                  params,
                );
              } else if (
                editCareTeamPerson?.id &&
                (params as CreateCareTeamMemberParams)?.type
              ) {
                await createCareTeamMember(
                  (params as CreateCareTeamMemberParams).type,
                  {
                    careTeamPersonId: editCareTeamPerson.id,
                    memberId: id,
                    ...params,
                  } as CreateCareTeamMemberParams,
                );
              }
              await getCareTeamPersonData();
              setOpenEditMemberModal(false);
            } catch (e) {
              showGlobalError(e as string);
            }
          }}
          open={openEditMemberModal}
          person={editCareTeamPerson as CareTeamPersonExtended}
        />
        <CareTeamMemberDeleteModal
          member={editCareTeamMember}
          onClose={() => setOpenDeleteMemberModal(false)}
          onSubmit={async (id, params) => {
            try {
              await invalidateCareTeamMember(id, params);
              await getCareTeamPersonData();
              setOpenDeleteMemberModal(false);
            } catch (e) {
              showGlobalError(e as string);
            }
          }}
          open={openDeleteMemberModal}
        />
        <EditAddressModal
          person={editCareTeamPerson}
          open={openEditAddressModal}
          onClose={() => setOpenEditAddressModal(false)}
          onSubmit={async (id, params) => {
            const result = await updatePerson(id, params);
            if (result) {
              setOpenEditAddressModal(false);
            }
            return result;
          }}
        />

        <EditContactModal
          person={editCareTeamPerson}
          open={openEditContactModal}
          onClose={() => setOpenEditContactModal(false)}
          onSubmit={async (id, params) => {
            const result = await updatePerson(id, params);
            if (result) {
              setOpenEditContactModal(false);
            }
            return result;
          }}
          showContactVerified={showContactVerified}
        />

        <EditEngagementModal
          person={editCareTeamPerson}
          open={openEditEngagementModal}
          onClose={() => setOpenEditEngagementModal(false)}
          onSubmit={async (id, params) => {
            const result = await updatePerson(id, params);
            if (result) {
              setOpenEditEngagementModal(false);
            }
            return result;
          }}
        />
        <ConsentEditModal
          answers={consents}
          open={openConsentModal}
          onClear={async (id: number) => {
            let result = true;
            try {
              if (editCareTeamPerson) {
                await deleteCTPConsentAnswer(editCareTeamPerson.id, id);
                await fetchConsents();
                setOpenConsentModal(false);
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenConsentModal(false)}
          onSubmit={async (param) => {
            let result = true;
            try {
              if (editCareTeamPerson) {
                if (param.id) {
                  await updateCTPConsentAnswer(
                    editCareTeamPerson.id,
                    param.id,
                    param,
                  );
                } else {
                  await createCTPConsentAnswer(editCareTeamPerson.id, param);
                }
                await fetchConsents();
                setOpenConsentModal(false);
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          request={consentRequest}
        />
      </Panel.Body>
    </Panel>
  );
};

export default CareTeamDetail;
