import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';

import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { makeStyles } from '@mui/styles';

import {
  BiometricsType,
  ProgramExtensionStatusReason,
  RPMAdherenceLevel,
} from '@vestahealthcare/common/enums';
import { TaskStatus } from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import {
  ClinicalMember,
  MemberDashboardRPM,
  MemberDashboardTask,
  MemberWarning,
  Patient,
  ProgramExtensionStatus,
  Task,
} from '@vestahealthcare/common/models';
import { Measurement } from '@vestahealthcare/common/models/Measurement';
import { MemberWarningType } from '@vestahealthcare/common/models/member-warnings/MemberWarningType';
import {
  DATE_FORMAT_MIN,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Card,
  Chip,
  Colors,
  Fonts,
  Summary,
} from 'styleguide-v2';

import { MemberWarningIcon } from 'dash/src/components/MemberWarningIcon';
import Session from 'dash/src/services/SessionServices';

type Props = {
  className?: string;
  member?: Patient | ClinicalMember;
  data?: MemberDashboardRPM;
  tasks?: Task[] | MemberDashboardTask[];
  warnings?: MemberWarning[];
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },

    '& > div:not(:last-child)': {
      borderRight: `1px solid ${BackgroundColors.grayLine}`,
    },
  },
  devicesText: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
    margin: '0.5rem 0 0',
    maxWidth: '20rem',
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  subtitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      fontWeight: 400,
      margin: 0,
      textTransform: 'initial',
    },
  },
  summary: {
    width: '24%',
  },
  tableHeader: {
    fontWeight: 500,
    paddingRight: '1rem',
  },
  tableRow: {
    verticalAlign: 'top',
    paddingBottom: '0.5rem',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
});

export const RPMCard = ({
  className,
  data,
  member,
  tasks,
  warnings,
}: Props) => {
  const styles = useStyles();

  const hasRPMTimeWarning = warnings?.find(
    ({ type }) => type.id === MemberWarningType.RPM_TIME,
  );

  const memberId = useMemo(
    () => (member instanceof ClinicalMember ? member.memberId : member?.id),
    [member],
  );
  const openNewTab = useMemo(() => member instanceof ClinicalMember, [member]);
  const shouldShowLink = Session.actingUser.showMemberLink;

  const getReadingsColor = () => {
    if (data?.opportunityLevel === RPMAdherenceLevel.MET)
      return Colors.iconGreen;
    if (data?.opportunityLevel === RPMAdherenceLevel.ON_TRACK)
      return Colors.gold;
    if (data?.opportunityLevel === RPMAdherenceLevel.AT_RISK)
      return Colors.orange;
    if (data?.opportunityLevel === RPMAdherenceLevel.MISSED)
      return Colors.textRed;

    return Colors.mint;
  };

  const getLastReadingColor = () => {
    const daysSinceLastReading = data?.lastReading
      ? moment().diff(moment.unix(data?.lastReading.readAt), 'days')
      : 0;
    if (daysSinceLastReading <= 3) return Colors.iconGreen;
    if (daysSinceLastReading <= 9) return Colors.gold;
    if (daysSinceLastReading <= 29) return Colors.orange;
    return Colors.textRed;
  };

  const getMinutesColor = () => {
    if (!data?.rpmMinutes || data?.rpmMinutes < 20) return Colors.iconGray;
    if (data?.rpmMinutes >= 80) return Colors.orange;
    if (data?.rpmMinutes >= 20) return Colors.iconGreen;

    return Colors.paleGray;
  };

  const renderReadingTooltip = (readings: Measurement[]) => {
    const finalReadings = [] as Measurement[];
    readings.forEach((reading) => {
      const { value, type, readAt } = reading;
      const storedReading = finalReadings?.find(
        ({ readAt: storedReadAt, type }) =>
          type === BiometricsType.BP_SYSTOLIC &&
          moment.unix(readAt).isSame(moment.unix(storedReadAt), 'minute'),
      );
      if (type === BiometricsType.BP_DIASTOLIC && storedReading) {
        // @ts-ignore
        storedReading.value = `${storedReading.value}/${value}`;
      } else {
        finalReadings.push({ ...reading });
      }
    });
    return (
      <table width={500}>
        <thead>
          <th className={styles.tableHeader} style={{ width: 135 }}>
            {translate('memberEvents.issues.reading')}
          </th>
          <th className={styles.tableHeader} style={{ width: 75 }}>
            {translate('memberEvents.issues.value')}
          </th>
          <th className={styles.tableHeader} style={{ width: 30 }}>
            {translate('memberEvents.issues.valid')}
          </th>
          <th className={styles.tableHeader} style={{ width: 30 }}>
            {translate('memberEvents.issues.abnormal')}
          </th>
          <th className={styles.tableHeader} style={{ width: 135 }}>
            {translate('memberEvents.issues.received')}
          </th>
        </thead>
        <tbody>
          {finalReadings.map(
            (
              { abnormal, createdAt, readAt, type, valid, value }: Measurement,
              index: number,
            ) => (
              <tr key={`member-${memberId}-reading-${index}`}>
                <td className={styles.tableRow}>
                  {`${moment
                    .unix(readAt)
                    .format(DATE_FORMAT_SHORT_WITH_TIME)} ${moment
                    .tz(moment.tz.guess())
                    .zoneAbbr()
                    .replace(/[DS]T$/, 'T')}`}
                </td>
                <td className={styles.tableRow}>
                  {value}
                  {BiometricsType.getUnit(type)}
                </td>
                <td className={styles.tableRow}>
                  {translate(`global.${valid ? 'yes' : 'no'}`)}
                </td>
                <td className={styles.tableRow}>
                  {translate(`global.${abnormal ? 'yes' : 'no'}`)}
                </td>
                <td className={styles.tableRow}>
                  {`${moment
                    .unix(createdAt)
                    .format(DATE_FORMAT_SHORT_WITH_TIME)} ${moment
                    .tz(moment.tz.guess())
                    .zoneAbbr()
                    .replace(/[DS]T$/, 'T')}`}
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    );
  };

  const getProgramExtensionStatus = ({
    programExtensionStatus: status,
    programExtensionStatusReasonOther: reasonOther,
    programExtensionStatusReason: reason,
  }: MemberDashboardRPM) =>
    `${status?.description}${
      status?.id === ProgramExtensionStatus.HOLD
        ? ` (${
            reason === ProgramExtensionStatusReason.OTHER && reasonOther
              ? reasonOther
              : reason?.toString()
          })`
        : ''
    }`;

  if (
    !data ||
    !data.currentCycleStart ||
    !data.programExtensionStatus ||
    ![
      ProgramExtensionStatus.ACTIVE,
      ProgramExtensionStatus.DELAYED_ESCALATED,
      ProgramExtensionStatus.DELAYED_TECH_ISSUE,
      ProgramExtensionStatus.DELAYED_UNREACHABLE,
      ProgramExtensionStatus.HOLD,
    ].includes(data.programExtensionStatus?.id)
  )
    return <></>;

  const hasAbnormalReadings = !!data.readingsFromLastDay?.find(
    ({ abnormal }) => abnormal,
  );

  return (
    <>
      <Card className={classNames(className, styles.container)}>
        <div className={styles.subContainer}>
          <div className="flex spaced">
            <span className={styles.title}>
              RPM Adherence&nbsp;&nbsp;
              <Chip
                color={ProgramExtensionStatus.getColorStatus(
                  data.programExtensionStatus,
                )}
                type="contained-2"
              >
                <span className="bold">{`RPM: ${getProgramExtensionStatus(
                  data,
                )?.toLocaleUpperCase()}`}</span>
              </Chip>
              &nbsp;
              {member && !!tasks?.length && (
                <Chip
                  color={TaskStatus.COLOR_MAP.get(tasks[0].status)?.color}
                  onClick={
                    Session.actingUser.showMemberLink
                      ? () =>
                          window.open(
                            `#/patients/${memberId}/tasks?createdDateRange=-1&selectedTab=tab-open`,
                            '_blank',
                            'noreferrer',
                          )
                      : undefined
                  }
                  title={
                    <>
                      {tasks.map((item: Task | MemberDashboardTask) => (
                        <p
                          className="no-margin white"
                          key={`task-${
                            item instanceof Task ? item.id : item.date.unix()
                          }-rpm-tooltip`}
                        >
                          {item instanceof Task
                            ? item.taskDefinition.name
                            : item.definition}{' '}
                          - {item.status.toString()}
                        </p>
                      ))}
                    </>
                  }
                  type="outlined"
                >
                  <span className="bold">
                    {translate('biometrics.rpmTasks', {
                      count: tasks.length,
                    })}
                  </span>
                </Chip>
              )}
            </span>
            {shouldShowLink && (
              <a
                className="flex middle min-gap"
                href={`#/patients/${memberId}/biometrics`}
                style={{ color: Colors.textGreen, fontWeight: 500 }}
                rel="noreferrer"
                target={openNewTab ? '_blank' : undefined}
              >
                {translate('memberProfile.dashboard.goToVitals')}
                <ForwardIcon fontSize="small" />
              </a>
            )}
          </div>
          <h3 className={styles.subtitle}>
            {data.currentCycleStart?.format(DATE_FORMAT_MIN)} -{' '}
            {data.currentCycleEnd?.format(DATE_FORMAT_MIN)} (
            {translate('biometrics.daysRemaining', {
              count:
                (data.currentCycleEnd?.endOf('day')?.diff(moment(), 'days') ||
                  0) + 1,
            })}
            )
          </h3>
          <br />
          <div className="flex spaced">
            <Summary
              body={data.totalReadings}
              className={styles.summary}
              color={getReadingsColor()}
              footer={translate('biometrics.rpmAdherence.remainingReading', {
                count: data.remainingReadings,
              })}
              tag={
                <Chip
                  color={RPMAdherenceLevel.getColor(data.opportunityLevel)}
                  type="contained-2"
                >
                  <span className="bold">
                    {data.opportunityLevel.toString().toLocaleUpperCase()}
                  </span>
                </Chip>
              }
              title={translate('biometrics.rpmAdherence.totalReadings')}
            />
            <Summary
              body={
                data?.lastReading?.readAt
                  ? moment.unix(data.lastReading.readAt).format('M/D/YY')
                  : '-'
              }
              className={styles.summary}
              color={getLastReadingColor()}
              footer={
                data?.lastReading?.readAt
                  ? translate('biometrics.daysAgo', {
                      count: moment().diff(
                        moment.unix(data.lastReading.readAt),
                        'days',
                      ),
                    })
                  : ''
              }
              tag={
                data?.lastReading ? (
                  <Chip
                    color={hasAbnormalReadings ? 'error' : 'secondary'}
                    icon={
                      <MonitorHeartIcon
                        htmlColor={
                          hasAbnormalReadings
                            ? Colors.textRed2
                            : Colors.iconGreen
                        }
                      />
                    }
                    title={
                      data.readingsFromLastDay?.length
                        ? renderReadingTooltip(data.readingsFromLastDay)
                        : undefined
                    }
                    type="contained-2"
                  />
                ) : undefined
              }
              title={translate('biometrics.rpmAdherence.lastReading')}
            />

            <Summary
              body={data.rpmMinutes}
              className={styles.summary}
              color={getMinutesColor()}
              footer={<i>{translate('memberProfile.dashboard.cumulative')}</i>}
              tag={
                hasRPMTimeWarning ? (
                  <Chip
                    color="error"
                    icon={
                      <MemberWarningIcon
                        category={hasRPMTimeWarning.type.category}
                      />
                    }
                    type="contained-2"
                    title={translate(
                      'memberProfile.dashboard.rpmTotalMinutesTooltip',
                    )}
                  />
                ) : undefined
              }
              title={translate('memberProfile.dashboard.rpmTotalMinutes')}
            />
            <Summary
              body={
                <p className={styles.devicesText}>
                  {data.devices?.length ? data.devices.join(', ') : EMPTY}
                </p>
              }
              className={styles.summary}
              color={Colors.paleGray}
              title={translate('biometrics.rpmAdherence.devices')}
            />
          </div>
        </div>
      </Card>
    </>
  );
};
