import classNames from 'classnames';
import { AsYouTypeFormatter } from 'google-libphonenumber';
import React, { Fragment, ReactNode, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import { Enum, PhoneType } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Phone, UserPhone } from '@vestahealthcare/common/models';
import { parse, validate } from '@vestahealthcare/common/utils/phone';

import {
  Button,
  IconButton,
  RadioGroup,
  Select,
  TextInput,
} from 'styleguide-v2';

const useStyles = makeStyles({
  container: {
    height: 'fit-content',
  },
  firstRadio: {
    height: '7rem',
  },
  radio: {
    '&& .MuiFormGroup-root': {
      margin: '1.5rem 0 0 1.5rem',
    },
  },
  firstIcon: {
    '&&': {
      margin: '2.25rem auto auto',
    },
  },
  icon: {
    alignSelf: 'baseline',
    margin: '0.5rem auto auto',
  },
});

export type Props = {
  className?: string;
  disabled?: boolean;
  getItemFooter?: (
    phone: UserPhone,
    idx: number,
  ) => string | ReactNode | undefined;
  getItemNote?: (phone: UserPhone, idx: number) => string | undefined;
  getItemNoteClassName?: (phone: UserPhone, idx: number) => string | undefined;
  getItemReadOnly?: (phones: UserPhone) => boolean;
  getItemReadOnlyPrimary?: (phones: UserPhone) => boolean;
  hideCellType?: boolean;
  hideHomeType?: boolean;
  hideFaxType?: boolean;
  multiple?: boolean;
  onChange: (phones: UserPhone[]) => void;
  phones?: UserPhone[];
  required?: boolean;
  showBusinessType?: boolean;
  submitted?: boolean;
};

interface UserPhoneOptional extends Omit<UserPhone, 'phone'> {
  phone: Partial<Phone>;
}

export const EditPhones = ({
  className,
  disabled,
  getItemFooter,
  getItemNote,
  getItemNoteClassName,
  getItemReadOnly,
  getItemReadOnlyPrimary,
  hideCellType,
  hideHomeType,
  hideFaxType,
  multiple = true,
  onChange,
  phones: paramPhones,
  required,
  showBusinessType,
  submitted,
}: Props) => {
  const styles = useStyles();

  const normalize = (str?: string) => str?.replace(/\D/g, '') ?? '';
  const formatter = new AsYouTypeFormatter('US');
  const formatAsPhoneNumber = (str?: string): string => {
    formatter.clear();
    return [...normalize(str)]
      .slice(0, 10)
      .reduce((_total, digit) => formatter.inputDigit(digit), '');
  };

  const isUserPhone = (p: UserPhoneOptional): p is UserPhone => true;

  const phones: UserPhoneOptional[] = useMemo(
    () =>
      paramPhones?.length
        ? paramPhones?.map((item) => ({
            ...item,
            phone: {
              ...item.phone,
            } as Phone,
          }))
        : [new UserPhone({ primary: true })],
    [paramPhones],
  );

  const handleChange = (phones?: UserPhoneOptional[]) =>
    onChange((phones || []).filter(isUserPhone));

  const getPhoneTypes = () => {
    const types: PhoneType[] = [];
    if (!hideCellType) {
      types.push(PhoneType.CELL);
    }
    if (!hideHomeType) {
      types.push(PhoneType.HOME);
    }
    if (!hideFaxType) {
      types.push(PhoneType.FAX);
    }
    if (showBusinessType) {
      types.push(PhoneType.BUSINESS);
    }
    return types;
  };

  return (
    <div
      className={classNames(className, styles.container, 'grid-wrapper fit')}
    >
      {[...(phones || [])].map((phone, idx) => (
        <Fragment key={`edit-phone-${idx}`}>
          {multiple && (
            <RadioGroup
              className={classNames(
                idx === 0 && styles.firstRadio,
                styles.radio,
                'grid-span-1',
              )}
              disabled={
                disabled ||
                (getItemReadOnlyPrimary &&
                  getItemReadOnlyPrimary(phone as UserPhone))
              }
              items={[{ value: idx + 1, label: '' }]}
              title={
                idx === 0
                  ? translate('components.editPhones.primary')
                  : undefined
              }
              onChange={() => {
                phones?.forEach((item, phonesIdx) => {
                  item.primary = idx === phonesIdx;
                });
                handleChange(phones);
              }}
              value={phone.primary ? idx + 1 : -1}
            />
          )}
          <TextInput
            className={`grid-span-${multiple ? 5 : 6}`}
            disabled={
              disabled ||
              (getItemReadOnly && getItemReadOnly(phone as UserPhone))
            }
            error={
              submitted &&
              (phone.phone?.number
                ? !validate(phone.phone?.number) &&
                  translate('components.editPhones.phoneNumberInvalid')
                : translate('global.missingRequiredFieldMin'))
            }
            label={
              idx === 0 ? translate('components.editPhones.phone') : undefined
            }
            maxLength={14}
            onChange={(val) => {
              if (val) {
                if (!phone.phone) {
                  phone.phone = new Phone({});
                }
                if (val.length > 1) {
                  // parse returns undefined at length 1
                  phone.phone.number =
                    parse(val)?.getNationalNumber()?.toString() || '';
                  phone.phone.number = [...phone.phone.number]
                    .slice(0, 10)
                    .join('');
                } else {
                  phone.phone.number = val;
                }
              } else {
                delete phone.phone?.number;
              }
              handleChange(phones);
            }}
            value={
              phone.phone?.number
                ? formatAsPhoneNumber(phone.phone?.number)
                : undefined
            }
            required={required}
            footer={getItemFooter && getItemFooter(phone as UserPhone, idx)}
            note={getItemNote && getItemNote(phone as UserPhone, idx)}
            noteClassName={
              getItemNoteClassName &&
              getItemNoteClassName(phone as UserPhone, idx)
            }
          />
          <Select
            className={`grid-span-${multiple ? 5 : 6}`}
            disabled={
              disabled ||
              (getItemReadOnly && getItemReadOnly(phone as UserPhone))
            }
            error={
              submitted &&
              !phone.phone?.type &&
              translate('global.missingRequiredFieldMin')
            }
            items={Enum.toSelectable(getPhoneTypes())}
            label={
              idx === 0 ? translate('components.editPhones.type') : undefined
            }
            onChange={(val?: Selectable) => {
              if (val) {
                if (!phone.phone) {
                  phone.phone = new Phone({});
                }
                phone.phone.type = PhoneType.byKey[val.value];
              } else {
                delete phone?.phone?.type;
              }
              handleChange(phones);
            }}
            value={
              phone.phone?.type
                ? Enum.toSelectable([phone.phone?.type])[0]
                : undefined
            }
            required={required}
          />
          {!(getItemReadOnly && getItemReadOnly(phone as UserPhone)) &&
            multiple && (
              <IconButton
                className={classNames(
                  idx === 0 && styles.firstIcon,
                  styles.icon,
                  'grid-span-1',
                )}
                disabled={(phones && phones.length < 2) || disabled}
                size="small"
                onClick={() => {
                  phones?.splice(idx, 1);
                  handleChange(phones);
                }}
              >
                <DeleteOutlineIcon fontSize="large" />
              </IconButton>
            )}
          {getItemReadOnly && getItemReadOnly(phone as UserPhone) && (
            <div className="grid-span-1" />
          )}
        </Fragment>
      ))}
      {!disabled && multiple && (
        <Button
          className="grid-span-12"
          color="tertiary"
          icon={<AddIcon />}
          onClick={() => handleChange([...(phones || []), new UserPhone({})])}
        >
          {translate('components.editPhones.addAnotherNumber')}
        </Button>
      )}
    </div>
  );
};
