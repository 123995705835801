import { Enum } from './Enum';

export class CCMServiceProvider extends Enum {
  translationKey = 'ccmServiceProvider';

  static asArray: CCMServiceProvider[] = [];

  static byKey: { [name: string]: CCMServiceProvider } = {};

  static readonly VESTA = new CCMServiceProvider('VESTA');

  static readonly PCS = new CCMServiceProvider('PCS');

  constructor(public readonly value: string) {
    super();
    CCMServiceProvider.asArray.push(this);
    CCMServiceProvider.byKey[value] = this;
  }
}

export default CCMServiceProvider;
