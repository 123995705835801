import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';

import HelpIcon from '@mui/icons-material/QuestionMark';
import {
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  SxProps,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { Colors } from '../../styles/Colors';
import { ErrorLabel } from '../ErrorLabel';
import { Label } from '../Label';
import { Tooltip } from '../Tooltip';

type Props = {
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  error?: boolean | string;
  footer?: ReactNode;
  icon?: ReactNode;
  label?: string;
  loading?: boolean;
  note?: string | ReactNode;
  noteClassName?: string;
  maxLength?: number;
  onChange?: (value?: string) => void;
  onClick?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  tooltip?: string;
  type?: string;
  value?: string | number;
  size?: 'medium' | 'small' | 'xs';
  sx?: SxProps<Theme>;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  helpIcon: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    left: '0.25rem',
    position: 'relative',
    top: '0.2rem',
  },
  note: {
    color: Colors.iconGray,
    fontStyle: 'italic',
    marginRight: '0.25rem',
  },
  noteContainer: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    marginTop: '.4rem',
  },
  readOnly: {
    margin: 'auto 0',
  },
});

const inputStyles = {
  '& > input': {
    fontSize: `calc(${Fonts.fontSize} * 1.125)`,
    padding: '1rem 1.5rem',
  },
  '& > input[disabled]': {
    background: Colors.lighterGray,
    color: Colors.textBlack,
    cursor: 'not-allowed',
  },
  '& > fieldset > legend > span': {
    display: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    boderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
};

const inputSmallStyles = {
  '&': {
    minHeight: '3.589rem',
    padding: 0,
  },
  '& > input': {
    fontSize: Fonts.fontSize,
    padding: '0.5rem 0.75rem',
  },
};

const inputExtraSmallStyles = {
  '&': {
    minHeight: '2.7rem',
    padding: 0,
  },
  '& > input': {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    padding: '0.6rem 0.75rem',
  },
};

export const TextInput = ({
  className,
  'data-cy': dataCy,
  disabled,
  error,
  footer,
  label,
  icon,
  loading,
  maxLength,
  note,
  noteClassName,
  onChange,
  onClick,
  onFocus,
  placeholder,
  readOnly,
  required,
  tooltip,
  type,
  value,
  size,
  sx,
}: Props) => {
  const styles = useStyles();
  const [currentValue, setValue] = useState(value);
  const classes = classNames(className, styles.container);

  useEffect(() => {
    setValue(value || '');
  }, [value]);

  return (
    <div className={classes} data-cy={dataCy}>
      {label && (
        <Label error={error}>
          {label}
          {required && ' *'}
          {!!tooltip && (
            <Tooltip text={tooltip}>
              <HelpIcon
                className={styles.helpIcon}
                color="action"
                fontSize="small"
              />
            </Tooltip>
          )}
        </Label>
      )}
      {readOnly ? (
        <span className={styles.readOnly}>{value}</span>
      ) : (
        <OutlinedInput
          disabled={disabled || loading}
          error={!!error}
          value={currentValue}
          inputProps={maxLength ? { maxLength } : undefined}
          sx={{
            ...inputStyles,
            ...sx,
            ...(size === 'small' ? inputSmallStyles : {}),
            ...(size === 'xs' ? inputExtraSmallStyles : {}),
          }}
          onChange={(evt) => {
            const inputValue = evt?.target?.value;
            setValue(inputValue);
            if (onChange) {
              onChange(inputValue);
            }
          }}
          onClick={onClick}
          onFocus={onFocus}
          placeholder={placeholder}
          endAdornment={
            loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              icon && <InputAdornment position="start">{icon}</InputAdornment>
            )
          }
          label=""
          type={type}
        />
      )}
      {(note || typeof error === 'string' || (error && required)) && !readOnly && (
        <div
          className={classNames(
            noteClassName,
            'flex spaced',
            styles.noteContainer,
          )}
        >
          <ErrorLabel error={error} required={required} />
          {note && !(typeof error === 'string' || (error && required)) && (
            <div />
          )}
          {typeof note === 'string' && (
            <span className={styles.note}>{note}</span>
          )}
          {note && typeof note !== 'string' && note}
        </div>
      )}
      {footer}
    </div>
  );
};

export default TextInput;
