import { Address } from './Address';
import { CareTeamContactInfo } from './CareTeamContactInfo';
import { CareTeamPersonStatus } from './CareTeamPersonStatus';
import { Employee } from './Employee';
import {
  DaysOfServiceHelper,
  getDaysOfService,
} from './Helpers/DaysOfServiceHelper';
import { LanguagePreference } from './LanguagePreference';
import { make, makeArray } from './Model';
import { UserPhoneVerified } from './UserPhoneVerified';

export class CareTeamPerson implements DaysOfServiceHelper {
  id = 0;

  firstName = '';

  lastName = '';

  fullName = '';

  languages: LanguagePreference[];

  phones: UserPhoneVerified[];

  email = '';

  status?: CareTeamPersonStatus;

  address?: Address;

  verifiedAddress = false;

  contactInfo?: CareTeamContactInfo;

  engagementOwner?: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.address = make(obj.address, Address);
    this.contactInfo = make(obj.contactInfo, CareTeamContactInfo);
    this.engagementOwner = make(obj.engagementOwner, Employee);
    this.languages = makeArray(obj.languages, LanguagePreference);
    this.phones = obj.phones?.map((x: any) => new UserPhoneVerified(x));
    this.status = make(obj.status, CareTeamPersonStatus);
  }

  getDaysOfService() {
    return getDaysOfService(this?.contactInfo?.contactTimes);
  }
}

export default CareTeamPerson;
