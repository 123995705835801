import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { Fonts } from '../../styles/Variables';

type Props = {
  className?: string;
  key?: string;
  reversed?: boolean;
  series: {
    color: string;
    label: string;
  }[];
  title?: string;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    fontSize: Fonts.fontSize,
    gap: '0.25rem',
    flexFlow: 'column',
  },
  legendContainer: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '.75em',
    gap: '0.5rem',
  },
  legendContainerReversed: {
    flexFlow: 'row-reverse',
  },
  legendColor: {
    borderRadius: '2px',
    height: '1.5rem',
    width: '1.5rem',
  },
  legentTitle: {
    fontSize: '0.875em',
    fontWeight: 500,
    lineHeight: '0.875em',
    marginBottom: '0.5rem',
  },
  legentTitleReversed: {
    textAlign: 'end',
  },
});

export const PieChartLegend = ({
  className,
  key = Math.round(Math.random() * Number.MAX_VALUE).toString(),
  reversed,
  series,
  title,
}: Props) => {
  const styles = useStyles();

  return (
    <div className={classNames(styles.container, className)}>
      {title && (
        <span
          className={classNames(
            styles.legentTitle,
            reversed && styles.legentTitleReversed,
          )}
        >
          {title}
        </span>
      )}
      {series.map((item, idx) => (
        <div
          className={classNames(
            styles.legendContainer,
            reversed && styles.legendContainerReversed,
          )}
          key={`legend-${key}-${item.label || idx}`}
        >
          <span
            className={styles.legendColor}
            style={{ backgroundColor: item.color }}
          />
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};
