import React, { useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { EmployeeGroupType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { EmployeeGroup } from '@vestahealthcare/common/models';

import { IconButton, Panel, Tabs } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import CacheServices from 'dash/src/services/CacheServices';
import {
  createEmployeeGroup,
  fetchEmployeeGroups,
  updateEmployeeGroup,
} from 'dash/src/services/EmployeeServices';

import GroupsEditModal from './GroupsEditModal';
import { GroupsTable } from './GroupsTable';

const GROUPS_PAGE_TABS = [
  {
    label: EmployeeGroupType.ASSIGNEE.toString(),
    value: EmployeeGroupType.ASSIGNEE.value,
  },
  {
    label: EmployeeGroupType.WORKLIST.toString(),
    value: EmployeeGroupType.WORKLIST.value,
  },
];

export const GroupsDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [editGroup, setEditGroup] = useState<EmployeeGroup>();
  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>(
    EmployeeGroupType.ASSIGNEE.value,
  );

  const getGroups = async () => {
    setLoading(true);
    try {
      const groups = await fetchEmployeeGroups(true);
      setGroups(groups);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const filteredGroups = useMemo(
    () => groups.filter(({ type }) => type.value === selectedTab),
    [groups, selectedTab],
  );

  const onAddGroup = () => {
    setEditGroup({} as EmployeeGroup);
  };

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <Panel id="groups-section">
      <Panel.Heading title={translate('groups.title')}>
        <Panel.Actions>
          <IconButton
            onClick={onAddGroup}
            tooltip={translate('groups.addGroup')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
        <Panel.Tabs>
          <Tabs
            items={GROUPS_PAGE_TABS}
            onChange={(tab) => {
              setSelectedTab(tab);
            }}
            value={selectedTab}
          />
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <GroupsTable
          groups={filteredGroups}
          onEdit={(g) => {
            setEditGroup(g);
          }}
        />
        <GroupsEditModal
          group={editGroup}
          groups={groups}
          onClose={() => setEditGroup(undefined)}
          onSubmit={async (id, params) => {
            try {
              if (id) {
                await updateEmployeeGroup(id, params);
              } else {
                await createEmployeeGroup(params);
              }
              CacheServices.invalidateEmployeeGroupsCache();
              const groups = await fetchEmployeeGroups(true);
              setGroups(groups);
            } catch (e) {
              showGlobalError(e as string);
            }
            return true;
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default GroupsDashboard;
