import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { MemberRisk } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import {
  DATE_TIME_FORMAT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import { Select } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updatePatientRisk } from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const DetailsComponent = React.memo(({ patient }: { patient: Patient }) => {
  return (
    <>
      <h4 className="grid-span-5" data-cy="member-risk-level-label">
        {translate('personalDetails.riskLevel')}
      </h4>
      <p className="grid-span-6" data-cy="member-risk-level-readonly">
        {patient.riskLevel?.toString() || EMPTY}
      </p>
    </>
  );
});

const UNASSIGNED: Selectable = {
  value: -1,
  label: translate('global.unassigned'),
};

const EDIT_RISK_OPTIONS = [
  UNASSIGNED,
  { label: MemberRisk.HIGH.toString(), value: MemberRisk.HIGH.value },
  { label: MemberRisk.MEDIUM.toString(), value: MemberRisk.MEDIUM.value },
  { label: MemberRisk.LOW.toString(), value: MemberRisk.LOW.value },
];

export const EditRiskLevel = ({ patient, onSubmit }: Props) => {
  const { riskLevel, riskLevelUpdatedAt, riskLevelUpdatedBy } = patient;
  const editable = Session.actingUser.canEditMemberRiskLevel;

  const [currentRiskLevel, setRiskLevel] = useState<Selectable>(
    riskLevel ? Enum.toSelectable([riskLevel])[0] : UNASSIGNED,
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    let result = true;
    setSubmitted(true);
    setButtonLoading(true);

    try {
      const newPatient = await updatePatientRisk(
        patient.id,
        MemberRisk.byKey[currentRiskLevel.value],
      );
      await onSubmit(newPatient);
    } catch (e) {
      result = false;
      showGlobalError(e as string);
    }

    setButtonLoading(false);
    return result;
  };

  const reset = () => {
    setRiskLevel(riskLevel ? Enum.toSelectable([riskLevel])[0] : UNASSIGNED);
    setButtonLoading(false);
    setSubmitted(false);
  };

  useEffect(() => reset(), [patient]);

  return (
    <BaseModal
      details={<DetailsComponent patient={patient} />}
      data-cy="member-risk-level"
      editable={editable}
      onCancel={reset}
      onSubmit={submit}
      loadingSubmit={buttonLoading}
      title={translate('personalDetails.riskLevel')}
    >
      <Select
        data-cy="edit-risk-level-options"
        error={submitted && !currentRiskLevel}
        label={translate('personalDetails.riskLevel')}
        onChange={(newValue: Selectable) => setRiskLevel(newValue)}
        items={EDIT_RISK_OPTIONS}
        value={currentRiskLevel}
        note={
          !!riskLevelUpdatedAt &&
          !!riskLevelUpdatedBy &&
          translate('global.lastUpdatedBy', {
            date: moment.unix(riskLevelUpdatedAt).format(DATE_TIME_FORMAT),
            editor: riskLevelUpdatedBy.fullName,
          })
        }
      />
    </BaseModal>
  );
};

export default EditRiskLevel;
