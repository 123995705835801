import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo, useState } from 'react';

import { Enum, InsuranceQualification } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, Patient } from '@vestahealthcare/common/models';
import { Moment } from '@vestahealthcare/common/moment';
import { DATE_TIME_FORMAT } from '@vestahealthcare/common/utils/constants';

import { Text } from 'styleguide';
import { Button, CollapsableSidebar, Select, TextInput } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  UpdateInsuranceQualificationParams,
  fetchMemberInsuranceQualification,
} from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';

interface Props {
  member: Patient;
  onClose: () => void;
  onSubmit: (params: UpdateInsuranceQualificationParams) => Promise<void>;
  open: boolean;
}

const InsuranceViewQualificationsModal = ({
  member,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const { enableInsurancesProtection } = useFlags();
  const [loading, setLoading] = useState<boolean>();
  const [loadingData, setLoadingData] = useState<boolean>();
  const [submitted, setSubmitted] = useState<boolean>();

  const [insuranceQualification, setInsuranceQualification] = useState<
    Selectable
  >();
  const [
    originalMemberInsuranceQualification,
    setOriginalMemberInsuranceQualification,
  ] = useState<InsuranceQualification>();
  const [originalLeadQualification, setOriginalLeadQualification] = useState<
    string
  >('');
  const [
    insuranceQualificationUpdatedAt,
    setInsuranceQualificationUpdatedAt,
  ] = useState<Moment>();
  const [
    insuranceQualificationUpdatedBy,
    setInsuranceQualificationUpdatedBy,
  ] = useState<Employee>();

  const { actingUser } = Session;

  const canEditQualifications = useMemo(
    () =>
      actingUser.canEditMemberInsuranceQualifications &&
      member.isEditable() &&
      (!enableInsurancesProtection || member.isInsuranceEditable()),
    [actingUser, member.status, enableInsurancesProtection],
  );

  const doSubmit = async () => {
    setSubmitted(true);
    if (
      !insuranceQualification ||
      !InsuranceQualification.byKey[insuranceQualification?.value]
    )
      return;
    setLoading(true);

    try {
      await onSubmit({
        insuranceQualification:
          InsuranceQualification.byKey[insuranceQualification?.value],
      });
      onClose();
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setLoading(false);
    }
  };

  const getMemberInsuraceQualification = async () => {
    setLoadingData(true);
    const miq = await fetchMemberInsuranceQualification(member.id);
    if (miq) {
      setOriginalMemberInsuranceQualification(miq.insuranceQualification);
      setInsuranceQualification(
        Enum.toSelectable([miq.insuranceQualification])[0],
      );
      setOriginalLeadQualification(miq.leadQualification.toString());
      setInsuranceQualificationUpdatedAt(miq.insuranceQualificationUpdatedAt);
      setInsuranceQualificationUpdatedBy(miq.insuranceQualificationUpdatedBy);
    } else {
      setOriginalMemberInsuranceQualification(undefined);
      setInsuranceQualification(undefined);
      setOriginalLeadQualification('');
      setInsuranceQualificationUpdatedAt(undefined);
      setInsuranceQualificationUpdatedBy(undefined);
    }
    setLoadingData(false);
  };

  const hasChanges = () =>
    insuranceQualification?.value !==
    originalMemberInsuranceQualification?.value;

  useEffect(() => {
    if (open) {
      getMemberInsuraceQualification();
    }
  }, [member, open]);

  return (
    <CollapsableSidebar
      title={<h2>{translate('memberInsurances.viewQualifications')}</h2>}
      onClose={onClose}
      open={open}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper gap fit">
          <Select
            className="grid-span-12"
            data-cy="insurance-qualification-input"
            disableClearable
            disabled={!canEditQualifications}
            error={
              submitted &&
              !insuranceQualification &&
              translate('global.missingRequiredFieldMin')
            }
            items={Enum.toSelectable(InsuranceQualification.asArray)}
            label={translate('memberInsurances.insuranceQualifications')}
            loading={loadingData}
            onChange={setInsuranceQualification}
            value={insuranceQualification}
          />
          {!loadingData &&
            !!insuranceQualificationUpdatedAt &&
            !!insuranceQualificationUpdatedBy && (
              <Text className="grid-span-12" small>
                {translate('global.lastUpdatedBy', {
                  date: insuranceQualificationUpdatedAt.format(
                    DATE_TIME_FORMAT,
                  ),
                  editor: insuranceQualificationUpdatedBy.fullName,
                })}
              </Text>
            )}
          <TextInput
            className="grid-span-12"
            data-cy="original-lead-qualification-input"
            disabled
            label={translate('memberInsurances.originalLeadQualification')}
            loading={loadingData}
            onChange={() => {}}
            value={originalLeadQualification}
          />
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="insurance-plan-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="insurance-plan-submit"
          disabled={
            !hasChanges() ||
            !actingUser.canEditMemberInsuranceQualifications ||
            loadingData
          }
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default InsuranceViewQualificationsModal;
