import { Moment } from 'moment';

import { Employee } from './Employee';
import { make, makeUnix } from './Model';
import { UserPhone } from './UserPhone';

export class UserPhoneVerified extends UserPhone {
  verifiedAt?: Moment | null;

  verifiedBy?: Employee | null;

  constructor(obj: any) {
    super(obj);
    this.verifiedAt = makeUnix(obj.verifiedAt);
    this.verifiedBy = make(obj.verifiedBy, Employee);
  }
}

export default UserPhoneVerified;
