import React, { useEffect, useState } from 'react';

import { States } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, Patient } from '@vestahealthcare/common/models';

import { Button, Modal, Text } from 'styleguide';

import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import SessionServices from 'dash/src/services/SessionServices';

const isUserLicensedForMember = (
  user: Employee,
  nlcStates: States[],
  patient?: Patient,
) =>
  !patient ||
  !user.showMemberLicensedModal ||
  (patient.address?.state &&
    (user.licences.includes(patient.address.state) ||
      (user.nlc && nlcStates.includes(patient.address.state))));

export function ClinicalLicensureModal() {
  const { actingUser } = SessionServices;
  const patient = useSelector((state) => state.memberInfoSlice.patient);
  const [nlcStates, setNLCStates] = useState<States[]>([]);
  const [show, setShow] = useState(false);

  const getNLCStates = async () => {
    const states = await CacheServices.getEmployeeNLCStates();
    setNLCStates(states);
  };

  useEffect(() => {
    getNLCStates();
  }, []);

  useEffect(() => {
    setShow(!isUserLicensedForMember(actingUser, nlcStates, patient));
  }, [nlcStates]);

  return (
    <Modal
      id="add-edit-medication-modal"
      show={show}
      modalSize="lg"
      color="warning"
      onHide={() => setShow(false)}
    >
      <Modal.Header
        modalTitle={translate('clinicalLicensure.mismatch')}
        closeButton
      />
      <Modal.Body>
        <Text large>{translate('clinicalLicensure.warning')}</Text>
        <ul style={{ listStyleType: 'circle', listStylePosition: 'inside' }}>
          <Text large style={{ paddingLeft: 40 }}>
            <li>{`${translate('clinicalLicensure.yourLicense')} ${
              actingUser.nlc ? 'NLC, ' : ''
            }${actingUser.licences.map((v) => v.value).join(', ')}`}</li>
          </Text>
          <Text large style={{ paddingLeft: 40 }}>
            <li>
              {`${translate('clinicalLicensure.memberResidence')} ${
                patient?.address?.state?.value
              }`}
            </li>
          </Text>
        </ul>
        <Text large style={{ marginTop: 10 }}>
          {translate('clinicalLicensure.pleaseContact')}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={() => setShow(false)}>
          {translate('global.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
