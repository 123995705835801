import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PendingIcon from '@mui/icons-material/Pending';
import { makeStyles } from '@mui/styles';

import { AppointmentStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  MemberDashboardEncounter,
  SkilledDetail,
  TOCAcuityLevel,
  TOCDetail,
  TOCItem,
  TOCStatus,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Colors,
  Data,
  Select,
  StatusVisit,
  TextArea,
  TextInput,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { UpdateTOCParams } from 'dash/src/services/TOCServices';

type Props = {
  className?: string;
  detail?: TOCDetail | SkilledDetail;
  onChange: (params: Partial<UpdateTOCParams>) => void;
  readonly?: boolean;
  submitted?: boolean;
  type: 'TOC' | 'SKILLED';
};

const useStyles = makeStyles({
  separator: {
    margin: 'auto',
    width: '99%',
  },
  tcmStatusSpan: {
    marginTop: '0.125rem',
  },
});

export const TOCDetailsCard = ({
  className,
  detail,
  onChange,
  readonly,
  submitted,
  type,
}: Props) => {
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);

  const [acuityLevels, setAcuityLevels] = useState<TOCAcuityLevel[]>([]);
  const [medicalRecords, setMedicalRecords] = useState<BaseEnum[]>([]);

  const [acuityLevel, setAcuityLevel] = useState<TOCAcuityLevel>();
  const [acuityRationale, setAcuityRationale] = useState<string>();
  const [handoffRecommendations, setHandoffRecommendations] = useState<
    string
  >();
  const [medicalRecord, setMedicalRecord] = useState<BaseEnum>();

  const detailInfo =
    detail instanceof TOCDetail ? detail?.tocRecord : detail?.skilledRecord;

  const getInitialData = async () => {
    setLoading(true);
    const [a, m] = await Promise.all([
      CacheServices.getAcuityLevels(),
      CacheServices.getTOCMedicalReqStatus(),
    ]);
    setAcuityLevels(a);
    setMedicalRecords(m);
    setLoading(false);
  };

  const getVisitValue = (encounter?: MemberDashboardEncounter) => (
    <StatusVisit
      color={AppointmentStatus.getColor(encounter?.details)}
      date={encounter?.date}
      tooltip={encounter?.details}
    />
  );

  const getVisitSubTitle = (encounter?: MemberDashboardEncounter) =>
    encounter
      ? `${encounter?.type}${
          encounter?.employeeName ? `, with ${encounter.employeeName}` : ''
        }`
      : undefined;

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (detailInfo instanceof TOCItem) {
      setAcuityLevel(detailInfo?.acuityLevel);
      setAcuityRationale(detailInfo?.acuityRationale);
      setHandoffRecommendations(detailInfo?.handoffRecs);
      setMedicalRecord(detailInfo?.medicalReqStatus);
    } else {
      setHandoffRecommendations(detailInfo?.handoffNotes);
    }
  }, [detail]);

  useEffect(() => {
    onChange({
      acuityLevelId: acuityLevel?.id,
      acuityRationale,
      handoffRecs: handoffRecommendations,
      medicalReqStatusId: medicalRecord?.id,
    });
  }, [acuityLevel, acuityRationale, handoffRecommendations, medicalRecord]);

  const npVisit = detailInfo?.npVisit;
  const rnCarePlan = detailInfo?.rnCarePlan;

  const getTCMColor = (eligible?: BaseEnum) => {
    if (eligible?.id === 'ELIGIBLE') return 'secondary';
    if (eligible?.id === 'PENDING' || eligible?.id === 'PENDING_CONTACTED')
      return 'warning';
    if (eligible?.id === 'NOT_ELIGIBLE') return 'error';
    return 'action';
  };

  const getTCMIcon = (eligible?: BaseEnum) => {
    if (eligible?.id === 'ELIGIBLE')
      return <CheckCircleIcon htmlColor={Colors.textGreen} />;
    if (eligible?.id === 'PENDING' || eligible?.id === 'PENDING_CONTACTED')
      return <PendingIcon htmlColor={Colors.textOrange} />;
    if (eligible?.id === 'NOT_ELIGIBLE')
      return <CancelIcon htmlColor={Colors.textRed2} />;
    return <HelpOutlineIcon htmlColor={Colors.textGray} />;
  };

  const getTCMText = (eligible?: BaseEnum) => {
    return eligible?.description || translate('toc.detail.tcmNull');
  };

  return (
    <Card className={className} sx={{ height: 'fit-content' }}>
      <CardHeader
        title={translate(`toc.detail.tocTitle.${type}`)}
        action={
          type === 'TOC' ? (
            <Chip
              color={getTCMColor((detailInfo as TOCItem)?.trueTcmStatus)}
              type="contained-2"
            >
              <div className="flex middle min-gap bold">
                {getTCMIcon((detailInfo as TOCItem)?.trueTcmStatus)}
                <span className={styles.tcmStatusSpan}>
                  {'TCM '}
                  {getTCMText((detailInfo as TOCItem)?.trueTcmStatus)}
                </span>
              </div>
            </Chip>
          ) : (
            <></>
          )
        }
      />
      <CardContent className="grid-wrapper" sx={{ paddingTop: 0 }}>
        {type === 'TOC' && detail instanceof TOCDetail && (
          <>
            <Select
              className="grid-span-6"
              error={submitted && !acuityLevel}
              items={acuityLevels}
              label={translate('toc.detail.acuityLevel')}
              loading={loading}
              onChange={setAcuityLevel}
              readOnly={readonly}
              required
              value={acuityLevel}
            />
            <Select
              className="grid-span-6"
              error={submitted && !medicalRecord}
              items={medicalRecords}
              label={translate('toc.detail.medicalRecords')}
              loading={loading}
              onChange={setMedicalRecord}
              readOnly={readonly}
              required
              value={medicalRecord}
            />
            {detail.tocRecord?.status?.id === TOCStatus.HANDOFF && (
              <TextInput
                className="grid-span-12"
                label={translate('toc.detail.handoffDisposition')}
                onChange={() => {}}
                readOnly
                value={
                  detail.tocRecord?.handoffDisposition?.description || EMPTY
                }
              />
            )}
            <TextArea
              className="grid-span-12"
              error={submitted && !acuityRationale}
              label={translate('toc.detail.acuityRationale')}
              minRows={3}
              onChange={setAcuityRationale}
              readOnly={readonly}
              required
              value={acuityRationale}
            />

            <hr className={classNames(styles.separator, 'grid-span-12')} />
          </>
        )}

        <Data
          className="grid-span-6"
          label={translate('toc.detail.npVisit')}
          value={getVisitValue(npVisit)}
          subvalue={getVisitSubTitle(npVisit)}
          type="secondary"
        />

        <Data
          className="grid-span-6"
          label={translate('toc.detail.rnCarePlan')}
          value={getVisitValue(rnCarePlan)}
          subvalue={getVisitSubTitle(rnCarePlan)}
          type="secondary"
        />

        <TextArea
          className="grid-span-12"
          error={submitted && !handoffRecommendations}
          label={translate(`toc.detail.handoffRecommendations.${type}`)}
          minRows={5}
          onChange={setHandoffRecommendations}
          readOnly={readonly}
          required
          value={handoffRecommendations}
        />
      </CardContent>
    </Card>
  );
};
