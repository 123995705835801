import { translate } from '@vestahealthcare/common/i18n';
import {
  FilterSelectable,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';

import { SelectProgramExtensionData } from 'styleguide-v2';

function excludeOtherValuesFunction<T extends { id: number | string }>(
  items: T[],
  values: T[],
  currenValue?: T,
): T[] {
  return items.filter(
    (item) =>
      !item.id ||
      item.id === currenValue?.id ||
      !values?.find((value) => value?.id === item?.id),
  );
}

export const excludeOtherValues = excludeOtherValuesFunction;

const labeler = (item: SelectProgramExtensionData) =>
  `${item.programExtension?.abbr} ${
    item.programExtensionStatus?.length > 3
      ? `${item.programExtensionStatus?.length} ${translate(
          'global.statusesCount',
          {
            count: item.programExtensionStatus?.length,
          },
        )}`
      : `[${item.programExtensionStatus
          ?.map(({ description }) => description)
          .join(', ')}]`
  } `;

export const dataUtils = {
  programExtensions: {
    getFilterItem: (pes: SelectProgramExtensionData[]) =>
      pes.map(
        (item: SelectProgramExtensionData) =>
          ({
            label: labeler(item),
            value: item,
          } as FilterSelectable<SelectProgramExtensionData>),
      ),
    labeler,
    loader: (
      programs: ProgramExtension[],
      statuses: ProgramExtensionStatus[],
    ) => (stored?: string) => {
      const [pe, peStatusString] = stored?.split(':') || [];
      const pes = peStatusString?.split('|') || [];
      return {
        programExtension: programs.find(({ id }) => `${id}` === pe),
        programExtensionStatus: pes
          ?.map((pesId) => statuses.find(({ id }) => id === pesId))
          ?.filter(Boolean),
      };
    },
    parser: ({
      programExtension: program,
      programExtensionStatus: status,
    }: SelectProgramExtensionData) =>
      `${program?.id}:${status?.map(({ id }) => id)?.join('|')}`,
  },
};
