import { Language, States } from '../enums';
import { SelectableInfo } from '../enums/Enum';
import { Account } from './Account';
import { DashPermission } from './DashPermission';
import { EmployeeGroup } from './EmployeeGroup';
import { EmployeePermissions } from './EmployeePermissions';
import { make } from './Model';

// todo: this list is temporary until we switch our permissions into actual individual permissions
export enum PermissionName {
  ADMIN = 'ADMIN',
  CLINICAL = 'CLINICAL',
  CLINICAL_ADMIN = 'CLINICAL_ADMIN',
}

export class Employee extends EmployeePermissions {
  account: Account;

  createdAt = 0;

  email = '';

  employeeLead?: Employee;

  phiAccess = false;

  firstName = '';

  fullName = '';

  hasPicture = false;

  id = 0;

  incidentReviewRequired = true;

  languages: Language[] = [];

  lead = false;

  licences: States[] = [];

  lastName = '';

  phoneNumber = '';

  timeZone = '';

  updatedAt = 0;

  userName = ''; // TODO: Deprecate userName

  zoomMeetingId = 0;

  dashPermissions?: DashPermission[];

  employeeGroups?: EmployeeGroup[];

  ecwId?: '';

  sfId?: 0;

  nlc = false;

  constructor({ dashPermission, enabled, employeeGroups, role, ...obj }: any) {
    super({ dashPermission, enabled, employeeGroups, role });
    Object.assign(this, obj);
    this.account = make(obj.account, Account);
    this.languages = obj.languages?.map((l: string) => Language.byKey[l]) || [];
    this.licences = obj.licences
      ? obj.licences.map(({ state }: any) => States.byKey[state])
      : [];
    this.employeeLead = make(obj.employeeLead, Employee);
  }

  static sort({ fullName: a }: Employee, { fullName: b }: Employee) {
    return a.localeCompare(b);
  }

  get firstInitialLastName() {
    if (this.firstName) {
      return `${this.firstName.charAt(0)} ${this.lastName}`;
    }
    return this.lastName;
  }

  get initials() {
    return `${this.firstName.substring(0, 1)}${this.lastName.substring(0, 1)}`;
  }

  get firstNameLastInitial() {
    return `${this.firstName} ${this.lastName.substring(0, 1)}.`;
  }

  get homepage() {
    if (this.isExternal) {
      return '/dashboard/ccm';
    }
    if (!this.isAdmin) {
      return '/dashboard/events';
    }
    return '/';
  }

  // Deprecated
  get isUnknown() {
    return !this.dashPermissions?.length;
  }

  allowedInState(state?: States, nlcStates?: States[]) {
    return (
      !state ||
      this.licences.includes(state) ||
      (this.nlc && nlcStates?.includes(state))
    );
  }

  static toSelectable(employee: Employee[]): SelectableInfo<Employee>[] {
    return employee.map((e) => ({
      value: e.id,
      label: e.fullName,
      info: e,
    }));
  }
}

export default Employee;
