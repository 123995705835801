import moment, { Moment } from 'moment';
// @ts-ignore
import qs from 'qs';

import { Selectable } from '../enums/Enum';
import { translate } from '../i18n';
import { BaseEnum, FilterItem, FilterSelectable } from '../models';
import { DATE_FORMAT_SHORT, SERVER_DATE_TIME_FORMAT_ZONED } from './constants';
import { isBoolean, isDate, isSelectable, isSelectableArray } from './type';

const getFilterValueFlatted = (
  item?: FilterSelectable<any> | FilterSelectable<any>[],
  parser?: (item: any) => string,
) => {
  if (!item) return undefined;
  if (!(item instanceof Array) && item.value instanceof moment)
    return (item.value as Moment).isValid()
      ? (item.value as Moment).format(SERVER_DATE_TIME_FORMAT_ZONED)
      : undefined;
  if (item instanceof Array) {
    return item.map((subitem) =>
      parser ? parser(subitem.value) : subitem.value,
    );
  }

  return parser ? parser(item.value) : item.value;
};

const getFilterItemValue = (
  value: any,
  valueLabel?: (value: any) => string,
  loader?: (value: string) => any,
): FilterSelectable<any> | FilterSelectable<any>[] | undefined => {
  if (value === undefined || value === '') return undefined;
  if (valueLabel || loader) {
    const currentValue = loader ? loader(value) : value;
    return {
      value: currentValue,
      label: valueLabel ? valueLabel(currentValue) : currentValue,
    };
  }
  if (typeof value === 'number') {
    return {
      label: String(value),
      value,
    };
  }
  if (typeof value === 'string') {
    if (isBoolean(value)) {
      const bool = value === 'true';
      return {
        value: bool,
        label: translate(`global.${bool ? 'yes' : 'no'}`),
      };
    }
    if (isDate(value)) {
      const date = moment(value, SERVER_DATE_TIME_FORMAT_ZONED);
      return {
        label: date.format(DATE_FORMAT_SHORT),
        value: date,
      };
    }
    return {
      label: value,
      value,
    };
  }
  if (value instanceof Array && typeof value[0] === 'string') {
    return value.map((item) => ({
      label: item,
      value: item,
    }));
  }
  if (value instanceof Date) {
    return {
      label: moment(value.getTime()).format(DATE_FORMAT_SHORT),
      value: moment(value.getTime()),
    };
  }
  if (isSelectable(value) || isSelectableArray(value)) {
    return value;
  }
  if (value instanceof Array && value[0] instanceof BaseEnum) {
    return value.map(({ id, description }) => ({
      value: id,
      label: description,
    }));
  }
  if (value instanceof BaseEnum) {
    return {
      value: value.id,
      label: value.description,
    };
  }
  if (typeof value === 'boolean') {
    return {
      value,
      label: translate(`global.${value ? 'yes' : 'no'}`),
    };
  }
  return undefined;
};

export const getFilterItem = (
  label: string,
  value: any,
  f?: (param: any) => FilterSelectable<any> | FilterSelectable<any>[],
): FilterItem<any> | undefined => {
  if (f)
    return {
      label,
      value: f(value),
    };
  const currentValue = getFilterItemValue(value);
  if (currentValue === undefined) return undefined;

  return {
    label,
    value: currentValue,
  };
};

export const getFilterValue = (
  item?: FilterSelectable<any> | FilterSelectable<any>[],
  parser?: (item: any) => string,
) => {
  if (!item) return undefined;
  if (item instanceof Array) {
    return item.map((i) => (parser ? parser(i.value) : i.value));
  }
  if (parser) {
    return parser(item);
  }

  return item.value;
};

export const getServerFilters = (
  filters: { [x: string]: FilterItem<any> },
  parser?: { [x: string]: (item: any) => string },
) =>
  Object.entries(filters)
    .filter(([, value]) => !!value)
    .reduce((acc, [key, value]) => {
      acc[key] = getFilterValue(value.value, parser && parser[key]);
      return acc;
    }, {} as { [x: string]: any });

const getServerFiltersFlatted = (
  filters: { [x: string]: FilterItem<any> },
  parser?: { [x: string]: (item: any) => string },
) =>
  Object.entries(filters)
    .filter(([, value]) => !!value)
    .reduce((acc, [key, value]) => {
      acc[key] = getFilterValueFlatted(value.value, parser && parser[key]);
      return acc;
    }, {} as { [x: string]: any });

export const saveFilters = (
  filters: { [x: string]: FilterItem<any> },
  parser?: { [x: string]: (item: any) => string },
) =>
  qs.stringify(getServerFiltersFlatted(filters, parser), {
    arrayFormat: 'repeat',
  });

export const saveFiltersToStorage = (
  key: string,
  filters: { [x: string]: FilterItem<any> },
  parser?: { [x: string]: (item: any) => string },
) => {
  localStorage.setItem(key, saveFilters(filters, parser));
};

export type LoadFilterOption = {
  data?: (Selectable | BaseEnum)[];
  default?: any;
  hidden?: boolean;
  label?: string;
  loader?: (value: string) => any;
  multiple?: boolean;
  valueLabel?: (value: any) => string;
};

export const loadFilters = (
  filtersString: string,
  filterData: {
    [x: string]: LoadFilterOption;
  },
  query?: URLSearchParams,
) => {
  const mappedFilters: { [x: string]: any } = qs.parse(filtersString) || {};
  return Object.entries(filterData || {}).reduce((acc, [key, data]) => {
    const storedValue = mappedFilters[key];
    const queryValue = data?.multiple ? query?.getAll(key) : query?.get(key);
    const value = queryValue?.length ? queryValue : storedValue;

    if ((!value || !value?.length) && data.default === undefined) return acc;

    let currentData = [] as Selectable[];
    if (data?.data?.length) {
      if (data.data[0] instanceof BaseEnum) {
        currentData = (data.data as BaseEnum[]).map(({ id, description }) => ({
          value: id,
          label: description,
        }));
      } else if (isSelectableArray(data.data)) {
        currentData = data.data as Selectable[];
      }
    }
    const multiple = data?.multiple;
    let currentValue: FilterSelectable<any> | FilterSelectable<any>[];

    if (data?.loader && multiple) {
      currentValue = (value instanceof Array
        ? value
        : [value]
      )?.map((valueString: string) =>
        getFilterItemValue(
          valueString || (data?.multiple ? undefined : data.default),
          data?.valueLabel,
          data?.loader,
        ),
      ) as FilterSelectable<any>[];
    } else {
      currentValue = getFilterItemValue(
        value || (data?.multiple ? undefined : data.default),
        data?.valueLabel,
        data?.loader,
      ) as FilterSelectable<any> | FilterSelectable<any>[];
    }

    if (data?.data) {
      if (multiple) {
        const valueWithDefault = value || data.default?.map(String);
        if (valueWithDefault instanceof Array) {
          currentValue = currentData.filter((item) => {
            if (item instanceof BaseEnum) {
              return valueWithDefault.includes(String(item.id));
            }
            return valueWithDefault.includes(String(item.value));
          });
        } else {
          currentValue = currentData.filter((item) => {
            if (item instanceof BaseEnum) {
              return String(item.id) === valueWithDefault;
            }
            return String(item.value) === valueWithDefault;
          });
        }
      } else {
        currentValue =
          currentData.find((item) => {
            if (item instanceof BaseEnum) {
              return String(item.id) === value;
            }
            return String(item.value) === value;
          }) || currentValue;
      }
    }
    if (currentValue) {
      acc[key] = {
        hidden: !!data.hidden,
        label: data?.label || '',
        value: currentValue,
      };
    }
    return acc;
  }, {} as { [x: string]: FilterItem<any> });
};

export const loadFiltersFromStorage = (
  key: string,
  filterData: {
    [x: string]: LoadFilterOption;
  },
  query?: URLSearchParams,
) => {
  const filtersString = localStorage.getItem(key) || '';
  return loadFilters(filtersString, filterData, query);
};

export const getFiltersEnabled = (
  filters?: { [x: string]: FilterItem<any> & { readonly?: boolean } },
  filterData?: {
    [x: string]: (Selectable | BaseEnum)[];
  },
  disabledKeys?: string[],
) => {
  const result = { ...filters };
  disabledKeys?.forEach((key) => delete result[key]);
  if (filterData) {
    Object.keys(result).forEach((key) => {
      const itemValues = filterData[key]?.map((item: Selectable | BaseEnum) =>
        item instanceof BaseEnum ? item.id : item.value,
      );
      if (itemValues?.length && result[key]?.value) {
        if (result[key].value instanceof Array) {
          const values = result[key].value as FilterSelectable<any>[];
          const newItems = values.filter(({ value }) =>
            itemValues.includes(value as string | number),
          );
          if (newItems?.length) {
            result[key].value = newItems;
          } else {
            delete result[key];
          }
        } else {
          const value = (result[key].value as FilterSelectable<any>)?.value;
          if (!itemValues.find((item) => item === value)) {
            delete result[key];
          }
        }
      }
    });
  }
  return result;
};
