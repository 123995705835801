/* eslint-disable no-underscore-dangle */
import moment from 'moment';

import { EmployeeRole } from '../enums';
import { DashPermission } from './DashPermission';
import { EmployeeGroup } from './EmployeeGroup';
import { UpdateInfo } from './Helpers/UpdateInfo';
import { make, makeArray } from './Model';

// todo: this list is temporary until we switch our permissions into actual individual permissions
export enum PermissionName {
  ADMIN = 'ADMIN',
  CLINICAL = 'CLINICAL',
  CLINICAL_ADMIN = 'CLINICAL_ADMIN',
}

export class EmployeePermissions {
  __activeGroups?: EmployeeGroup[];

  id: number;

  dashPermissions?: DashPermission[];

  enabled = false;

  employeeGroups?: EmployeeGroup[];

  role: EmployeeRole;

  constructor(obj: any) {
    // todo: this will come as array from backend
    this.dashPermissions = obj.dashPermission
      ? [make(obj.dashPermission, DashPermission)]
      : [];
    this.employeeGroups = makeArray(obj.employeeGroups, EmployeeGroup);
    this.enabled = obj.enabled;
    this.id = obj.id;
    this.role = EmployeeRole.byKey[obj.role];

    this.__activeGroups = this.employeeGroups?.filter(({ active }) => active);
  }

  /** UTILS */
  get activeGroups() {
    return this.__activeGroups;
  }

  can(...names: PermissionName[]) {
    return !!this.dashPermissions?.find((permission) =>
      names.some((permName) => permName === permission.name),
    );
  }

  /** CHECK PERMISSION */
  get isClinical() {
    return this.can(PermissionName.CLINICAL, PermissionName.CLINICAL_ADMIN);
  }

  get isAdmin() {
    return this.can(PermissionName.ADMIN, PermissionName.CLINICAL_ADMIN);
  }

  /** CHECK ROLES */

  get isCEC() {
    return this.role === EmployeeRole.CEC;
  }

  get isExternal() {
    return this.role === EmployeeRole.EXTERNAL;
  }

  get isNP() {
    return this.role === EmployeeRole.NP;
  }

  get isPodManager() {
    return this.role === EmployeeRole.MCD;
  }

  get isRN() {
    return this.role === EmployeeRole.RN;
  }

  get isDialer() {
    return this.isExternal || this.isCEC;
  }

  /** CHECK GROUPS */

  get isInAdmin() {
    return !!this.activeGroups?.find(({ id }) => id === EmployeeGroup.ADMIN);
  }

  get isInBilling() {
    return !!this.activeGroups?.find(({ id }) => id === EmployeeGroup.BILLING);
  }

  get isInBizOps() {
    return !!this.activeGroups?.find(({ id }) => id === EmployeeGroup.BIZ_OPS);
  }

  get isInCareCoordinator() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.CLINICAL_ASSISTANTS,
    );
  }

  get isInClinicalLeadership() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.CLINICAL_LEADERSHIP,
    );
  }

  get isInEligibility() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.ELIGIBILLITY,
    );
  }

  get isInEligibilityManagement() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.ELIGIBILLITY_MANAGEMENT,
    );
  }

  get isInEngagement() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.ENGAGEMENT,
    );
  }

  get isInEnrollment() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.ENROLLMENT,
    );
  }

  get isInHealthCoaches() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.HEALTH_COACH,
    );
  }

  get isInIncidentsManagement() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.INCIDENTS_MANAGEMENT,
    );
  }

  get isInLicensureAdministration() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.LICENSURE_ADMINISTRATION,
    );
  }

  get isInNPs() {
    return !!this.activeGroups?.find((group) => group.isNPOwner());
  }

  get isInPODs() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.POD_ADMINISTRATOR,
    );
  }

  get isInRNs() {
    return !!this.activeGroups?.find((group) => group.isRNOwner());
  }

  get isInRosterIngestionTeam() {
    return !!this.activeGroups?.find(
      ({ id }) => id === EmployeeGroup.ROSTER_INGESTION,
    );
  }

  get isInTOC() {
    return !!this.activeGroups?.find(({ id }) => id === EmployeeGroup.TOC);
  }

  /** DASH ACTIONS */
  /** Pages */
  get canViewPageBulkEdit() {
    return this.isAdmin;
  }

  get canViewPageCampaignManager() {
    return this.isAdmin;
  }

  get canViewPageEligibilityJobs() {
    return this.isInBizOps || this.isAdmin;
  }

  get canViewPageEventInterventions() {
    return this.isInClinicalLeadership;
  }

  get canViewPageIncidents() {
    return this.isInIncidentsManagement;
  }

  get canViewPageTaskTemplates() {
    return this.isInAdmin;
  }

  get canViewPagePOD() {
    return this.isInPODs;
  }

  /** Bulk Edit */
  get canEditBulkEditStatus() {
    return this.isInEnrollment || this.isInBizOps;
  }

  get canEditBulkEditStatusAll() {
    return this.isInBizOps;
  }

  /** Clinical dashboard */
  get canExportClinicalDashboard() {
    return !this.isExternal;
  }

  /** Employees */
  get canEditEmployeeAllFields() {
    return this.role === EmployeeRole.ADMIN;
  }

  get canEditEmployeeSomeFields() {
    return this.isInLicensureAdministration || this.isPodManager;
  }

  catImpersonateUser(e: EmployeePermissions) {
    return (
      this.role === EmployeeRole.ADMIN &&
      this.isAdmin &&
      !e.isAdmin &&
      e.enabled
    );
  }

  /** Events */

  get canEventForceClose() {
    return this.isInEnrollment;
  }

  get canEventInvalidate() {
    return this.isInAdmin || this.isInClinicalLeadership;
  }

  get canEditEventClosedIssue() {
    return this.isAdmin;
  }

  get canEditEventClosedResolution() {
    return this.isAdmin;
  }

  get canEditEventClosedNotes() {
    return this.isAdmin;
  }

  canEditEventNote(note: UpdateInfo) {
    return (
      this.isAdmin ||
      (this.id === note?.createdBy?.id &&
        note?.createdAt?.isAfter(moment().subtract(24, 'hours')))
    );
  }

  get canViewEventIncident() {
    return this.isInIncidentsManagement;
  }

  /** Insurance Plans */
  get canEditInsuranceECW() {
    return this.isInEligibilityManagement;
  }

  /** Members */
  get canExportMembers() {
    return (
      this.isInAdmin ||
      this.isInBizOps ||
      this.isInClinicalLeadership ||
      this.isInEligibility
    );
  }

  /** Member Profile */
  get canAddMemberCareTrackCarePlan() {
    return this.isInEnrollment;
  }

  get canEditMemberCareTrackRiskLevel() {
    return this.isInEnrollment;
  }

  get canEditMemberInsurances() {
    return this.isInBilling || this.isInEligibility;
  }

  get canEditMemberInsuranceQualifications() {
    return this.isInEligibilityManagement;
  }

  get canEditMemberPHIP() {
    return this.isInClinicalLeadership || this.isInRNs || this.isInNPs;
  }

  get canEditMemberRiskLevel() {
    return this.isClinical;
  }

  get canEditMemberStatus() {
    return this.isInBizOps || this.isInEnrollment;
  }

  get canEditMemberStatusAll() {
    return this.isInBizOps;
  }

  get canEditMemberTestFlag() {
    return this.can(PermissionName.ADMIN);
  }

  get canEditMemberVestaTeam() {
    return this.isInBizOps || this.isInClinicalLeadership;
  }

  get canEditMemberVirtualVisitCompleted() {
    return this.isAdmin;
  }

  get canSetMemberStatusInvalid() {
    return this.isInEligibilityManagement;
  }

  get showMemberLicensedModal() {
    return this.isInNPs || this.isInRNs;
  }

  get showMemberLink() {
    return !this.isExternal;
  }

  get canRunMemberInsurancesEligibility() {
    return this.isInBilling || this.isInEligibility || this.isInEnrollment;
  }

  get showMemberWarningsModal() {
    return !this.isExternal;
  }

  /** Tasks */

  get canEditTaskClosed() {
    return this.isAdmin;
  }

  /** OTHER */

  get canAddCareTeamRoster() {
    return this.isInRosterIngestionTeam;
  }

  get canEditFacilities() {
    return this.isInEligibilityManagement;
  }

  get canEditTOC() {
    return this.isInTOC;
  }
}

export default EmployeePermissions;
