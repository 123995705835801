import { LS_DASHBOARD_CCM_FILTERS } from '@vestahealthcare/common/utils/constants';

import { getStoredFilters } from 'dash/src/utils/filterUtils';

export const getFilters = (query: URLSearchParams) => {
  const clearFilters = query.get('clearFilters');
  const filters = clearFilters
    ? {}
    : getStoredFilters(LS_DASHBOARD_CCM_FILTERS);

  return {
    sort: query.get('sort') || filters.sort,
  };
};
