import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';

import { MemberDashboardActionStatus } from '@vestahealthcare/common/enums';
import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  CCMMember,
  MemberWarningDetail,
  Patient,
  TOCStatus,
} from '@vestahealthcare/common/models';
import { CCMMemberDetailHeader } from '@vestahealthcare/common/models/CCMMemberDetailHeader';
import EventStatus from '@vestahealthcare/common/models/EventStatus';
import MemberWarningOpenItemsActions from '@vestahealthcare/common/models/member-warnings/MemberWarningOpenItemsActions';
import MemberWarningType from '@vestahealthcare/common/models/member-warnings/MemberWarningType';
import {
  DATE_FORMAT_MIN,
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
  NO_DAY_NO_TIME_DATE_FORMAT_DO,
  NO_TIME_DATE_FORMAT_DO,
} from '@vestahealthcare/common/utils/constants';

import {
  CollapsableSidebar,
  Colors,
  Fonts,
  Spinner,
  StatusIndicator,
  Tooltip,
} from 'styleguide-v2';

import { MemberWarningIcon } from 'dash/src/components/MemberWarningIcon';
import { onWarningsDetailUpdated } from 'dash/src/redux/slices/MemberInfoSlice';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import { fetchMemberWarningDetails } from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';

import { MemberWarningsCard } from './MemberWarningsCard';

type Props = {
  open: boolean;
  onClose: () => void;
  patient: Patient | CCMMemberDetailHeader;
};

const NA = translate('global.na');

const useStyles = makeStyles({
  modal: {
    zIndex: '1400!important',
  },
  tableLabel: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  tableSublabel: {
    fontSize: `calc(${Fonts.fontSize} * 0.625)`,
    lineHeight: `calc(${Fonts.fontSize} * 0.875)`,
    color: Colors.darkGray,
  },
  warningIcon: {
    marginTop: '2px',
  },
});

export const MemberWarningsModal = ({ open, onClose, patient }: Props) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const warnings =
    patient instanceof Patient
      ? useSelector((state) => state.memberInfoSlice.warningsDetail)
      : undefined;
  const [warningsDetail, setWarningDetails] = useState<MemberWarningDetail[]>();

  const [eventStatuses, setEventStatuses] = useState<BaseEnum[]>();

  const getEventStatuses = async () => {
    const statuses = await CacheServices.getEventStatuses();
    setEventStatuses(statuses);
  };

  const patientId = useMemo(
    () => (patient instanceof CCMMember ? patient.memberId : patient.id),
    [patient],
  );

  const getMemberWarnings = async () => {
    setLoading(true);
    const warnings = await fetchMemberWarningDetails(patientId);
    if (patient instanceof Patient) {
      dispatch(onWarningsDetailUpdated(warnings));
    } else {
      setWarningDetails(warnings);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!eventStatuses) getEventStatuses();
    if (open && !warnings) {
      getMemberWarnings();
    }
  }, [open, warnings]);

  const getSubtitle = (detail: MemberWarningDetail) => {
    if (
      MemberWarningDetail.isRPMMissed(detail) ||
      MemberWarningDetail.isRPMMissed(detail)
    ) {
      return (
        <>
          <span className="semi-bold">
            {translate('memberWarnings.modal.currentCycle')}:
          </span>
          &nbsp;
          <span>
            {detail.currentCycleStart?.format(DATE_FORMAT_MIN)}
            {' - '}
            {detail.currentCycleEnd?.format(DATE_FORMAT_MIN)} (
            {translate('biometrics.daysRemaining', {
              count: (detail.currentCycleEnd?.diff(moment(), 'days') || 0) + 1,
            })}
            )
          </span>
        </>
      );
    }
    if (MemberWarningDetail.isTOC(detail)) {
      return (
        <div className="flex min-gap baseline">
          <span className="semi-bold">
            {translate('memberWarnings.modal.status')}:
          </span>
          <StatusIndicator
            color={TOCStatus.getColor(detail.status)}
            status={detail.status.description}
          />
        </div>
      );
    }
    return undefined;
  };

  const getData = (detail: MemberWarningDetail) => {
    if (MemberWarningDetail.isInitialCarePlan(detail)) {
      return [
        {
          label: translate('memberWarnings.modal.dateOnboarded'),
          value:
            detail.lastOnboardingDate?.format(NO_TIME_DATE_FORMAT_DO) || NA,
          subvalue: detail.lastOnboardingEmployee
            ? `With ${detail.lastOnboardingEmployee}`
            : '',
        },
      ];
    }
    if (MemberWarningDetail.isCarePlanUpdate(detail)) {
      return [
        {
          label: translate('memberWarnings.modal.lastCarePlan'),
          value: detail.lastCarePlanDate?.format(NO_TIME_DATE_FORMAT_DO) || NA,
          subvalue: detail.lastCarePlanEmployee
            ? `With ${detail.lastCarePlanEmployee}`
            : '',
        },
        {
          label: translate('memberWarnings.modal.nextCarePlan'),
          value: NA,
          subvalue: detail.nextCarePlanDue
            ? `Due: ${detail.nextCarePlanDue.format(
                NO_DAY_NO_TIME_DATE_FORMAT_DO,
              )}`
            : '',
        },
      ];
    }
    if (MemberWarningDetail.isNPBiannual(detail)) {
      return [
        {
          label: translate('memberWarnings.modal.lastAppointment'),
          value: detail.lastBiannualDate?.format(NO_TIME_DATE_FORMAT_DO) || NA,
          subvalue: detail.lastBiannualDate
            ? [detail.lastBiannualType, detail.lastBiannualEmployee]
                .filter(Boolean)
                .join(', ')
            : '',
        },
        {
          label: translate('memberWarnings.modal.nextAppointment'),
          value: detail.nextBiannualDate?.format(NO_TIME_DATE_FORMAT_DO) || NA,
          subvalue: detail.nextBiannualDate
            ? [detail.nextBiannualType, detail.nextBiannualEmployee]
                .filter(Boolean)
                .join(', ')
            : `Due: ${
                detail.nextBiannualDue?.format(NO_DAY_NO_TIME_DATE_FORMAT_DO) ||
                NA
              }`,
        },
      ];
    }
    if (MemberWarningDetail.isRPMRisk(detail)) {
      return [
        {
          label: translate('memberWarnings.modal.totalReadings'),
          value: String(detail.totalReadings),
          subvalue: `${detail.remainingReadings} readings remaining`,
        },
        {
          label: translate('memberWarnings.modal.lastReading'),
          value: detail.lastReadingReadAt
            ? detail.lastReadingReadAt?.format(NO_TIME_DATE_FORMAT_DO)
            : NA,
          subvalue: detail.lastReadingReadAt
            ? translate('biometrics.daysAgo', {
                count: moment()
                  .endOf('day')
                  .diff(detail.lastReadingReadAt, 'days'),
              })
            : '',
        },
      ];
    }
    if (MemberWarningDetail.isRPMMissed(detail)) {
      return [
        {
          label: translate('memberWarnings.modal.totalReadings'),
          value: String(detail.totalReadings),
          subvalue: `${detail.remainingReadings} readings remaining`,
        },
        {
          label: translate('memberWarnings.modal.historicalAdherence'),
          value: `${detail.adherenceTrend < 0 ? '' : '+'}${
            detail.adherenceTrend
          }%`,
          subvalue: `${detail.completedCycles} / ${detail.totalCycles} cycles`,
        },
      ];
    }

    if (MemberWarningDetail.isTime(detail)) {
      const isRPM = detail.type.id === MemberWarningType.RPM_TIME;
      return [
        {
          label: translate(`memberWarnings.modal.time${isRPM ? 'RPM' : 'CCM'}`),
          value: String(detail.minutes),
          subvalue: translate(
            `memberWarnings.modal.timeNote${isRPM ? 'RPM' : 'CCM'}`,
          ),
          subvalueClassName: 'grey italic font-normal',
        },
      ];
    }

    if (MemberWarningDetail.isTOC(detail)) {
      return [
        {
          label: translate('memberWarnings.modal.admitDate'),
          value: detail.admitDate?.format(NO_TIME_DATE_FORMAT_DO) || EMPTY,
          subvalue: detail.admitDate
            ? translate('common.daysAgo', {
                count: moment().diff(detail.admitDate, 'days'),
              })
            : '',
        },
        {
          label: translate('memberWarnings.modal.dischargeDate'),
          value: detail.dischargeDate?.format(NO_TIME_DATE_FORMAT_DO) || EMPTY,
          subvalue: detail.dischargeDate
            ? translate('common.daysAgo', {
                count: moment().diff(detail.dischargeDate, 'days'),
              })
            : '',
        },
      ];
    }

    return undefined;
  };

  const getTableHeader = (type: MemberWarningType) => {
    if (type.id === MemberWarningType.OPEN_EVENTS) return 'Event';
    if (type.id === MemberWarningType.OPEN_ACTIONS) return 'Action';
    if (type.id === MemberWarningType.OPEN_TASKS) return 'Task';
    return 'Item';
  };

  const getStatusColor = (type: MemberWarningType, status: string) => {
    if (type.id === MemberWarningType.OPEN_EVENTS)
      return EventStatus.getColor(status);
    if (type.id === MemberWarningType.OPEN_ACTIONS)
      return MemberDashboardActionStatus.getColorByString(status);
    if (type.id === MemberWarningType.OPEN_TASKS)
      return TaskStatus.getColor(status);
    return 'default';
  };

  const getStatusLabel = (type: MemberWarningType, status: string) => {
    if (type.id === MemberWarningType.OPEN_EVENTS)
      return (
        eventStatuses?.find(({ id }) => id === status)?.description || EMPTY
      );
    if (type.id === MemberWarningType.OPEN_TASKS)
      return TaskStatus.byKey[status].toString();
    return status;
  };

  const getTable = (detail: MemberWarningDetail) => {
    if (detail instanceof MemberWarningOpenItemsActions) {
      return {
        header: getTableHeader(detail.type),
        data: detail.items.map(
          (
            {
              type,
              createdAt,
              completedAt,
              date,
              dateLabel,
              dueDate,
              startedAt,
              status,
              assignee,
            },
            index,
          ) => [
            <Fragment key={`warning-table-row-${detail.type.id}-${index}-0`}>
              <p className={classNames('no-margin', styles.tableLabel)}>
                {type}
              </p>
              <Tooltip
                text={
                  <div key={`action-${createdAt}`} style={{ width: '150px' }}>
                    <p className="no-margin white flex spaced">
                      <span>{translate('common.created')}:</span>
                      <span>{createdAt?.format(DATE_FORMAT_SHORT)}</span>
                    </p>

                    {![
                      MemberDashboardActionStatus.NOT_STARTED.value,
                      MemberDashboardActionStatus.CANCELLED.value,
                    ].includes(status) && (
                      <p className="no-margin white flex spaced">
                        <span>{translate('common.started')}:</span>
                        <span>
                          {startedAt?.format(DATE_FORMAT_SHORT) ||
                            translate('global.unknown')}
                        </span>
                      </p>
                    )}

                    <p className="no-margin white flex spaced">
                      <span>{translate('common.due')}:</span>
                      <span>
                        {dueDate?.format(DATE_FORMAT_SHORT) ||
                          translate('global.unknown')}
                      </span>
                    </p>

                    {MemberDashboardActionStatus.COMPLETED.value === status && (
                      <p className="no-margin white flex spaced">
                        <span>{translate('common.completed')}:</span>
                        <span>
                          {completedAt?.format(DATE_FORMAT_SHORT) ||
                            translate('global.unknown')}
                        </span>
                      </p>
                    )}
                  </div>
                }
              >
                <p className={classNames('no-margin', styles.tableSublabel)}>
                  {`${dateLabel}: ${
                    date?.format(DATE_FORMAT_SHORT) ||
                    translate('global.unknown')
                  }`}
                </p>
              </Tooltip>
            </Fragment>,
            <Fragment key={`warning-table-row-${detail.type.id}-${index}-1`}>
              <StatusIndicator
                color={getStatusColor(detail.type, status)}
                size="small"
                status={getStatusLabel(detail.type, status)}
              />
              <p className={classNames('no-margin', styles.tableSublabel)}>
                {assignee || translate('global.unassigned')}
              </p>
            </Fragment>,
          ],
        ),
      };
    }
    if (MemberWarningDetail.isOpenItems(detail)) {
      return {
        header: getTableHeader(detail.type),
        data: detail.items.map(
          ({ type, dateTime, status, assignee }, index) => [
            <Fragment key={`warning-table-row-${detail.type.id}-${index}-0`}>
              <p className={classNames('no-margin', styles.tableLabel)}>
                {type}
              </p>
              <p className={classNames('no-margin', styles.tableSublabel)}>
                {dateTime?.format(DATE_FORMAT_SHORT_WITH_TIME)}
              </p>
            </Fragment>,
            <Fragment key={`warning-table-row-${detail.type.id}-${index}-1`}>
              <StatusIndicator
                color={getStatusColor(detail.type, status)}
                size="small"
                status={getStatusLabel(detail.type, status)}
              />
              <p className={classNames('no-margin', styles.tableSublabel)}>
                {assignee || translate('global.unassigned')}
              </p>
            </Fragment>,
          ],
        ),
      };
    }
  };

  const getLink = (detail: MemberWarningDetail) => {
    if (
      MemberWarningDetail.isMissingPCPInfo(detail) ||
      MemberWarningDetail.isMissingPharmacyInfo(detail)
    )
      return {
        link: `#/patients/${patientId}/network`,
        text: translate('memberWarnings.modal.goToNetwork'),
      };
    if (MemberWarningDetail.isMissingConsent(detail))
      return {
        link: `#/patients/${patientId}/personal-details?anchor=consents`,
        text: translate('memberWarnings.modal.goToConsent'),
      };
    if (MemberWarningDetail.isOpenEvents(detail))
      return {
        link: `#/patients/${patientId}/events`,
        text: translate('memberWarnings.modal.goToEvents'),
      };
    if (MemberWarningDetail.isOpenTasks(detail))
      return {
        link: `#/patients/${patientId}/tasks`,
        text: translate('memberWarnings.modal.goToTasks'),
      };
    if (
      MemberWarningDetail.isRPMMissed(detail) ||
      MemberWarningDetail.isRPMRisk(detail)
    )
      return {
        link: `#/patients/${patientId}/biometrics`,
        text: translate('memberWarnings.modal.goToVitals'),
      };
    if (MemberWarningDetail.isTOC(detail))
      return {
        link: `#/patients/${patientId}/toc-list`,
        text: translate('memberWarnings.modal.goToTOCDetails'),
      };

    return {
      link: undefined,
      text: undefined,
    };
  };

  return (
    <CollapsableSidebar
      className={styles.modal}
      title={
        <h2>
          <WarningIcon />
          &nbsp;
          {translate('memberWarnings.modal.title', {
            member: patient.fullName,
          })}
        </h2>
      }
      onClose={onClose}
      open={open}
      size={500}
    >
      <CollapsableSidebar.Body full>
        {loading && (
          <div className="flex center">
            <Spinner color="primary" width={40} />
          </div>
        )}
        {!loading && !warnings?.length && <span>No warnings available</span>}
        {!loading && (
          <>
            {(warnings || warningsDetail)?.map((detail) => {
              const { link, text } = getLink(detail);
              return (
                <MemberWarningsCard
                  data={getData(detail)}
                  key={`member-${patientId}-warning-${detail.type.description}-detail`}
                  link={
                    patient instanceof CCMMember &&
                    Session.actingUser.showMemberLink
                      ? link
                      : undefined
                  }
                  linkText={text}
                  onClickLink={() => onClose()}
                  openNewTab={patient instanceof CCMMember}
                  title={detail.type.description}
                  titleIcon={
                    <MemberWarningIcon
                      category={detail.type.category}
                      className={styles.warningIcon}
                    />
                  }
                  subtitle={getSubtitle(detail)}
                  table={getTable(detail)}
                  updatedAt={
                    MemberWarningDetail.isOpenActions(detail)
                      ? detail.updatedAt
                      : undefined
                  }
                />
              );
            })}
          </>
        )}
      </CollapsableSidebar.Body>
    </CollapsableSidebar>
  );
};

export default MemberWarningsModal;
