import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { CCMServiceProvider } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  EventMember,
  Patient,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import { CCMMemberDetailHeader } from '@vestahealthcare/common/models/CCMMemberDetailHeader';

import { Chip, Colors, Fonts } from 'styleguide-v2';

type Props = {
  className?: string;
  patient: Patient | CCMMemberDetailHeader | EventMember;
};

const useStyles = makeStyles({
  chip: {
    '&&': {
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontFamily: 'Proxima Nova Condensed',
      letterSpacing: 1,
      margin: '0 0 0 1rem ',
      paddingBottom: '0.3rem',
    },
  },
  core: {
    backgroundColor: Colors.gold,
    borderColor: Colors.gold,
    color: Colors.textBlack,
  },
  lite: {
    backgroundColor: Colors.textRed2,
    borderColor: Colors.textRed2,
    color: Colors.white,
  },
  vpc: {
    backgroundColor: Colors.purple,
    borderColor: Colors.purple,
    color: Colors.white,
  },
  vpcVesta: {
    backgroundColor: Colors.purple,
    borderColor: Colors.purple,
    color: Colors.mint,
  },
  vpcShared: {
    backgroundColor: `${Colors.purple}26`,
    borderColor: `${Colors.purple}26`,
    color: Colors.purple,
  },
  test: {
    backgroundColor: Colors.pink,
    borderColor: Colors.pink,
    color: Colors.textBlack,
  },
});

export const VestaCoreLiteTag = ({ className, patient }: Props) => {
  const styles = useStyles();

  const hasCoreTag = !!patient.programExtensions?.find(
    ({ programExtension: { id }, status }) =>
      ProgramExtension.VESTA_CORE === id &&
      ProgramExtensionStatus.DISENROLLED !== status?.id,
  );
  const hasLiteTag = !!patient.programExtensions?.find(
    ({ programExtension: { id }, status }) =>
      ProgramExtension.VESTA_LITE === id &&
      ProgramExtensionStatus.DISENROLLED !== status?.id,
  );

  const peCCM = patient.isVPC();
  const hasVPCTag = !!peCCM;
  const hasVPCVestaTag =
    peCCM && peCCM?.serviceProvider === CCMServiceProvider.VESTA;
  const hasVPCLiteTag = !!patient.isVPCLite();

  const getChipTitle = () => {
    if (hasCoreTag) return translate('memberProfile.header.coreTitle');
    if (hasLiteTag) return translate('memberProfile.header.liteTitle');
    if (hasVPCLiteTag) return translate('memberProfile.header.vpcVestaTitle');
    if (hasVPCTag) return translate('memberProfile.header.vpcTitle');
  };

  return (
    <>
      {!(patient instanceof EventMember) && patient.test && (
        <Chip
          className={classNames(className, styles.chip, styles.test)}
          title={translate('memberProfile.header.testTitle')}
        >
          {translate('memberProfile.header.test')}
        </Chip>
      )}
      {(hasCoreTag || hasLiteTag) && (
        <Chip
          className={classNames(
            className,
            styles.chip,
            hasLiteTag && styles.lite,
            !hasLiteTag && hasCoreTag && styles.core,
          )}
          title={getChipTitle()}
        >
          {!hasLiteTag && hasCoreTag && translate('memberProfile.header.core')}
          {hasLiteTag && translate('memberProfile.header.lite')}
        </Chip>
      )}

      {(hasVPCTag || hasVPCLiteTag) && (
        <Chip
          className={classNames(
            className,
            styles.chip,
            hasVPCLiteTag && styles.vpcShared,
            hasVPCVestaTag && styles.vpcVesta,
            hasVPCTag && !hasVPCLiteTag && !hasVPCVestaTag && styles.vpc,
          )}
          title={getChipTitle()}
        >
          {!hasVPCLiteTag && hasVPCTag && translate('memberProfile.header.vpc')}
          {hasVPCLiteTag && translate('memberProfile.header.vpcShared')}
        </Chip>
      )}
    </>
  );
};
