import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamPerson,
  LanguagePreference,
  UserPhoneVerified,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';
import { isValidMail } from '@vestahealthcare/common/utils/format';

import {
  Button,
  CheckboxWithLabel,
  CollapsableSidebar,
  TextInput,
} from 'styleguide-v2';

import { EditLanguages } from 'dash/src/components/EditLanguages';
import { EditPhones } from 'dash/src/components/EditPhones';
import { UpdateCareTeamPersonParams } from 'dash/src/services/CareTeamServices';
import Session from 'dash/src/services/SessionServices';

interface Props {
  open?: boolean;
  onClose: () => void;
  onSubmit: (
    id: number,
    params: UpdateCareTeamPersonParams,
  ) => Promise<boolean>;
  person?: CareTeamPerson;
  showContactVerified?: boolean;
}

const useSyles = makeStyles({
  notes: {
    width: 'max-content',
  },
  verified: {
    '&& .MuiCheckbox-root': {
      paddingLeft: '0.5rem',
      paddingTop: '0.5rem',
    },
  },
});

export const EditContactModal = ({
  open,
  onClose,
  onSubmit,
  person,
  showContactVerified,
}: Props) => {
  const styles = useSyles();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [phones, setPhones] = useState<UserPhoneVerified[]>([]);
  const [languages, setLanguages] = useState<Partial<LanguagePreference>[]>([]);
  const [email, setEmail] = useState<string>();
  const [verifiedPhones, setVerifiedPhones] = useState<{
    [x: string]: boolean | null;
  }>({});

  useEffect(() => {
    setPhones(person?.phones || []);
    setLanguages(person?.languages || []);
    setEmail(person?.email);
    setVerifiedPhones({});
  }, [person, open]);

  useEffect(() => {
    setLoading(false);
    setSubmitted(false);
  }, [open]);

  const validate = () =>
    (!email || isValidMail(email)) &&
    (!languages?.length || !languages.some(({ language }) => !language)) &&
    (!phones?.length ||
      !phones.some(({ phone }) => !phone?.number || !phone?.type));

  const doSubmit = async () => {
    setSubmitted(true);
    if (person && validate()) {
      setLoading(true);
      try {
        await onSubmit(person.id, {
          email,
          phones: phones.map((phone) => ({
            ...phone,
            ...(showContactVerified
              ? {
                  // eslint-disable-next-line no-nested-ternary
                  verifiedAt: verifiedPhones[`${phone.id}`]
                    ? moment()
                    : verifiedPhones[`${phone.id}`] === null
                    ? null
                    : phone?.verifiedAt,
                  // eslint-disable-next-line no-nested-ternary
                  verifiedBy: verifiedPhones[`${phone.id}`]
                    ? Session.actingUser
                    : verifiedPhones[`${phone.id}`] === null
                    ? null
                    : phone?.verifiedBy,
                }
              : {}),
          })),
          languages: languages as LanguagePreference[],
        });
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!open}
      title={
        <h2>
          {translate('careTeam.modals.editContactModalTitle', {
            name: person?.fullName,
          })}
        </h2>
      }
      size={550}
    >
      <CollapsableSidebar.Body>
        <TextInput
          className="grid-span-12"
          data-cy="care-team-steps-email"
          error={
            submitted &&
            email &&
            !isValidMail(email) &&
            translate('common.errors.invalidEmail')
          }
          label={translate('careTeam.common.email')}
          onChange={(email) => {
            setEmail(email);
          }}
          value={email}
        />

        <EditLanguages
          className="grid-span-12"
          languages={languages}
          onChange={(items) => {
            setLanguages(items ? [...items] : []);
          }}
          submitted={submitted && !!languages?.length}
        />
        <EditPhones
          className="grid-span-12"
          getItemFooter={
            showContactVerified
              ? (phone) =>
                  phone?.id &&
                  person?.phones?.find(
                    (pp) => pp.phone.number === phone?.phone?.number,
                  ) &&
                  !(phone as UserPhoneVerified)?.verifiedAt ? (
                    <CheckboxWithLabel
                      checked={!!verifiedPhones[`${phone.id}`]}
                      className={styles.verified}
                      label={translate('common.verified')}
                      onChange={(value) => {
                        verifiedPhones[`${phone.id}`] = value;
                        setVerifiedPhones({ ...verifiedPhones });
                      }}
                    />
                  ) : undefined
              : undefined
          }
          getItemNote={
            showContactVerified
              ? (phone) =>
                  phone?.id &&
                  person?.phones?.find(
                    (pp) => pp.phone.number === phone?.phone?.number,
                  ) &&
                  !!(phone as UserPhoneVerified)?.verifiedAt
                    ? translate('careTeam.common.tooltipVerifiedBy', {
                        date: (phone as UserPhoneVerified).verifiedAt?.format(
                          DATE_FORMAT_SHORT,
                        ),
                        user:
                          (phone as UserPhoneVerified)?.verifiedBy?.fullName ||
                          EMPTY,
                      })
                    : undefined
              : undefined
          }
          getItemNoteClassName={() => styles.notes}
          phones={phones}
          onChange={(items) => {
            items.forEach((phone) => {
              const findPhone = person?.phones?.find(
                (pp) => pp.phone.number === phone?.phone?.number,
              );
              verifiedPhones[`${phone.id}`] = findPhone
                ? verifiedPhones[`${phone.id}`]
                : null;
            });
            setVerifiedPhones({ ...verifiedPhones });
            setPhones([...items]);
          }}
          showBusinessType
          submitted={submitted && !!phones?.length}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="care-team-detail-edit-contact-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="care-team-detail-edit-contact-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default EditContactModal;
