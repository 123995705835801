import { Brand, Language, MemberStatus, PatientGender } from '../enums';
import { CareTrack } from './CareTrack';
import { Employee } from './Employee';
import {
  PatientBrandHelper,
  hasBrandCareAtHome,
} from './Helpers/PatientBrandHelper';
import {
  VestaCoreLiteHelper,
  isVPC,
  isVPCLite,
  isVestaCoreLite,
} from './Helpers/VestaCoreLiteHelper';
import { HouseHoldMember } from './HouseHoldMember';
import { MemberContactInfo } from './MemberContactInfo';
import { MemberProgramExtension } from './MemberProgramExtension';
import { MemberReferral } from './MemberReferral';
import { make, makeArray } from './Model';

export class CCMMemberDetailHeader
  implements PatientBrandHelper, VestaCoreLiteHelper {
  id = 0;

  memberId = 0;

  firstName = '';

  lastName = '';

  fullName = '';

  status: MemberStatus;

  birthDate = '';

  language: Language;

  owner?: Employee;

  activeReferrals?: MemberReferral[];

  programExtensions?: MemberProgramExtension[];

  gender: PatientGender;

  contactInfo: MemberContactInfo;

  totalEventsCount = 0;

  openEventsCount = 0;

  riskPrograms: CareTrack[];

  ccmValidMinutes = 0;

  ccmTotalMinutes = 0;

  ccmProfessionalMinutes = 0;

  notes = '';

  householdMembers?: HouseHoldMember[];

  test = false;

  brand: Brand;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.id = obj.memberId;
    this.status = MemberStatus.byKey[obj.status];
    this.householdMembers = makeArray(obj.householdMembers, HouseHoldMember);
    this.language = Language.byKey[obj.language];
    this.owner = make(obj.owner, Employee);
    this.gender = PatientGender.byKey[obj.gender];
    this.contactInfo = make(obj.contactInfo, MemberContactInfo);
    this.activeReferrals = makeArray(obj.activeReferrals, MemberReferral);
    this.programExtensions = makeArray(
      obj.programExtensions,
      MemberProgramExtension,
    );
    this.riskPrograms = makeArray(obj.riskPrograms, CareTrack);
    this.brand = Brand.byKey[obj.brand];
  }

  isVPC(): false | MemberProgramExtension {
    return isVPC(this as VestaCoreLiteHelper);
  }

  isVPCLite(): false | MemberProgramExtension {
    return isVPCLite(this as VestaCoreLiteHelper);
  }

  isVestaCoreLite(): false | MemberProgramExtension {
    return isVestaCoreLite(this as VestaCoreLiteHelper);
  }

  isBrandCareAtHome(): boolean {
    return hasBrandCareAtHome(this);
  }
}

export default CCMMemberDetailHeader;
