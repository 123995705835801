import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import ForwardArrowIcon from '@mui/icons-material/ArrowForwardIos';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  MemberContactMethodType,
  MemberContactTimeType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { CCMMemberDetail } from '@vestahealthcare/common/models';
import { CCMMemberDetailHeader } from '@vestahealthcare/common/models/CCMMemberDetailHeader';
import {
  DATE_FORMAT_SHORT,
  TIME_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  BrandMemberIcon,
  Chip,
  Colors,
  Fonts,
  MemberProgramExtensions,
} from 'styleguide-v2';

import { MemberWarnings } from 'dash/src/pages/MemberProfile/Header/MemberWarnings';
import { VestaCoreLiteTag } from 'dash/src/pages/MemberProfile/Header/VestaCoreLiteTag';
import { parseMemberContactInfo } from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';

import { InfoHeaderCard } from './InfoHeaderCard';

type Props = {
  member?: CCMMemberDetailHeader;
  detail?: CCMMemberDetail;
};

const SORT_ORDER = [
  undefined,
  MemberContactTimeType.ANY_TIME,
  MemberContactTimeType.MORNING,
  MemberContactTimeType.AFTERNOON,
  MemberContactTimeType.CUSTOM,
];

const useStyles = makeStyles({
  title: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: Fonts.fontSize,
      fontWeight: 400,
      letterSpacing: 'initial',
      lineHeight: 'initial',
      marginLeft: '1rem',
      marginRight: 0,
      textTransform: 'initial',
      maxWidth: 'calc(100% - 5rem)',
    },
  },
  riskPrograms: {
    maxWidth: '25rem',

    '& .risk-moderate': {
      color: Colors.gold,
    },
    '& .risk-high': {
      color: Colors.orange,
    },
  },
  small: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
  bold: {
    fontWeight: 500,
  },
  gap: {
    gap: '1rem',
  },
  columns: {
    display: 'flex',
    gap: '1rem',
  },
  name: {
    gap: 0,
    margin: '0.5rem 0',
  },
  id: {
    color: '#3f8bf4',
  },
  notes: {
    backgroundColor: BackgroundColors.lightYellow,
    fontSize: '.75em',
    margin: '.5rem 0 0 0',
    maxWidth: 'calc(100vw - 75rem)',
    padding: '.5rem',
    width: 'fit-content',
  },
  brandIcon: {
    marginLeft: '.6rem',
  },
});

export const InfoModalHeader = ({ detail, member }: Props) => {
  const styles = useStyles();
  const header = detail?.header;
  const household = header?.householdMembers;

  return (
    <div className={classNames(styles.title, 'flex spaced')}>
      <div>
        <h2 className={styles.name}>
          <span>
            {header?.fullName} (
            {!Session.actingUser.showMemberLink ? (
              member?.memberId
            ) : (
              <a
                className={styles.id}
                href={`#/patients/${member?.memberId}`}
                target="_blank"
                rel="noreferrer"
              >
                {member?.memberId}
              </a>
            )}
            )
          </span>
          {(member?.isVestaCoreLite() ||
            member?.isVPC() ||
            member?.isVPCLite() ||
            member?.test) && <VestaCoreLiteTag patient={member} />}

          {detail?.isBrandCareAtHome() && (
            <BrandMemberIcon
              brand="careAtHome"
              className={styles.brandIcon}
              size="m"
            />
          )}
          {household && !!household?.length && (
            <Chip
              color="secondary"
              onClick={
                household.length === 1
                  ? () =>
                      window.open(
                        `#/patients/${household[0].linkedMember.id}`,
                        '_blank',
                        'noreferrer',
                      )
                  : undefined
              }
              title={
                household.length !== 1 ? (
                  <>
                    {household.map(({ id, linkedMember, relationship }) => (
                      <p
                        className="no-margin white"
                        key={`member-${linkedMember.id}-household-${id}`}
                      >
                        {relationship.description}: {linkedMember.fullName} (
                        <a
                          className="lighten"
                          href={`#/patients/${linkedMember.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {linkedMember.id}
                        </a>
                        )
                      </p>
                    ))}
                  </>
                ) : (
                  household[0].relationship.description
                )
              }
              type="contained-2"
            >
              <div className="flex middle gap">
                <GroupOutlinedIcon />
                {household.length === 1
                  ? household[0].linkedMember.nameWithId
                  : translate('memberProfile.header.householdCount', {
                      count: household.length,
                    })}
                {household.length === 1 && <ForwardArrowIcon />}
              </div>
            </Chip>
          )}
        </h2>
        <div className={classNames('flex', styles.gap)}>
          {header?.language && (
            <span className={classNames(styles.small, styles.bold)}>
              {header.language.toString()}
            </span>
          )}
          {header?.birthDate && (
            <span className={styles.small}>
              {`DOB: ${moment(header.birthDate).format(DATE_FORMAT_SHORT)}`}
              &nbsp;
              {`(${translate('global.yearsOld', {
                age: moment().diff(moment(header.birthDate), 'years'),
              })})`}
            </span>
          )}
          {header?.gender && (
            <span className={styles.small}>{header.gender.toString()}</span>
          )}
          {header?.owner && (
            <span className={styles.small}>
              {translate('global.rn')}: {header.owner.fullName}
            </span>
          )}
          {!!header?.activeReferrals?.length && (
            <span className={styles.small}>
              {header.activeReferrals
                ?.map(
                  ({ organization }) => organization.abbr || organization.name,
                )
                .join(', ')}
            </span>
          )}
          {!!header?.programExtensions?.length && (
            <span>
              <MemberProgramExtensions
                className={styles.small}
                data-cy="program-extension-name"
                programExtensions={header.programExtensions}
              />
            </span>
          )}
          {header?.status && (
            <span className={classNames(styles.small, styles.bold)}>
              {header.status.toString()}
            </span>
          )}
        </div>
        {header?.notes && <div className={styles.notes}>{header.notes}</div>}
      </div>
      <div className="flex gap">
        {!!header?.contactInfo && (
          <InfoHeaderCard
            label={translate('ccm.modals.info.contactInfo')}
            value={
              <div className={styles.columns}>
                <div>
                  {(header.contactInfo.memberContactMethods
                    ? [...header.contactInfo.memberContactMethods]
                    : []
                  )
                    .sort((a, b) =>
                      a.contactMethod
                        .toString()
                        .localeCompare(b.contactMethod.toString()),
                    )
                    ?.map(({ contactMethod, contactMethodOther }) =>
                      contactMethod === MemberContactMethodType.OTHER ? (
                        <>
                          <p className="small no-margin">
                            {contactMethod.toString()}
                          </p>
                          <p className="small no-margin">
                            {contactMethodOther}
                          </p>
                        </>
                      ) : (
                        <p className="small no-margin">
                          {contactMethod.toString()}
                        </p>
                      ),
                    )}
                </div>
                <div>
                  {parseMemberContactInfo(header.contactInfo.memberContactTimes)
                    ?.sort(
                      (a, b) =>
                        SORT_ORDER.indexOf(a?.type) -
                        SORT_ORDER.indexOf(b?.type),
                    )
                    .map(({ dates, type, startTime, endTime }, idx) => (
                      <p
                        className="small no-margin"
                        key={`member-contact-info-days-${idx}`}
                      >
                        {dates?.length && (
                          <>{DayOfWeek.stringify(dates)}:&nbsp;</>
                        )}
                        {type !== MemberContactTimeType.CUSTOM &&
                          type?.toString()}
                        {type === MemberContactTimeType.CUSTOM && (
                          <>
                            {startTime
                              ? moment(startTime.getTime()).format(TIME_FORMAT)
                              : '*'}
                            &nbsp;-&nbsp;
                            {endTime
                              ? moment(endTime.getTime()).format(TIME_FORMAT)
                              : '*'}
                          </>
                        )}
                      </p>
                    ))}
                </div>
              </div>
            }
          />
        )}
        {member && (
          <MemberWarnings
            clickable={Session.actingUser.showMemberWarningsModal}
            patient={member}
            warnings={detail?.warnings}
          />
        )}
      </div>
    </div>
  );
};
