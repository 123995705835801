import { Brand, Language, MemberRisk, MemberStatus, States } from '../enums';
import { EMPTY } from '../utils/constants';
import { BiometricsConfig } from './BiometricsConfig';
import { CarePlanGroup } from './CarePlanGroup';
import { DBEnum } from './DBEnum';
import { Employee } from './Employee';
import { EmployeeGroup } from './EmployeeGroup';
import {
  PatientBrandHelper,
  hasBrandCareAtHome,
} from './Helpers/PatientBrandHelper';
import {
  VestaCoreLiteHelper,
  isVPC,
  isVPCLite,
  isVestaCoreLite,
} from './Helpers/VestaCoreLiteHelper';
import { HouseHoldMember } from './HouseHoldMember';
import { MemberPhone } from './MemberPhone';
import { MemberProgramExtension } from './MemberProgramExtension';
import { MemberReferral } from './MemberReferral';
import { make, makeArray } from './Model';
import { ProgramExtensionStatus } from './ProgramExtensionStatus';

export class MemberView implements PatientBrandHelper, VestaCoreLiteHelper {
  activeReferrals?: MemberReferral[];

  brand: Brand;

  careCoordinators?: Employee[];

  carePlanGroup?: CarePlanGroup;

  deceasedDate?: string;

  engagementOwners?: Employee[];

  externalId?: string;

  firstName = '';

  fullName = '';

  healthCoaches?: Employee[];

  householdMembers?: HouseHoldMember[];

  id = 0;

  language?: Language;

  lastName = '';

  npOwner?: Employee;

  owner?: Employee;

  podManager?: Employee;

  primaryPhone?: MemberPhone;

  programExtensions: MemberProgramExtension[] = [];

  riskLevel?: MemberRisk;

  state: States;

  status?: MemberStatus;

  vitalsConfigs?: BiometricsConfig[];

  worklistGroup?: EmployeeGroup;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.activeReferrals = makeArray(obj.activeReferrals, MemberReferral);
    this.carePlanGroup = make(obj.carePlanGroup, DBEnum);
    this.language = obj.language && Language.byKey[obj.language];
    this.careCoordinators = (makeArray(
      obj.careCoordinators,
      Employee,
    ) as Employee[])?.sort(Employee.sort);
    this.engagementOwners = (makeArray(
      obj.engagementOwners,
      Employee,
    ) as Employee[])?.sort(Employee.sort);
    this.healthCoaches = (makeArray(
      obj.healthCoaches,
      Employee,
    ) as Employee[])?.sort(Employee.sort);
    this.householdMembers = makeArray(obj.householdMembers, HouseHoldMember);
    this.npOwner = make(obj.npOwner, Employee);
    this.owner = make(obj.owner, Employee);
    this.podManager = make(obj.podManager, Employee);
    this.riskLevel = MemberRisk.byKey[obj.riskLevel];
    this.state = States.byKey[obj.state];
    this.status = MemberStatus.byKey[obj.status];
    this.primaryPhone = obj.primaryPhone && make(obj.primaryPhone, MemberPhone);
    this.programExtensions = makeArray(
      obj.programExtensions,
      MemberProgramExtension,
    );
    this.vitalsConfigs = makeArray(obj.vitalsConfigs, BiometricsConfig);
    this.worklistGroup = make(obj.worklistGroup, EmployeeGroup);
    this.brand = Brand.byKey[obj.brand];
  }

  get referralsAbbrs() {
    return (
      this.activeReferrals
        ?.map((ref) => ref.organization.abbr?.toUpperCase())
        .join(', ') || EMPTY
    );
  }

  getSortedProgramExtensions(filter?: boolean) {
    const sorted = [...this.programExtensions].sort((a, b) =>
      a.programExtension.name.localeCompare(b.programExtension.name),
    );

    if (filter) {
      return sorted.filter(
        ({ status }) => status?.id !== ProgramExtensionStatus.DISENROLLED,
      );
    }
    return sorted;
  }

  isBrandCareAtHome(): boolean {
    return hasBrandCareAtHome(this);
  }

  isVPC(): false | MemberProgramExtension {
    return isVPC(this as VestaCoreLiteHelper);
  }

  isVPCLite(): false | MemberProgramExtension {
    return isVPCLite(this as VestaCoreLiteHelper);
  }

  isVestaCoreLite(): false | MemberProgramExtension {
    return isVestaCoreLite(this as VestaCoreLiteHelper);
  }
}

export default MemberView;
