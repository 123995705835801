import { Brand } from '../enums';
import { CCMMemberDetailContact } from './CCMMemberDetailContact';
import { CCMMemberDetailHeader } from './CCMMemberDetailHeader';
import {
  PatientBrandHelper,
  hasBrandCareAtHome,
} from './Helpers/PatientBrandHelper';
import { MemberDashboard } from './MemberDashboard';
import { MemberDashboardEvent } from './MemberDashboardEvent';
import { MemberDashboardTask } from './MemberDashboardTask';
import { make, makeArray } from './Model';
import { PaginatedResponse } from './Pagination';
import { Task } from './Task';
import { MemberWarning } from './member-warnings/MemberWarning';
import { MemberWarningCategory } from './member-warnings/MemberWarningCategory';

const WARNING_SORT = {
  [MemberWarningCategory.APPOINTMENT]: 0,
  [MemberWarningCategory.TIME]: 5,
  [MemberWarningCategory.MISSING_INFO]: 10,
  [MemberWarningCategory.OPEN_ITEMS]: 15,
  [MemberWarningCategory.RPM_RISK]: 20,
};

const sortMemberWarning = (
  { type: { category: a } }: MemberWarning,
  { type: { category: b } }: MemberWarning,
) => WARNING_SORT[a.id] - WARNING_SORT[b.id];

export class CCMMemberDetail
  extends MemberDashboard
  implements PatientBrandHelper {
  header: CCMMemberDetailHeader;

  brand: Brand;

  contactSummary: CCMMemberDetailContact;

  recentTasks: PaginatedResponse<MemberDashboardTask>;

  openRPMTasks: MemberDashboardTask[];

  recentEvents: PaginatedResponse<MemberDashboardEvent>;

  warnings: MemberWarning[];

  constructor(obj: any) {
    super(obj);
    this.brand = Brand.byKey[obj.brand];
    this.contactSummary = make(obj.contactSummary, CCMMemberDetailContact);
    this.header = make(obj.header, CCMMemberDetailHeader);
    this.recentEvents = {
      items: makeArray(obj.recentEvents.items, MemberDashboardEvent),
      pagination: obj.recentEvents.pagination,
    };
    this.recentTasks = {
      items: makeArray(obj.recentTasks.items, MemberDashboardTask),
      pagination: obj.recentTasks.pagination,
    };
    this.openRPMTasks = makeArray(obj.openRpmTasks, MemberDashboardTask)?.sort(
      Task.sortByStatus,
    );
    this.warnings = makeArray(obj.warnings, MemberWarning)?.sort(
      sortMemberWarning,
    );
  }

  isBrandCareAtHome(): boolean {
    return hasBrandCareAtHome(this);
  }
}

export default CCMMemberDetail;
