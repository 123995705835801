import { Enum } from './Enum';

export class EmployeeRole extends Enum {
  translationKey = 'employeeRole';

  static asArray: EmployeeRole[] = [];

  static byKey: { [name: string]: EmployeeRole } = {};

  static readonly ADMIN = new EmployeeRole('admin');

  static readonly CARE_NAVIGATOR = new EmployeeRole('care_navigator', true);

  static readonly CARE_MANAGER = new EmployeeRole('care_manager', true);

  static readonly CITY_MANAGER = new EmployeeRole('city_manager', true);

  static readonly COMPLIANCE = new EmployeeRole('compliance', true);

  static readonly CST_LIMITED = new EmployeeRole('cst_limited', true);

  static readonly LPN = new EmployeeRole('lpn');

  static readonly MATCHER = new EmployeeRole('matcher', true);

  static readonly NP = new EmployeeRole('np');

  static readonly MD = new EmployeeRole('md');

  static readonly LVN = new EmployeeRole('lvn');

  static readonly MA = new EmployeeRole('ma');

  static readonly CNA = new EmployeeRole('cna');

  static readonly OPERATIONS = new EmployeeRole('operations');

  static readonly PEOPLE_OPS = new EmployeeRole('people_ops', true);

  static readonly RECRUITER = new EmployeeRole('recruiter', true);

  static readonly SALES = new EmployeeRole('sales', true);

  static readonly RELIABILITY_TEAM = new EmployeeRole('reliability_team', true);

  static readonly RN = new EmployeeRole('rn');

  static readonly UNKNOWN = new EmployeeRole('unknown');

  static readonly EXTERNAL = new EmployeeRole('external');

  static readonly ENGAGEMENT_COORDINATOR = new EmployeeRole(
    'engagement_coordinator',
  );

  static readonly HEALTH_COACH = new EmployeeRole('health_coach');

  static readonly OA = new EmployeeRole('oa');

  static readonly MCD = new EmployeeRole('mcd');

  static readonly PHARMACIST = new EmployeeRole('pharmacist');

  static readonly PHARMACIST_MA = new EmployeeRole('pharmacist_ma');

  static readonly SOCIAL_WORKER = new EmployeeRole('social_worker');

  static readonly CEC = new EmployeeRole('cec');

  constructor(
    public readonly value: string,
    public readonly deprecated = false,
  ) {
    super();
    EmployeeRole.asArray.push(this);
    EmployeeRole.byKey[value] = this;
  }

  static toSelectable() {
    return Enum.toSelectable(
      EmployeeRole.asArray.filter((val) => !val.deprecated),
    );
  }
}

export default EmployeeRole;
