import classNames from 'classnames';
import React, { useState } from 'react';

import { ClassNameMap, makeStyles } from '@mui/styles';

import {
  AppointmentStatus,
  EncounterDirection,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberDashboardEncounter } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  CollapseIcon,
  Colors,
  Fonts,
  StatusIndicator,
  Table,
  Tooltip,
  UNASSIGNED,
} from 'styleguide-v2';

type Props = {
  className?: string;
  data?: MemberDashboardEncounter[];
  type: 'TOC' | 'SKILLED';
};

const MAX_DASHBOARD_TABLE_ITEMS = 3;

const RECENT_ENCONUNTERS_COLUMNS = (styles: ClassNameMap<'status'>) => [
  {
    headerName: 'Encounter',
    component: ({
      date,
      dateTime,
      details,
      employeeName,
    }: MemberDashboardEncounter) => (
      <StatusIndicator
        className={styles.status}
        color={details ? AppointmentStatus.getColor(details) : 'success'}
        status={details?.toString() || EncounterDirection.INBOUND.toString()}
        subtitle={`${
          dateTime
            ? dateTime.format(DATE_FORMAT_SHORT_WITH_TIME)
            : date.format(DATE_FORMAT_SHORT)
        } - ${employeeName || UNASSIGNED.fullName}`}
      />
    ),
    width: 275,
  },
  {
    headerName: 'Notes',
    component: ({ notes }: MemberDashboardEncounter) =>
      notes ? (
        <Tooltip text={notes}>
          <div
            className="ellipsis-2-lines"
            style={{ maxWidth: 'calc(50vw - 55rem)', width: 'fit-content' }}
          >
            {notes}
          </div>
        </Tooltip>
      ) : (
        EMPTY
      ),
  },
];

const useStyles = makeStyles({
  table: {
    marginTop: 1,
  },
  tableContainer: {
    display: 'flex',
    marginBottom: '1rem',
    minHeight: '2rem',
    maxHeight: '18.9rem',
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
  },
  seeMore: {
    color: Colors.textGreen,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  status: {
    '& > :first-child': {
      margin: '4px 0 0',
    },
    '& > :last-child': {
      marginTop: '-3px',
    },
  },
});

export const TOCEncountersTable = (props: Props) => {
  const styles = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={props.className}>
      <div
        className={styles.tableContainer}
        style={{
          maxHeight: open
            ? `${(props.data?.length || 2) * 5.495 + 3}rem`
            : undefined,
        }}
      >
        <Table
          className={styles.table}
          config={{
            columns: RECENT_ENCONUNTERS_COLUMNS(styles),
            compact: true,
            data: props.data || [],
            paginationOptions: [-1],
          }}
          empty={translate(`toc.detail.noTOCEncounters.${props.type}`)}
          whiteBackground
        />
      </div>
      <div className="flex spaced">
        {(props.data?.length || 0) > MAX_DASHBOARD_TABLE_ITEMS ? (
          <a
            className={classNames('flex middle min-gap', styles.seeMore)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            <CollapseIcon open={open} htmlColor={Colors.textGreen} />
            {translate(`memberProfile.dashboard.${open ? 'hide' : 'see'}More`, {
              count: (props.data?.length || 0) - MAX_DASHBOARD_TABLE_ITEMS,
            })}
          </a>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
