import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';

import { translate } from '@vestahealthcare/common/i18n';
import { EventNote } from '@vestahealthcare/common/models';

import { Colors, IconButton, UpdatedBy } from 'styleguide-v2';

import Session from 'dash/src/services/SessionServices';

import { EventDetailCard } from './EventDetailCard';

const useStyles = makeStyles({
  noteContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    width: '100%',

    '& li:nth-child(even)': {
      backgroundColor: `${Colors.gray}33`,
      borderRadius: '0.5rem',
    },
  },
  note: {
    display: 'flex',
    flex: '1 1 60rem',
    gap: '0.5rem',
    padding: '0.5rem',

    '& > .note-content': {
      flex: '10 1 40rem',
    },

    '& > .note-created': {
      flex: '0 1 13.5rem',
    },

    '& > span.note-edit': {
      margin: '0',
      padding: '0 1rem 0 0',
    },
  },
});

type Props = {
  addendum?: boolean;
  canAdd: boolean;
  className?: string;
  notes?: EventNote[];
  onEdit: (note?: EventNote) => void;
};

export const EventDetailNotes = ({
  addendum: isAddendum = false,
  canAdd,
  className,
  notes,
  onEdit,
}: Props) => {
  const styles = useStyles();

  const renderNote = (note: EventNote, index: number) => (
    <li className={styles.note} key={`note-${index}`}>
      <div className="note-content">
        <span>{note.note}</span>
        {!!note.updatedAt.diff(note.createdAt, 'seconds') && (
          <>
            {' '}
            <span className="gray">(edited)</span>
          </>
        )}
      </div>
      <UpdatedBy
        className="note-created"
        date={note.createdAt.unix()}
        user={note.createdBy.fullName}
      />
      {Session.actingUser.canEditEventNote(note) ? (
        <IconButton
          className="note-edit"
          size="small"
          onClick={() => onEdit(note)}
        >
          <EditIcon color="primary" />
        </IconButton>
      ) : (
        <div style={{ width: '3rem' }} />
      )}
    </li>
  );

  if (!notes?.length) {
    return <></>;
  }

  return (
    <EventDetailCard
      className={className}
      title={
        isAddendum
          ? translate('memberEvents.detail.addendums')
          : translate('memberEvents.detail.notes')
      }
      titleIcon={
        canAdd && (
          <IconButton
            className="note-edit"
            size="small"
            onClick={
              canAdd
                ? () => onEdit(new EventNote({ addendum: isAddendum }))
                : undefined
            }
          >
            <AddIcon color="primary" />
          </IconButton>
        )
      }
    >
      <ul className={styles.noteContainer}>{notes.map(renderNote)}</ul>
    </EventDetailCard>
  );
};
export default EventDetailNotes;
