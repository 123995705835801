import { useFlags } from 'launchdarkly-react-client-sdk';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CCMIcon from '@mui/icons-material/AppRegistration';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import CustomSelectionIcon from '@mui/icons-material/GroupAdd';
import { makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  EncounterChannel,
  EncounterDirection,
  EncounterMethod,
  EncounterType,
  Language,
  MemberContactTimeType,
  RPMAdherenceLevel,
  RiskLevel,
  States,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BHIMember,
  CCMMember,
  CCMMemberDetail,
  ClinicalMember,
  DBEnum,
  Employee,
  EmployeeGroup,
  InsurancePlan,
  MemberDashboardEvent,
  MemberDashboardTask,
  Organization,
  PaginationType,
  ProgramExtension,
  ProgramExtensionStatus,
  RPMMember,
  StoredFilter,
} from '@vestahealthcare/common/models';
import { PaginatedResponse } from '@vestahealthcare/common/models/Pagination';
import { LS_DASHBOARD_CCM_FILTERS_V2 } from '@vestahealthcare/common/utils/constants';

import { Toast } from 'styleguide';
import {
  FilterItem,
  FilterSelectable,
  IconButton,
  LastUpdated,
  Panel,
  ToggleButton,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { MAX_DASHBOARD_TABLE_ITEMS } from 'dash/src/pages/MemberProfile/Dashboard/contansts';
import AddEncounterModal from 'dash/src/pages/MemberProfile/Encounters/AddEncounterModal';
import {
  onStoredFilterAdd,
  onStoredFilterChange,
  onStoredFilterDeleted,
} from 'dash/src/redux/slices/EmployeeSlice';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import {
  GetCCMMemberParams,
  assignToGroupWorklist,
  downloadCCMDashboard,
  downloadRPMDashboard,
  fecthBHIMembers,
  fecthCCMMembers,
  fecthRPMMembers,
  getMemberInfoData,
} from 'dash/src/services/CCMServices';
import {
  createSelfStoredFilter,
  deleteSelfStoredFilter,
  updateSelfStoredFilter,
} from 'dash/src/services/EmployeeServices';
import {
  fecthMemberDashboardEvents,
  fecthMemberDashboardTasks,
} from 'dash/src/services/MemberDashboardServices';
import Session from 'dash/src/services/SessionServices';
import {
  LoadFilterOption,
  getServerFilters,
  loadNewFilters,
  loadNewFiltersFromStorage,
  saveNewFilters,
  saveNewFiltersToStorage,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { dataUtils } from '../../../utils/dataUtils';
import { BHIFiltersBar } from './BHIFiltersBar';
import BHITable from './BHITable';
import {
  CCM_STATUSES,
  RECENT_CYCLES_OPTIONS,
  RISK_LEVEL_OPTION_NONE_VALUE,
  TIME_OPTION_UNKNOWN,
  VESTA_START_DATE_OPTIONS,
} from './CCMData';
import { CCMFiltersBar } from './CCMFiltersBar';
import { CCMFiltersModal } from './CCMFiltersModal';
import { CCMTable } from './CCMTable';
import { RPMFiltersBar } from './RPMFiltersBar';
import RPMTable from './RPMTable';
import { AssignWorklistModal } from './modals/AssignWorklistModal';
import { InfoCCMModal } from './modals/InfoModal';
import { getFilters } from './utils';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_SORTING = 'lastCCMTouchPointDate asc';

const UNASSIGNED = {
  label: translate('global.unassigned'),
  value: 0,
  info: undefined,
} as SelectableInfo<Employee | EmployeeGroup>;

const uniq = (array: any[]) => [...new Set(array)];
let CCMDashboardLastFilters = '';

const CCM_TOGGLE_ITEM_CCM = 'ccm-tab-ccm';
const CCM_TOGGLE_ITEM_RPM = 'ccm-tab-rpm';
const CCM_TOGGLE_ITEM_BHI = 'ccm-tab-bhi';
const CCM_TOGGLE_ITEMS = [
  {
    label: translate('ccm.tabs.ccm'),
    value: CCM_TOGGLE_ITEM_CCM,
  },
  {
    label: translate('ccm.tabs.rpm'),
    value: CCM_TOGGLE_ITEM_RPM,
  },
  {
    label: translate('ccm.tabs.bhi'),
    value: CCM_TOGGLE_ITEM_BHI,
  },
];

const useStyles = makeStyles({
  modal: {
    zIndex: '1300!important',
  },
  toggle: {
    '& > div': {
      height: '3rem',

      '& > button': {
        minWidth: '5rem',
      },
    },
  },
});

type KeyGetCCMParams = keyof GetCCMMemberParams;
const CCM_PAGE_FILTER = 'ccm-page';

export const CCMDashboard = () => {
  const { showBhi } = useFlags();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();
  const styles = useStyles();
  const { sort: defaultSort } = getFilters(query);

  const storedFilters = useSelector((state) =>
    state.employeeSlice.storedFilters?.filter(
      StoredFilter.filter(CCM_PAGE_FILTER),
    ),
  );

  const [selectedTab, setSelectedTab] = useState<string>();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetCCMParams]?: FilterItem;
    }
  >();
  const [sort, setSort] = useState<string>(
    (defaultSort as string) || DEFAULT_SORTING,
  );

  const [employees, setEmployees] = useState<SelectableInfo<Employee>[]>([]);
  const [brands, setBrands] = useState<Selectable[]>([]);
  const [carePlanGroups, setCarePlanGroups] = useState<Selectable[]>([]);
  const [employeeGroups, setEmployeeGroups] = useState<Selectable[]>([]);
  const [insurances, setInsurances] = useState<Selectable[]>([]);
  const [languages, setLanguages] = useState<SelectableInfo<Language>[]>([]);
  const [programExtensions, setProgramExtensions] = useState<
    ProgramExtension[]
  >([]);
  const [programExtensionStatuses, setProgramExtensionStatuses] = useState<
    ProgramExtensionStatus[]
  >([]);
  const [reasons, setReasons] = useState<Selectable[]>([]);
  const [referralSources, setReferralSources] = useState<
    SelectableInfo<Organization>[]
  >([]);

  const [ccmMembers, setCCMMembers] = useState<CCMMember[]>([]);
  const [rpmMembers, setRPMMembers] = useState<RPMMember[]>([]);
  const [bhiMembers, setBHIMembers] = useState<BHIMember[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Moment>();

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [openAssignModal, setOpenAssignModal] = useState<boolean>(false);
  const [infoMember, setInfoMember] = useState<ClinicalMember>();
  const [infoDetail, setInfoDetail] = useState<CCMMemberDetail>();
  const [infoDetailEvents, setInfoDetailEvents] = useState<
    PaginatedResponse<MemberDashboardEvent>
  >();
  const [infoDetailTasks, setInfoDetailTasks] = useState<
    PaginatedResponse<MemberDashboardTask>
  >();
  const [openLogCallModal, setOpenLogCallModal] = useState<EncounterType>();
  const [modalData, setModalData] = useState<ClinicalMember>();

  const getInitialData = async () => {
    setLoadingFilters(true);
    const [
      l,
      employees,
      pes,
      pess,
      cpgs,
      egs,
      o,
      reasons,
      brands,
      ins,
    ] = await Promise.all([
      CacheServices.getLanguages(),
      CacheServices.getEmployees(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getProgramExtensionStatus(),
      CacheServices.getCarePlanGroups(),
      CacheServices.getEmployeeGroupsWorklist(),
      CacheServices.getOrganizations(),
      CacheServices.getCCMTouchpointReasons(),
      CacheServices.getOrganizationBrands(),
      CacheServices.getInsurancePlans(),
    ]);

    setEmployees([
      UNASSIGNED as SelectableInfo<Employee>,
      ...Employee.toSelectable(employees),
    ]);

    setProgramExtensions(pes);
    setProgramExtensionStatuses(pess);

    setCarePlanGroups([UNASSIGNED, ...DBEnum.toSelectable(cpgs)]);

    setEmployeeGroups([UNASSIGNED, ...EmployeeGroup.toSelectable(egs)]);

    setReferralSources(Organization.toSelectable(o));

    setLanguages([
      UNASSIGNED,
      ...Language.toSelectable(l).filter(({ disabled }) => !disabled),
    ]);

    setReasons(
      reasons.map((item) => ({
        value: item,
        label: item,
      })),
    );
    setBrands(
      brands.map(({ id, name }) => ({ value: id, label: name } as Selectable)),
    );
    setInsurances(InsurancePlan.toSelectable(ins));

    setLoadingFilters(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const getListItems = async () => {
    if (!selectedTab) return;
    if (selectedTab === CCM_TOGGLE_ITEM_RPM) {
      getRPMMembers();
    } else if (selectedTab === CCM_TOGGLE_ITEM_BHI) {
      getBHIMembers();
    } else {
      getCCMMembers();
    }
  };

  const resetCCMMembers = async () => {
    if (page !== 0) {
      setPage(0);
    } else {
      await getListItems();
    }
  };

  const getReferralsWithChildren = (
    referrals: Organization[],
    result = [] as Organization[],
  ) => {
    referrals.forEach((referral) => {
      result.push(referral);
      if (referral.children?.length) {
        getReferralsWithChildren(referral.children, result);
      }
    });
    return result;
  };

  const prepareFilters = () => {
    let currentFilters: GetCCMMemberParams;
    let rpmStartDate;
    let lastReadingDate: number;

    if (!newFilters) return;
    ({ rpmStartDate, lastReadingDate, ...currentFilters } = getServerFilters(
      newFilters,
      {
        programExtensionFilter: dataUtils.programExtensions.parser,
      },
    ));
    rpmStartDate = parseInt(rpmStartDate?.toString(), 10);
    lastReadingDate = parseInt(lastReadingDate?.toString(), 10);
    currentFilters = {
      ...currentFilters,
      aideScheduleTime: currentFilters?.aideScheduleTime?.filter(
        (item) => !!MemberContactTimeType.byKey[item.valueOf()],
      ),
      contactTime: currentFilters?.contactTime?.filter(
        (item) => !!MemberContactTimeType.byKey[item.valueOf()],
      ),
      excludedReferralSource: getReferralsWithChildren(
        referralSources
          ?.filter(({ value }) =>
            currentFilters?.excludedReferralSource?.includes(value as string),
          )
          ?.map(({ info }) => info as Organization),
      )?.map(({ name }) => name),
      hasAideScheduleInfo: currentFilters?.aideScheduleTime
        ? !currentFilters?.aideScheduleTime?.find(
            (item) => item.valueOf() === TIME_OPTION_UNKNOWN.value,
          )
        : !!currentFilters?.aideScheduleDay?.length || undefined,
      hasContactInfo: currentFilters?.contactTime
        ? !currentFilters?.contactTime?.find(
            (item) => item.valueOf() === TIME_OPTION_UNKNOWN.value,
          )
        : !!currentFilters?.contactDay?.length || undefined,
      hasLanguage: currentFilters?.language
        ? !currentFilters?.language?.find(
            (item) => item.valueOf() === UNASSIGNED.value,
          )
        : undefined,
      hasRiskLevel: currentFilters?.riskLevel
        ? !currentFilters?.riskLevel?.find(
            (item) => item.valueOf() === RISK_LEVEL_OPTION_NONE_VALUE,
          )
        : undefined,
      insurance: insurances
        ?.filter(({ value }) =>
          currentFilters?.insurance?.includes(value as string),
        )
        ?.map(({ label }) => label as string),
      language: currentFilters?.language?.filter(
        (value) => !!Language.byKey[value.valueOf()],
      ),
      referralSource: getReferralsWithChildren(
        referralSources
          ?.filter(({ value }) =>
            currentFilters?.referralSource?.includes(value as string),
          )
          ?.map(({ info }) => info as Organization),
      )?.map(({ name }) => name),
      riskLevel: currentFilters?.riskLevel?.filter(
        (item) => item.valueOf() !== RISK_LEVEL_OPTION_NONE_VALUE,
      ),
      lastReadingDateFrom: !isNaN(lastReadingDate)
        ? moment().subtract(lastReadingDate, 'days')
        : currentFilters.lastReadingDateFrom,
      lastReadingDateTo: !isNaN(lastReadingDate)
        ? undefined
        : currentFilters.lastReadingDateTo,
      rpmStartDateFrom: !isNaN(rpmStartDate)
        ? moment().subtract(rpmStartDate, 'days')
        : currentFilters.rpmStartDateFrom,
      rpmStartDateTo: !isNaN(rpmStartDate)
        ? undefined
        : currentFilters.rpmStartDateTo,
    };
    return currentFilters;
  };

  const getCCMMembers = async () => {
    const offset = page * pageSize;
    if (offset !== 0 && offset < ccmMembers?.length) return;

    setLoading(true);

    const currentFilters = prepareFilters();

    CCMDashboardLastFilters = JSON.stringify({
      ...currentFilters,
    });

    try {
      const { items, pagination, lastUpdatedAt } = await fecthCCMMembers({
        ...currentFilters,
        offset,
        limit: pageSize,
        sort,
      });
      if (CCMDashboardLastFilters === JSON.stringify({ ...currentFilters })) {
        if (page > 0) {
          setCCMMembers([...ccmMembers, ...items]);
        } else {
          setCCMMembers(items);
        }
        setLastUpdatedAt(lastUpdatedAt);
        setPagination(pagination);
      }
    } catch (e) {
      showGlobalError(e as string);
    }

    if (selectedTab === CCM_TOGGLE_ITEM_CCM) {
      setLoading(false);
    }
  };

  const getRPMMembers = async () => {
    const offset = page * pageSize;
    if (offset !== 0 && offset < rpmMembers?.length) return;

    setLoading(true);

    const currentFilters = prepareFilters();

    CCMDashboardLastFilters = JSON.stringify({
      ...currentFilters,
    });
    try {
      const { items, pagination, lastUpdatedAt } = await fecthRPMMembers({
        ...currentFilters,
        offset,
        limit: pageSize,
        sort,
      });

      if (CCMDashboardLastFilters === JSON.stringify({ ...currentFilters })) {
        if (page > 0) {
          setRPMMembers([...rpmMembers, ...items]);
        } else {
          setRPMMembers(items);
        }
        setLastUpdatedAt(lastUpdatedAt);
        setPagination(pagination);
      }
    } catch (e) {
      showGlobalError(e as string);
    }
    if (selectedTab === CCM_TOGGLE_ITEM_RPM) {
      setLoading(false);
    }
  };

  const getBHIMembers = async () => {
    const offset = page * pageSize;
    if (offset !== 0 && offset < rpmMembers?.length) return;

    setLoading(true);

    const currentFilters = prepareFilters();

    CCMDashboardLastFilters = JSON.stringify({
      ...currentFilters,
    });
    try {
      const { items, pagination, lastUpdatedAt } = await fecthBHIMembers({
        ...currentFilters,
        offset,
        limit: pageSize,
        sort,
      });
      if (CCMDashboardLastFilters === JSON.stringify({ ...currentFilters })) {
        if (page > 0) {
          setBHIMembers([...bhiMembers, ...items]);
        } else {
          setBHIMembers(items);
        }
      }

      setLastUpdatedAt(lastUpdatedAt);
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const getEncounterChannel = (type?: EncounterType) => {
    if (type === EncounterType.RPM) return EncounterChannel.RPM_ADHERENCE;
    if (type === EncounterType.CCM) return EncounterChannel.CCM_VISIT;
  };

  const storedFiltersConfiguration = useMemo(() => {
    if (
      brands?.length &&
      carePlanGroups?.length &&
      employees?.length &&
      employeeGroups?.length &&
      languages?.length &&
      programExtensions?.length &&
      programExtensionStatuses?.length &&
      reasons?.length &&
      referralSources?.length
    ) {
      return {
        aideScheduleDay: {
          data: Enum.toSelectable(DayOfWeek.asArray),
          label: translate('ccm.common.aideScheduleDays'),
          multiple: true,
        },
        aideScheduleTime: {
          data: Enum.toSelectable(MemberContactTimeType.asArray),
          label: translate('ccm.common.aideScheduleTime'),
          multiple: true,
        },
        bhiMinutesFrom: { label: translate('ccm.common.bhiMinutesFrom') },
        bhiMinutesTo: { label: translate('ccm.common.bhiMinutesTo') },
        brandId: {
          data: brands,
          label: translate('ccm.common.brand'),
          multiple: true,
        },
        careCoordinatorId: {
          data: employees,
          label: translate('ccm.common.clinicalAssistant'),
          multiple: true,
        },
        carePlanDateFrom: { label: translate('ccm.common.lastCarePlan') },
        carePlanDue: { label: translate('ccm.common.carePlanDue') },
        carePlanGroupId: {
          data: carePlanGroups,
          label: translate('ccm.common.carePlanGroup'),
          multiple: true,
        },
        careTeamDiscoveryDue: {
          label: translate('ccm.common.careTeamDiscovery'),
        },
        ccmCallStatus: {
          data: CCM_STATUSES,
          label: translate('ccm.common.ccmStatus'),
          multiple: true,
        },
        contactDay: {
          data: Enum.toSelectable(DayOfWeek.asArray),
          label: translate('ccm.common.contactPreferenceDate'),
          multiple: true,
        },
        contactTime: {
          data: Enum.toSelectable(MemberContactTimeType.asArray),
          label: translate('ccm.common.contactPreferenceTime'),
          multiple: true,
        },
        engagementOwnerId: {
          data: employees,
          label: translate('ccm.common.engagementOwner'),
          multiple: true,
        },
        excludedProgramExtensionId: {
          data: ProgramExtension.toSelectable(programExtensions),
          default: [
            ProgramExtension.MEDICAID,
            ProgramExtension.TCM,
            ProgramExtension.VESTA_LITE,
          ],
          label: translate('ccm.common.excludedprogramExtensions'),
          multiple: true,
        },
        excludedReferralSource: {
          data: referralSources,
          label: translate('ccm.common.excludedReferralSources'),
          multiple: true,
        },
        hasProfessionalTime: {
          label: translate('ccm.common.hasProfessionalTime'),
        },
        hasProfessionalTimeOrTouchedByNp: {
          label: translate('ccm.common.hasProfessionalTimeOrTouchedByNp'),
        },
        healthCoachId: {
          data: employees,
          label: translate('ccm.common.healthCoach'),
          multiple: true,
        },
        isBiannualComplete: {
          label: translate('ccm.common.bianualVVStatus'),
        },
        isComplete: { label: translate('ccm.common.monthlyCCMStatus') },
        initialCPDue: { label: translate('ccm.common.initialCP') },
        isUnreachable: {
          label: translate('ccm.common.unreachable'),
          default: false,
        },
        insurance: {
          data: insurances,
          label: translate('ccm.common.unreachable'),
          multiple: true,
        },
        language: {
          data: languages,
          label: translate('ccm.common.language'),
          multiple: true,
        },
        memberIds: { label: translate('ccm.common.memberId') },
        memberName: { label: translate('ccm.common.memberName') },
        npOwnerId: {
          data: employees,
          label: translate('ccm.common.npOwner'),
          multiple: true,
        },
        numberOfCalls: { label: translate('ccm.common.attempts') },

        programExtensionFilter: {
          label: translate('podReporting.filters.programExtensions'),
          loader: dataUtils.programExtensions.loader(
            programExtensions,
            programExtensionStatuses,
          ),
          valueLabel: dataUtils.programExtensions.labeler,
          multiple: true,
        },
        referralSource: {
          data: referralSources,
          label: translate('ccm.common.referralSources'),
          multiple: true,
        },
        riskLevel: {
          data: Enum.toSelectable(RiskLevel.asArray),
          label: translate('ccm.common.riskLevel'),
          multiple: true,
        },
        rpmAdherenceId: {
          data: Enum.toSelectable(RPMAdherenceLevel.asArray),
          label: translate('ccm.common.rpmAdherence'),
          multiple: true,
        },
        rpmMinutesFrom: { label: translate('ccm.common.rpmMinutesFrom') },
        rpmMinutesTo: { label: translate('ccm.common.rpmMinutesTo') },
        recentCyclesMetCount: {
          data: RECENT_CYCLES_OPTIONS,
          label: translate('ccm.common.recentCycles'),
          multiple: true,
        },
        lastReadingDateFrom: {
          label: translate('ccm.common.lastReadingDateFrom'),
        },
        lastReadingDateTo: {
          label: translate('ccm.common.lastReadingDateTo'),
        },
        lastReadingDate: {
          label: translate('ccm.common.lastReading'),
          valueLabel: (option: number) => `Last ${option} days`,
        },
        podManagerId: {
          data: employees,
          label: translate('ccm.common.podManager'),
          multiple: true,
        },
        rpmStartDateFrom: { label: translate('ccm.common.rpmStartDateFrom') },
        rpmStartDateTo: { label: translate('ccm.common.rpmStartDateTo') },
        rpmStartDate: {
          label: translate('ccm.common.rpmStartDate'),
          valueLabel: (option: number) => `Last ${option} days`,
        },
        rnOwnerId: {
          data: employees,
          label: translate('ccm.common.rnOwner'),
          multiple: true,
        },
        startDateFrom: {
          data: VESTA_START_DATE_OPTIONS,
          label: `${translate('ccm.common.vestaStartDate')} ${translate(
            'common.after',
          )}`,
        },
        startDateTo: {
          data: VESTA_START_DATE_OPTIONS,
          label: `${translate('ccm.common.vestaStartDate')} ${translate(
            'common.before',
          )}`,
        },
        state: {
          data: Enum.toSelectable(States.asArray),
          label: translate('ccm.common.state'),
          multiple: true,
        },
        touchedByNp: {
          label: translate('ccm.common.touchedByNP'),
        },
        totalMinutesFrom: { label: translate('ccm.common.totalMinutesFrom') },
        totalMinutesTo: { label: translate('ccm.common.totalMinutesTo') },
        touchPointReason: {
          data: reasons,
          label: translate('ccm.common.touchpointReasons'),
          multiple: true,
        },
        worklistGroupId: {
          data: employeeGroups,
          label: translate('ccm.common.employeeGroup'),
          multiple: true,
        },
      } as {
        [x in
          | KeyGetCCMParams
          | 'selectedTab'
          | 'rpmStartDate'
          | 'lastReadingDate']: LoadFilterOption;
      };
    }
  }, [
    brands,
    carePlanGroups,
    employees,
    employeeGroups,
    languages,
    programExtensions,
    reasons,
    referralSources,
  ]);

  useEffect(() => {
    if (storedFiltersConfiguration) {
      const clearFilters = query.get('clearFilters');
      const storedFilters = loadNewFiltersFromStorage(
        clearFilters ? '' : LS_DASHBOARD_CCM_FILTERS_V2,
        storedFiltersConfiguration,
        query,
      );

      setNewFilters(storedFilters);
      if (!clearFilters) {
        setDefaultProgramExtensions(storedFilters);
      }
    }
  }, [storedFiltersConfiguration]);

  useEffect(() => {
    const clearFilters = query.get('clearFilters');
    const { selectedTab } = loadNewFiltersFromStorage(
      clearFilters ? '' : LS_DASHBOARD_CCM_FILTERS_V2,
      {
        selectedTab: { label: '' },
      },
      query,
    );

    setSelectedTab(
      ((selectedTab?.value as FilterSelectable)?.value as string) ||
        CCM_TOGGLE_ITEM_CCM,
    );
  }, []);

  useEffect(() => {
    if (newFilters && selectedTab) {
      history.replace('?');
      saveNewFiltersToStorage(
        LS_DASHBOARD_CCM_FILTERS_V2,
        {
          ...newFilters,
          selectedTab: {
            label: '',
            value: { label: '', value: selectedTab },
          },
        },
        {
          programExtensionFilter: dataUtils.programExtensions.parser,
        },
      );
    }
  }, [newFilters, selectedTab]);

  const setDefaultProgramExtensions = (filters?: {
    [x: string]: FilterItem;
  }) => {
    const currentFilters = filters || newFilters;
    if (!selectedTab || !programExtensions?.length || !currentFilters) return;

    if (selectedTab === CCM_TOGGLE_ITEM_RPM && programExtensions) {
      const rpm = programExtensions.find(
        ({ id }) => id === ProgramExtension.RPM,
      );
      const activeStatus = programExtensionStatuses.filter(
        ({ id }) => id === ProgramExtensionStatus.ACTIVE,
      );
      setNewFilters({
        ...currentFilters,
        programExtensionFilter: {
          label: translate('ccm.common.programExtensions'),
          value: [
            {
              label: 'RPM [Active]',
              value: {
                programExtension: rpm,
                programExtensionStatus: activeStatus,
              },
            },
          ],
        },
      });
    } else if (selectedTab === CCM_TOGGLE_ITEM_BHI && programExtensions) {
      const bhi = programExtensions.find(
        ({ id }) => id === ProgramExtension.BHI,
      );
      const activeStatus = programExtensionStatuses.filter(
        ({ id }) => id === ProgramExtensionStatus.ACTIVE,
      );
      setNewFilters({
        ...currentFilters,
        programExtensionFilter: {
          label: translate('ccm.common.programExtensions'),
          value: [
            {
              label: 'BHI [Active]',
              value: {
                programExtension: bhi,
                programExtensionStatus: activeStatus,
              },
            },
          ],
        },
      });
    } else if (!filters) {
      setNewFilters({
        ...currentFilters,
        programExtensionFilter: undefined,
      });
    }

    setLoading(true);
    setPage(0);
    setCCMMembers([]);
    setRPMMembers([]);
    setBHIMembers([]);
  };

  useEffect(() => {
    setDefaultProgramExtensions();
  }, [selectedTab, programExtensions]);

  useEffect(() => {
    getListItems();
  }, [JSON.stringify(newFilters), sort, page, pageSize]);

  const selectedMembers = selectAll
    ? (pagination?.total || 0) - selectedIds.length
    : selectedIds.length;

  const getMoreEvents = async () => {
    if (infoMember) {
      try {
        const { items } = await fecthMemberDashboardEvents(
          infoMember.memberId,
          {
            offset: MAX_DASHBOARD_TABLE_ITEMS,
            limit: infoDetailEvents?.pagination?.total || 100,
          },
        );
        setInfoDetailEvents({
          items: [...(infoDetailEvents?.items || []), ...items],
          pagination: infoDetailEvents?.pagination || ({} as PaginationType),
        });
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  const getMoreTasks = async () => {
    if (infoMember) {
      try {
        const { items } = await fecthMemberDashboardTasks(infoMember.memberId, {
          offset: MAX_DASHBOARD_TABLE_ITEMS,
          limit: infoDetailTasks?.pagination?.total || 100,
        });
        setInfoDetailTasks({
          items: [...(infoDetailTasks?.items || []), ...items],
          pagination: infoDetailTasks?.pagination || ({} as PaginationType),
        });
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  const getFilterBar = (tab?: string) => {
    if (tab === CCM_TOGGLE_ITEM_BHI)
      return (
        <BHIFiltersBar
          data={{
            languages,
            programExtensions,
          }}
          filters={newFilters}
          loading={loadingFilters}
          onChange={(newFilters) => {
            setPage(0);
            setNewFilters(newFilters);
          }}
        />
      );
    if (tab === CCM_TOGGLE_ITEM_RPM)
      return (
        <RPMFiltersBar
          data={{
            languages,
          }}
          filters={newFilters}
          loading={loadingFilters}
          onChange={(newFilters) => {
            setPage(0);
            setNewFilters(newFilters);
          }}
        />
      );

    return (
      <CCMFiltersBar
        data={{
          languages,
          programExtensions,
          worklistGroups: employeeGroups,
        }}
        loading={loadingFilters}
        filters={newFilters}
        onChange={(newFilters) => {
          setPage(0);
          setNewFilters(newFilters);
        }}
      />
    );
  };

  return (
    <Panel>
      <Panel.Heading title={translate('ccm.title')} filtersV2>
        <Panel.Filters className="flex gap">
          <ToggleButton
            className={styles.toggle}
            items={CCM_TOGGLE_ITEMS.filter(
              (item) => showBhi || item.value !== CCM_TOGGLE_ITEM_BHI,
            )}
            onChange={setSelectedTab}
            value={selectedTab}
          />
        </Panel.Filters>
        <Panel.Actions>
          {lastUpdatedAt && (
            <LastUpdated
              lastUpdatedAt={lastUpdatedAt}
              tooltip={translate('toc.actions.lastUpdatedTooltip')}
            />
          )}
          {Session.actingUser.canExportClinicalDashboard && (
            <IconButton
              data-cy="member-export"
              tooltip={translate('ccm.actions.download')}
              loading={loadingDownload}
              onClick={async () => {
                setLoadingDownload(true);
                try {
                  if (selectedTab === CCM_TOGGLE_ITEM_RPM) {
                    await downloadRPMDashboard(prepareFilters() || {});
                  } else {
                    await downloadCCMDashboard(prepareFilters() || {});
                  }
                } catch (e) {
                  showGlobalError(e as string);
                }
                setLoadingDownload(false);
              }}
            >
              <DownloadIcon />
            </IconButton>
          )}
          <IconButton
            data-cy="ccm-button"
            disabled={!selectedIds.length && !selectAll}
            onClick={() => setOpenAssignModal(true)}
            tooltip={translate('ccm.actions.ccm')}
          >
            <CCMIcon />
          </IconButton>
        </Panel.Actions>
        <Panel.FilterBar
          onClearFilters={() => {
            setPage(0);
            setNewFilters({
              isUnreachable: newFilters?.isUnreachable,
            });
          }}
          onDeleteFilter={(key: string) => {
            setPage(0);
            setNewFilters({
              ...newFilters,
              [key]: undefined,
            });
          }}
          onOpenFilters={() => setOpenFilters(!openFilters)}
          onSelectFilter={(filter) =>
            storedFiltersConfiguration &&
            setNewFilters(
              loadNewFilters(filter.filters, storedFiltersConfiguration),
            )
          }
          chips={{
            ...newFilters,
            isUnreachable: newFilters?.isUnreachable
              ? ({
                  ...newFilters.isUnreachable,
                  readonly: true,
                } as FilterItem & { readonly: boolean })
              : undefined,
          }}
          inputs={getFilterBar(selectedTab)}
          storedFilters={storedFilters}
        />
        <Panel.Tabs className="flex spaced buttons">
          <span>
            {translate('ccm.selectedItems', {
              count: selectedMembers,
            })}
          </span>
          <div className="flex gap">
            <IconButton
              data-cy="ccm-selection-button"
              disabled={selectAll}
              onClick={() => {
                setSelectedIds([]);
                setSelectAll(!selectAll);
              }}
              tooltip={translate('ccm.actions.selection')}
            >
              <CustomSelectionIcon />
            </IconButton>
            <IconButton
              data-cy="ccm-clear-button"
              disabled={!selectedIds?.length && !selectAll}
              onClick={() => {
                setSelectedIds([]);
                setSelectAll(false);
              }}
              tooltip={translate('ccm.actions.clear')}
            >
              <ClearIcon />
            </IconButton>
          </div>
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body loading={loading} fixedTableHeader>
        <CCMFiltersModal
          data={{
            brands,
            carePlanGroups,
            employees,
            insurances,
            languages,
            programExtensions,
            reasons,
            referralSources,
            worklistGroups: employeeGroups,
          }}
          filters={newFilters}
          loadingFilters={loadingFilters}
          onChange={(newFilters) => {
            setPage(0);
            setNewFilters(newFilters);
          }}
          onClose={() => setOpenFilters(false)}
          onDeleteFilter={async (filter) => {
            await deleteSelfStoredFilter(filter.id);
            dispatch(onStoredFilterDeleted(filter));
          }}
          onEditFilter={async (filter) => {
            await updateSelfStoredFilter(filter.id, filter);
            dispatch(onStoredFilterChange(filter));
          }}
          onSaveFilter={async (filter) => {
            if (filter.id) {
              await updateSelfStoredFilter(filter.id, {
                filters: saveNewFilters(newFilters || {}),
              });
              dispatch(onStoredFilterChange(filter));
            } else if (newFilters && selectedTab) {
              const updated = await createSelfStoredFilter({
                description: filter.description,
                filters: saveNewFilters(newFilters || {}),
                page: CCM_PAGE_FILTER,
                preferred: false,
              });
              dispatch(onStoredFilterAdd(updated));
            }
          }}
          open={openFilters}
          storedFilters={storedFilters}
        />
        {selectedTab === CCM_TOGGLE_ITEM_BHI && (
          <BHITable
            allChecked={
              !!(
                rpmMembers?.length &&
                rpmMembers?.every((member) =>
                  selectedIds.includes(member.memberId),
                )
              )
            }
            blacklist={selectAll}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSort={sort}
            items={bhiMembers}
            onChangePage={setPage}
            onChangePageSize={(ps) => {
              setPage(0);
              setPageSize(ps);
            }}
            onClickRow={async (member) => {
              if (!member) return;

              setLoading(true);

              const detail = await getMemberInfoData(member?.memberId);
              setInfoMember(member);
              setInfoDetail(detail);
              setInfoDetailEvents(detail.recentEvents);
              setInfoDetailTasks(detail.recentTasks);

              setLoading(false);
            }}
            onDefaultSort={() => {
              setPage(0);
              setSort(DEFAULT_SORTING);
            }}
            onLogCall={(member) => {
              setModalData(member);
              setOpenLogCallModal(EncounterType.BHI);
            }}
            onSelectAll={(checked) => {
              const ids = rpmMembers?.map((member) => member.memberId) || [];
              if (!ids.length) return;

              setSelectedIds(
                checked
                  ? selectedIds.filter((id) => !ids.includes(id))
                  : uniq([...selectedIds, ...ids]),
              );
            }}
            onSelectMember={(member) => {
              const idx = selectedIds?.findIndex(
                (id) => id === member.memberId,
              );
              if (idx === -1) {
                selectedIds.push(member.memberId);
              } else {
                selectedIds.splice(idx, 1);
              }
              setSelectedIds([...selectedIds]);
            }}
            onSort={(field, direction) => {
              setPage(0);
              setSort(`${field} ${direction}`);
            }}
            pagination={pagination}
            selectedIds={selectedIds}
          />
        )}
        {selectedTab === CCM_TOGGLE_ITEM_RPM && (
          <RPMTable
            allChecked={
              !!(
                rpmMembers?.length &&
                rpmMembers?.every((member) =>
                  selectedIds.includes(member.memberId),
                )
              )
            }
            blacklist={selectAll}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSort={sort}
            items={rpmMembers}
            onChangePage={setPage}
            onChangePageSize={(ps) => {
              setPage(0);
              setPageSize(ps);
            }}
            onClickRow={async (member) => {
              if (!member) return;

              setLoading(true);

              const detail = await getMemberInfoData(member?.memberId);
              setInfoMember(member);
              setInfoDetail(detail);
              setInfoDetailEvents(detail.recentEvents);
              setInfoDetailTasks(detail.recentTasks);

              setLoading(false);
            }}
            onDefaultSort={() => {
              setPage(0);
              setSort(DEFAULT_SORTING);
            }}
            onLogCall={(member) => {
              setModalData(member);
              setOpenLogCallModal(EncounterType.RPM);
            }}
            onSelectAll={(checked) => {
              const ids = rpmMembers?.map((member) => member.memberId) || [];
              if (!ids.length) return;

              setSelectedIds(
                checked
                  ? selectedIds.filter((id) => !ids.includes(id))
                  : uniq([...selectedIds, ...ids]),
              );
            }}
            onSelectMember={(member) => {
              const idx = selectedIds?.findIndex(
                (id) => id === member.memberId,
              );
              if (idx === -1) {
                selectedIds.push(member.memberId);
              } else {
                selectedIds.splice(idx, 1);
              }
              setSelectedIds([...selectedIds]);
            }}
            onSort={(field, direction) => {
              setPage(0);
              setSort(`${field} ${direction}`);
            }}
            pagination={pagination}
            selectedIds={selectedIds}
          />
        )}
        {selectedTab === CCM_TOGGLE_ITEM_CCM && (
          <CCMTable
            allChecked={
              !!(
                ccmMembers?.length &&
                ccmMembers?.every((member) =>
                  selectedIds.includes(member.memberId),
                )
              )
            }
            blacklist={selectAll}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSort={sort}
            items={ccmMembers}
            onChangePage={setPage}
            onChangePageSize={(ps) => {
              setPage(0);
              setPageSize(ps);
            }}
            onClickRow={async (member) => {
              if (!member) return;

              setLoading(true);

              const detail = await getMemberInfoData(member?.memberId);
              setInfoMember(member);
              setInfoDetail(detail);
              setInfoDetailEvents(detail.recentEvents);
              setInfoDetailTasks(detail.recentTasks);

              setLoading(false);
            }}
            onDefaultSort={() => {
              setPage(0);
              setSort(DEFAULT_SORTING);
            }}
            onLogCall={(member) => {
              setModalData(member);
              setOpenLogCallModal(EncounterType.CCM);
            }}
            onSelectAll={(checked) => {
              const ids = ccmMembers?.map((member) => member.memberId) || [];
              if (!ids.length) return;

              setSelectedIds(
                checked
                  ? selectedIds.filter((id) => !ids.includes(id))
                  : uniq([...selectedIds, ...ids]),
              );
            }}
            onSelectMember={(member) => {
              const idx = selectedIds?.findIndex(
                (id) => id === member.memberId,
              );
              if (idx === -1) {
                selectedIds.push(member.memberId);
              } else {
                selectedIds.splice(idx, 1);
              }
              setSelectedIds([...selectedIds]);
            }}
            onSort={(field, direction) => {
              setPage(0);
              setSort(`${field} ${direction}`);
            }}
            pagination={pagination}
            selectedIds={selectedIds}
          />
        )}
        <AssignWorklistModal
          open={openAssignModal}
          onClose={() => setOpenAssignModal(false)}
          onSubmit={async (params, group) => {
            let result = true;
            try {
              if (selectAll) {
                await assignToGroupWorklist({
                  ...params,
                  filter: {
                    excludedMemberIds: selectedIds,
                    ...prepareFilters(),
                  },
                });
              } else {
                await assignToGroupWorklist({
                  includedMemberIds: selectedIds,
                  ...params,
                });
              }

              new Toast({
                autoClose: 5000,
                position: 'bottom-right',
                title: translate('ccm.info.membersAssignedTitle'),
                body: group.value
                  ? translate('ccm.info.membersAssignedBody', {
                      count: selectedMembers,
                      group: group.label,
                    })
                  : translate('ccm.info.membersUnassignedBody', {
                      count: selectedMembers,
                    }),
                type: 'info',
              });

              setSelectedIds([]);
              setSelectAll(false);
              await resetCCMMembers();
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          totalMembers={selectedMembers}
        />
        <InfoCCMModal
          detail={infoDetail}
          events={infoDetailEvents}
          getMoreEvents={getMoreEvents}
          getMoreTasks={getMoreTasks}
          member={infoMember}
          open={!!infoMember}
          onClose={() => setInfoMember(undefined)}
          onLogCall={(member, encounterType) => {
            setModalData(member);
            setOpenLogCallModal(encounterType);
          }}
          tasks={infoDetailTasks}
        />

        <AddEncounterModal
          autofillTime
          avoidRequiredMinutes
          className={styles.modal}
          channel={getEncounterChannel(openLogCallModal)}
          direction={EncounterDirection.OUTBOUND}
          method={EncounterMethod.CALL}
          type={openLogCallModal}
          onClose={() => setOpenLogCallModal(undefined)}
          onSubmit={async () => {
            await resetCCMMembers();
            setOpenLogCallModal(undefined);
          }}
          open={!!openLogCallModal}
          patient={modalData?.memberId}
        />
      </Panel.Body>
    </Panel>
  );
};

export default CCMDashboard;
