import { MemberProgramExtension } from '../MemberProgramExtension';
import { ProgramExtension } from '../ProgramExtension';
import { ProgramExtensionStatus } from '../ProgramExtensionStatus';

const hasProgramExtension = (
  peId: number | number[],
  programExtensions?: MemberProgramExtension[],
) =>
  programExtensions?.find(
    ({ programExtension: { id }, status }) =>
      (peId instanceof Array ? peId.includes(id) : peId === id) &&
      status?.id !== ProgramExtensionStatus.DISENROLLED,
  );
export interface VestaCoreLiteHelper {
  programExtensions?: MemberProgramExtension[];
  isVPC: () => false | MemberProgramExtension;
  isVPCLite: () => false | MemberProgramExtension;
  isVestaCoreLite: () => false | MemberProgramExtension;
}

export const isVestaCoreLiteHelper = function testType(
  arg: any,
): arg is VestaCoreLiteHelper {
  return arg && arg.programExtensions && arg.isVPC;
};

export const isVPC = ({ programExtensions }: VestaCoreLiteHelper) => {
  const ccm = hasProgramExtension(ProgramExtension.CCM, programExtensions);
  const vpc = hasProgramExtension(ProgramExtension.VPC, programExtensions);

  return (vpc && ccm) || false;
};

export const isVPCLite = ({ programExtensions }: VestaCoreLiteHelper) => {
  const ccm = hasProgramExtension(ProgramExtension.CCM, programExtensions);
  const vpc = hasProgramExtension(ProgramExtension.VPC, programExtensions);

  return (!ccm && vpc) || false;
};

export const isVestaCoreLite = ({
  programExtensions,
}: VestaCoreLiteHelper): false | MemberProgramExtension => {
  const pe = hasProgramExtension(
    [ProgramExtension.VESTA_CORE, ProgramExtension.VESTA_LITE],
    programExtensions,
  );

  return pe?.status?.id === ProgramExtensionStatus.ACTIVE ? pe : false;
};
