import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberProgramExtension,
  ProgramExtension,
} from '@vestahealthcare/common/models';
import {
  PatientBrandHelper,
  isPatientBrandHelper,
} from '@vestahealthcare/common/models/Helpers/PatientBrandHelper';
import {
  VestaCoreLiteHelper,
  isVestaCoreLiteHelper,
} from '@vestahealthcare/common/models/Helpers/VestaCoreLiteHelper';

import { Colors, Fonts } from 'styleguide-v2';
import { BrandColors } from 'styleguide-v2/src/styles/Colors';

import { capitalize } from 'lodash';

type Props = {
  className?: string;
  member?: VestaCoreLiteHelper | PatientBrandHelper;
};

const useStyles = makeStyles({
  bkgCAH: {
    backgroundColor: BrandColors.careAtHome.headerBackground,
    color: BrandColors.careAtHome.textColor,
  },
  bkgCore: {
    backgroundColor: `${Colors.gold}26`,
    color: Colors.textBlack,
  },
  bkgLite: {
    backgroundColor: `${Colors.textRed2}26`,
    color: Colors.textRed2,
  },
  bkgVPC: {
    backgroundColor: `${Colors.purple}26`,
    color: Colors.purple,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '3.2rem',
    justifyContent: 'center',
    width: '100%',
  },
  text: {
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
    letterSpacing: 'initial',
    textTransform: 'none',
  },
});

export const BrandBanner = ({ className, member }: Props) => {
  const styles = useStyles();
  const peTag =
    isVestaCoreLiteHelper(member) &&
    (member.isVestaCoreLite() || member.isVPC() || member.isVPCLite());
  const brandTag = isPatientBrandHelper(member) && member.isBrandCareAtHome();

  const showBanner = brandTag || peTag;
  let bannerText = '';

  if (showBanner) {
    if (brandTag) {
      bannerText = translate('components.BrandBanner.label', {
        brand: member.brand?.value
          .replaceAll('_', ' ')
          .split(' ')
          .map(capitalize)
          .join(' '),
      });
    } else if (peTag) {
      if (member.isVestaCoreLite()) {
        bannerText = translate('components.BrandBanner.label', {
          brand: peTag.programExtension.name,
        });
      } else if (member.isVPC()) {
        bannerText = translate('components.BrandBanner.labelVPCVesta');
      } else {
        bannerText = translate('components.BrandBanner.label', {
          brand: 'Vesta Primary Care',
        });
      }
    }
  }

  return member && showBanner ? (
    <div
      className={classNames(
        className,
        styles.container,
        brandTag && styles.bkgCAH,
        peTag &&
          peTag.programExtension.id === ProgramExtension.VESTA_CORE &&
          styles.bkgCore,
        peTag &&
          peTag instanceof MemberProgramExtension &&
          peTag.programExtension.id === ProgramExtension.VESTA_LITE &&
          styles.bkgLite,
        peTag && (member.isVPC() || member.isVPCLite()) && styles.bkgVPC,
      )}
    >
      <span className={styles.text}>{bannerText}</span>
    </div>
  ) : (
    <></>
  );
};
