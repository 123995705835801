import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import {
  CCMServiceProvider,
  ProgramExtensionStatusReason,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  Patient,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import MemberProgramExtension from '@vestahealthcare/common/models/MemberProgramExtension';
import {
  DATE_FORMAT,
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Button,
  Colors,
  DateTimePicker,
  IconButton,
  Select,
  TextInput,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { fetchPatientWarnings } from 'dash/src/redux/slices/MemberInfoSlice';
import { CacheServices } from 'dash/src/services';
import { updateProgramExtensions } from 'dash/src/services/PatientServices';
import { excludeOtherValues } from 'dash/src/utils/dataUtils';

import { BaseModal } from './BaseModal';

interface Props {
  onSubmit: (patient: Patient) => void;
  patient: Patient;
}

const useStyles = makeStyles({
  addProgramExtensionIcon: {
    marginTop: '-0.25rem',
  },
  addProgramExtensionLink: {
    color: Colors.textGreen,
    fontSize: '0.875em',
    marginLeft: '0.5rem',
    textDecoration: 'underline',
    '&:hover': {
      color: Colors.iconGreen,
    },
  },
  icon: {
    '&&': {
      margin: '0.375rem auto auto',
    },
  },
  firstIcon: {
    '&&': {
      margin: '2.375rem auto auto',
    },
  },
  marginDate: {
    marginTop: '0.5rem',
  },
});

export const EditProgramExtensions = ({ patient, onSubmit }: Props) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [programExtensions, setProgramExtensions] = useState(
    patient.programExtensions?.length
      ? patient.programExtensions
      : [new MemberProgramExtension({})],
  );
  const [allProgramExtensions, setAllProgramExtensions] = useState<
    ProgramExtension[]
  >([]);
  const [submitted, setSubmitted] = useState(false);

  const fetchData = async () => {
    const [allProgramExtensions] = await Promise.all([
      CacheServices.getAllProgramExtensions(),
    ]);
    setAllProgramExtensions(allProgramExtensions);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const resetPEs = () => {
    setProgramExtensions(
      patient.programExtensions?.length
        ? patient.programExtensions.map((item) => ({ ...item }))
        : [new MemberProgramExtension({})],
    );
  };

  useEffect(() => {
    resetPEs();
  }, [patient.id, JSON.stringify(patient.programExtensions)]);

  const renderDetails = () => {
    if (!patient.programExtensions.length) {
      return EMPTY;
    }

    const sortedProgramExtensions = [
      ...patient.programExtensions,
    ].sort((a: MemberProgramExtension, b: MemberProgramExtension): number =>
      a.programExtension?.name.localeCompare(b.programExtension?.name),
    );

    return sortedProgramExtensions.map((proExt, index) => (
      <p
        className="no-margin"
        key={proExt.programExtension.id}
        style={{
          lineHeight: '12px',
          paddingTop: index === 0 ? '0.25rem' : undefined,
        }}
      >
        <span>{proExt.programExtension.name}</span>{' '}
        {proExt.status && proExt.status?.id === ProgramExtensionStatus.HOLD ? (
          <span className="size-xs">
            [
            {[
              proExt.status?.description,
              proExt.statusReason?.toString(),
              proExt.statusReason === ProgramExtensionStatusReason.OTHER
                ? proExt.statusReasonOther
                : undefined,
              proExt.releaseDate
                ? moment(proExt.releaseDate, DATE_FORMAT).format(
                    DATE_FORMAT_SHORT,
                  )
                : undefined,
              proExt.serviceProvider?.toString(),
            ]
              .filter(Boolean)
              .join(' - ')}
            ]
          </span>
        ) : (
          <span className="size-xs">
            [
            {[proExt.status?.description, proExt.serviceProvider?.toString()]
              .filter(Boolean)
              .join(' - ')}
            ]
          </span>
        )}
      </p>
    ));
  };

  const checkUpdatedPEs = () => {
    const oldCCM = patient.programExtensions?.find(
      ({ programExtension }) => programExtension.id === ProgramExtension.CCM,
    );
    const oldRPM = patient.programExtensions?.find(
      ({ programExtension }) => programExtension.id === ProgramExtension.RPM,
    );
    const newCCM = programExtensions?.find(
      ({ programExtension }) => programExtension.id === ProgramExtension.CCM,
    );
    const newRPM = programExtensions?.find(
      ({ programExtension }) => programExtension.id === ProgramExtension.RPM,
    );

    if (!oldCCM !== !newCCM || oldCCM?.status !== newCCM?.status) return true;
    if (!oldRPM !== !newRPM || oldRPM?.status !== newRPM?.status) return true;

    return false;
  };

  const validatePE = ({
    programExtension,
    status,
    statusReason,
    statusReasonOther,
  }: MemberProgramExtension) =>
    !programExtension?.id ||
    !status ||
    (status?.id === ProgramExtensionStatus.HOLD && !statusReason) ||
    (status?.id === ProgramExtensionStatus.HOLD &&
      statusReason === ProgramExtensionStatusReason.OTHER &&
      !statusReasonOther);

  const validate = () =>
    programExtensions?.length > 1
      ? programExtensions.find(validatePE)
      : validatePE(programExtensions[0]);

  const submit = async () => {
    setSubmitted(true);
    if (validate()) {
      return false;
    }

    try {
      await updateProgramExtensions(
        patient.id,
        programExtensions.map(
          ({
            programExtension,
            releaseDate,
            serviceProvider,
            status,
            statusReason,
            statusReasonOther,
          }) => ({
            programExtension,
            status,
            releaseDate:
              status?.id === ProgramExtensionStatus.HOLD && releaseDate
                ? moment(releaseDate, DATE_FORMAT)?.format(DATE_FORMAT)
                : undefined,
            serviceProvider:
              programExtension?.id === ProgramExtension.CCM
                ? serviceProvider?.value || null
                : undefined,
            statusReasonOther:
              status?.id === ProgramExtensionStatus.HOLD &&
              statusReason === ProgramExtensionStatusReason.OTHER
                ? statusReasonOther
                : undefined,
            statusReason:
              status?.id === ProgramExtensionStatus.HOLD
                ? statusReason?.value
                : undefined,
          }),
        ),
      );
      if (checkUpdatedPEs()) {
        // TODO: Added timeout because the warning process in the BE is async
        setTimeout(() => {
          dispatch(fetchPatientWarnings(patient.id));
        }, 250);
      }
      await onSubmit(patient);
    } catch (e) {
      showGlobalError(e as string);
    }

    return true;
  };

  const hasVPC = programExtensions.find(
    ({ programExtension, status }) =>
      programExtension?.id === ProgramExtension.VPC &&
      status?.id !== ProgramExtensionStatus.DISENROLLED,
  );

  useEffect(() => {
    if (!hasVPC) {
      const ccm = programExtensions.find(
        ({ programExtension }) => ProgramExtension.CCM === programExtension?.id,
      );
      if (ccm) {
        ccm.serviceProvider = CCMServiceProvider.VESTA;
      }
      setProgramExtensions([...(programExtensions || [])]);
    }
  }, [hasVPC]);

  return (
    <BaseModal
      details={
        <>
          <h4 className="grid-span-4">
            {translate('personalDetails.programExtensions')}
          </h4>
          <p className="grid-span-7" data-cy="program-extension-details">
            {renderDetails()}
          </p>
        </>
      }
      data-cy="program-extension"
      editable={patient.isEditable()}
      onShowModal={() => {
        resetPEs();
        setSubmitted(false);
      }}
      onSubmit={submit}
      title={translate('personalDetails.programExtensions')}
    >
      <div className="grid-wrapper fit">
        {programExtensions?.map((mpe, idx) => (
          <Fragment
            key={`program-extension-${mpe.programExtension?.id || 0}-${idx}`}
          >
            <Select
              className="grid-span-6"
              disableClearable
              error={submitted && !mpe.programExtension}
              getItemLabel={(pe: ProgramExtension) => pe.abbr}
              label={
                idx === 0 ? translate('personalDetails.programExtensions') : ''
              }
              placeholder={translate('global.select')}
              onChange={(programExtension: ProgramExtension) => {
                programExtensions[idx].programExtension = programExtension;
                if (programExtension.id === ProgramExtension.CCM) {
                  programExtensions[idx].serviceProvider =
                    CCMServiceProvider.VESTA;
                }
                setProgramExtensions([...programExtensions]);
              }}
              items={excludeOtherValues(
                allProgramExtensions,
                programExtensions.map(
                  ({ programExtension }) => programExtension,
                ),
                mpe.programExtension,
              )}
              renderOption={(pe: ProgramExtension) => pe.name}
              required
              value={mpe.programExtension}
            />
            <Select
              className="grid-span-5"
              disabled={!mpe.programExtension}
              disableClearable
              error={submitted && !mpe.status}
              getItemLabel={BaseEnum.getLabel}
              label={
                idx === 0
                  ? translate('personalDetails.programExtensionStatus')
                  : ''
              }
              placeholder={translate('personalDetails.peStatus')}
              onChange={(status: ProgramExtensionStatus) => {
                programExtensions[idx].status = status;
                setProgramExtensions([...programExtensions]);
              }}
              items={
                allProgramExtensions
                  ?.find(({ id }) => id === mpe.programExtension?.id)
                  ?.statuses?.sort(ProgramExtensionStatus.sort) || []
              }
              value={mpe.status}
              required
            />
            <IconButton
              className={classNames(
                styles.icon,
                idx === 0 && styles.firstIcon,
                'grid-span-1',
              )}
              disabled={
                programExtensions &&
                programExtensions.length < 2 &&
                !programExtensions[0]?.programExtension &&
                !programExtensions[0]?.status
              }
              size="small"
              onClick={() => {
                if (programExtensions && programExtensions.length < 2) {
                  setProgramExtensions([new MemberProgramExtension({})]);
                } else {
                  programExtensions?.splice(idx, 1);
                  setProgramExtensions([...programExtensions]);
                }
              }}
            >
              <DeleteOutlineIcon fontSize="large" />
            </IconButton>
            {mpe.programExtension?.id === ProgramExtension.CCM && (
              <Select
                className="grid-span-11"
                disabled={!hasVPC}
                getItemLabel={(item: CCMServiceProvider) => item?.toString()}
                placeholder={translate('personalDetails.serviceProvider')}
                onChange={(serviceProvider: CCMServiceProvider) => {
                  programExtensions[idx].serviceProvider = serviceProvider;
                  setProgramExtensions([...programExtensions]);
                }}
                items={CCMServiceProvider.asArray}
                value={mpe.serviceProvider}
              />
            )}
            {mpe.status?.id === ProgramExtensionStatus.HOLD && (
              <>
                <Select
                  className="grid-span-6"
                  error={submitted && !mpe.statusReason}
                  getItemLabel={(item: ProgramExtensionStatusReason) =>
                    item?.toString()
                  }
                  placeholder={translate('personalDetails.statusReason')}
                  onChange={(statusReason: ProgramExtensionStatusReason) => {
                    programExtensions[idx].statusReason = statusReason;
                    setProgramExtensions([...programExtensions]);
                  }}
                  items={ProgramExtensionStatusReason.asArray}
                  value={mpe.statusReason}
                  required
                />
                {mpe.statusReason === ProgramExtensionStatusReason.OTHER && (
                  <TextInput
                    className="grid-span-6"
                    error={submitted && !mpe.statusReasonOther}
                    placeholder={translate('global.other')}
                    onChange={(statusReasonOther?: string) => {
                      programExtensions[idx].statusReasonOther =
                        statusReasonOther || '';
                      setProgramExtensions([...programExtensions]);
                    }}
                    value={mpe.statusReasonOther}
                    required
                  />
                )}
                <DateTimePicker
                  className={classNames('grid-span-6', styles.marginDate)}
                  disabled={
                    mpe.statusReason ===
                    ProgramExtensionStatusReason.DISENROLL_REQUEST
                  }
                  placeholder={translate('personalDetails.releaseDate')}
                  onChange={(releaseDate?: Date) => {
                    programExtensions[idx].releaseDate = releaseDate
                      ? moment(releaseDate.getTime()).format(DATE_FORMAT)
                      : '';
                    setProgramExtensions([...programExtensions]);
                  }}
                  minDate={moment().add(1, 'day').toDate()}
                  value={
                    mpe.statusReason !==
                      ProgramExtensionStatusReason.DISENROLL_REQUEST &&
                    programExtensions[idx].releaseDate
                      ? moment(
                          programExtensions[idx].releaseDate,
                          DATE_FORMAT,
                        )?.toDate()
                      : undefined
                  }
                />
              </>
            )}
            {(mpe.status?.id === ProgramExtensionStatus.HOLD ||
              mpe.programExtension?.id === ProgramExtension.CCM) &&
              idx !== programExtensions?.length - 1 && (
                <hr className="separator grid-span-12" />
              )}
          </Fragment>
        ))}
        <Button
          className="grid-span-12"
          color="tertiary"
          icon={<AddIcon />}
          onClick={() =>
            setProgramExtensions([
              ...(programExtensions || []),
              new MemberProgramExtension({}),
            ])
          }
        >
          {translate('personalDetails.addAnotherProgramExtension')}
        </Button>
      </div>
    </BaseModal>
  );
};

export default EditProgramExtensions;
